import React, { useState } from 'react';
import axios from 'axios';
import { setupAxiosCSRF } from '../helpers';
import Filters from './Filters';
import moment from 'moment';
import { InputLabelBig } from '../common/styles';

const ListTransfer = ({data}) => {
  const initialItems = Object.keys(data.attributeNames);
  const [leftItems, setLeftItems] = useState(initialItems);
  const [rightItems, setRightItems] = useState([]);
  const [selectedLeftItems, setSelectedLeftItems] = useState([]);
  const [selectedRightItems, setSelectedRightItems] = useState([]);
  const [filters, setFilters] = useState({
    date_range: `${moment().subtract(6, 'd').format('MM/DD/YYYY')} - ${moment().format('MM/DD/YYYY')}`
  });
  const [isDowloading, setIsDownloading] = useState(false);

  const moveItemsToRight = () => {
    if (selectedLeftItems.length) {
      setRightItems(prevItems => [...prevItems, ...selectedLeftItems]);
      setLeftItems(prevItems => prevItems.filter(item => !selectedLeftItems.includes(item)));
      setSelectedLeftItems([]);
    }
  };

  const moveItemsToLeft = () => {
    if (selectedRightItems.length) {
      setLeftItems(prevItems => [...prevItems, ...selectedRightItems]);
      setRightItems(prevItems => prevItems.filter(item => !selectedRightItems.includes(item)));
      setSelectedRightItems([]);
    }
  };

  const toggleLeftItemSelection = (item) => {
    setSelectedLeftItems(prevItems => {
      if (prevItems.includes(item)) {
        return prevItems.filter(i => i !== item);
      } else {
        return [...prevItems, item];
      }
    });
  }

  const toggleRightItemSelection = (item) => {
    setSelectedRightItems(prevItems => {
      if (prevItems.includes(item)) {
        return prevItems.filter(i => i !== item);
      } else {
        return [...prevItems, item];
      }
    });
  }

  const sortedLeftItems = [...leftItems].sort();
  const sortedRightItems = [...rightItems].sort();

  const handleExport = () => {
    setIsDownloading(true);
    const actualAttributeNames = sortedRightItems.map(item => data.attributeNames[item]);
    setupAxiosCSRF();
    axios.post('/excel_reports/export', { selected_columns: actualAttributeNames,
      filters: {
        date_range: filters.date_range,
        agent: filters.agent,
        is_scored: filters.isScored
      }
    }, { responseType: 'blob' })
    .then((response) => {
      setIsDownloading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'agent_scored_report.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log("Error exporting data: ", error);
      setIsDownloading(false);
    });
  };

  const handleFiltersChange = (updateBody) => {
    setFilters({
      ...(filters || {}),
      ...updateBody
    });
  };

  const handleReset = () => {
    setLeftItems(initialItems);
    setRightItems([]);
    setSelectedLeftItems([]);
    setSelectedRightItems([]);
  };

  return (
    <div className='container'>
      <Filters onChange={handleFiltersChange} agents={data.agents} groups={data.groups} />
      <div className="row align-items-center">
        <div className='col'>
          <InputLabelBig>All metrics</InputLabelBig>
          <div className="card border rounded h-100 px-3">
            <div className="card-body list-card-body">
            {sortedLeftItems.map(item => (
              <div key={item} className='d-flex align-items-center'>
                <label className={`${selectedLeftItems.includes(item) ? 'selected-item p-1' : ''} mt-1`} onClick={() => toggleLeftItemSelection(item)}>{item}</label>
              </div>))
            }
            </div>
          </div>
        </div>

        <div className='col-auto d-flex flex-column align-items-center sticky-btn'>
          <button onClick={moveItemsToRight} className='btn btn-primary mb-3'><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
          <button onClick={moveItemsToLeft} className='btn btn-primary'><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
        </div>

        <div className='col'>
          <InputLabelBig>Include in export</InputLabelBig>
          <div className="card border rounded h-100 px-3">
            <div className="card-body list-card-body">
            {sortedRightItems.map(item => (
              <div key={item} className='d-flex align-items-center'>
                <label className={`${selectedRightItems.includes(item) ? 'selected-item p-1' : ''} mt-1`} onClick={() => toggleRightItemSelection(item)}>{item}</label>
              </div>))
            }
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button onClick={handleReset} className='btn btn-primary'>Reset</button>
        <button onClick={handleExport} className='btn btn-primary' disabled={rightItems.length === 0 || isDowloading}>{isDowloading ? 'Downloading...' : 'Export Excel'}</button>
      </div>
    </div>
  );
};

export default ListTransfer;
