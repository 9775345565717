import React, { useState } from 'react';
import Slider from 'rc-slider';
import styled from 'styled-components';

export const defaultStyles = {
  trackStyle: { backgroundColor: '#FF5D39', height: 8 },
  handleStyle: {
    borderColor: 'white',
    borderStyle: 'solid',
    borderWidth: 4,
    height: 16,
    width: 16,
    marginLeft: 0,
    marginTop: -4,
    opacity: 1,
    filter: 'drop-shadow(0px 0px 3px rgba(213, 57, 22, 0.3))',
    backgroundColor: '#FF5D39',
  },
  railStyle: { backgroundColor: '#FFECE7', height: 8 },
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Label = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 64px;
  padding-top: 4px;
  text-align: ${props => props.left ? 'left' : 'right'};
  font-weight: 500;
  font-size: 12px;
  color: #000215;
`;

const SliderSelect = ({
  initialValue,
  min,
  max,
  step,
  styles = defaultStyles,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue || 0);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  return (
    <Wrapper>
      <Slider
        min={min}
        max={max}
        defaultValue={initialValue}
        step={step}
        {...styles}
        onChange={handleChange}
      />
      <Label>
        {value} %
      </Label>
    </Wrapper>
  );
};

export default SliderSelect;
