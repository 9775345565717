import React, { useState, useEffect, useRef } from 'react';
import LeftSlidePanel from '../common/popovers/LeftSlidePanel';
import styled from 'styled-components';
import { InputLabelBig } from '../common/styles';
import axios from 'axios';
import Swal from 'sweetalert2';
import { setupAxiosCSRF } from '../helpers';
import moment from 'moment';

const PanelWrapper = styled.div`
  padding: 32px;
`;

const MessageField = styled.input`
  margin-bottom: 18px;
  padding: 12px !important;
`;

const ExportButton = () => {
  const filtersListener = useRef(null);
  const [params, setParams] = useState({});
  const [panelOpen, setPanelOpen] = useState(false);
  const [exportFilename, setExportFilename] = useState('')


  useEffect(() => {
    filtersListener.current = window.addEventListener('reports.filters', event => {
      const { detail: filters } = event;
      const newParams = {
        ...params,
        "filters[call_type]": filters['call_type'],
        "filters[call_map]": filters['call_map'],
        "filters[date_range]": filters['date_range'],
        "filters[users]": filters['users'],
        "filters[agent_prompt_labels]": filters['agent_prompt_labels'],
        "filters[customer_prompt_labels]": filters['customer_prompt_labels'],
        "filters[violation_labels]": filters['violation_labels'],
        "filters[outcome]": filters['outcome'],
        "filters[termination_type]": filters['termination_type'],
        "filters[disposition]": filters['disposition'],
        "filters[dialer_disposition]": filters['dialer_disposition'],
        "filters[keyword]": filters['keyword'],
        "filters[text_match]": filters['text_match'],
        "filters[meta_keyword]": filters['meta_keyword'],
        "filters[benchmark_ceil]": filters['benchmark_ceil'],
        "filters[completion_floor]": filters['completion_floor'],
        "filters[manager]": filters['manager'],
        "filters[campaign]": filters['campaign'],
        "filters[customer]": filters['customer'],
        "filters[contact]": filters['contact'],
        "filters[dialer_id]": filters['dialer_id'],
        "filters[phrase]": filters['phrase'],
        "filters[scored]": filters['scored'],
        "filters[dnis]": filters['dnis'],
        "filters[compliance_violations_range]": filters['compliance_violations_range'],
        "filters[completion_range]": filters['completion_range'],
        "filters[monologue_range]": filters['monologue_range'],
        "filters[questions_range]": filters['questions_range'],
        "filters[talk_ratio_range]": filters['talk_ratio_range'],
        "filters[objections_range]": filters['objections_range'],
        "filters[duration_range]": filters['duration_range'],
        "filters[score_range]": filters['score_range'],
      };

      // Set the filename based on the date range
      if (filters['date_range']) {
        const dates = filters['date_range'].split(' - ');
        const from_date = moment(dates[0], 'MM/DD/YYYY').format('MM-DD-YYYY');
        const to_date =  moment(dates[1], 'MM/DD/YYYY').format('MM-DD-YYYY');
        const formattedFilename = `Calls_Report_${from_date}_${to_date}`;
        setExportFilename(formattedFilename);
      }

      setParams(newParams);
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('reports.filters', filtersListener.current);
      }
    };
  }, []);

  const handleExport = () => {

    const newParams = {
      ...params,
    };

    setupAxiosCSRF();
    axios.post('/reports/export', {
        filters: newParams,
        filename: exportFilename
      }).then(response => {
        console.log(response.data);
    }).catch(error => {
      if (error.response && error.response.data.error) {
        Swal.fire({
          icon: 'info',
          title: 'Report generation',
          text: error.response?.data?.error,
          confirmButtonColor: '#FE5D39'
        }).then(() => {
          setPanelOpen(false);
          setExportFilename('');
        });
      } else {
        console.error("Error starting export:", error);
      }
    });
  };

  const isExportButtonDisabled = exportFilename.trim().length === 0;

  return (
    <div className='mr-2'>
      <button style={{ marginLeft: 8 }} className="btn btn-primary" onClick={() => setPanelOpen(true)}>
        Export
      </button>

      <LeftSlidePanel
        width="450px"
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
      >
        {panelOpen && (
          <PanelWrapper>
            <h4 style={{ marginBottom: 32 }}>Download report</h4>
            <InputLabelBig>Enter file name</InputLabelBig>
            <MessageField
              className='form-control'
              rows={1}
              placeholder="Enter file name"
              value={exportFilename}
              onChange={(e) => setExportFilename(e.target.value)}
              />
            <div className="row" style={{ paddingLeft: 32, paddingRight: 32, marginTop: 32 }}>
              <div className="offset-md-2 col-md-8">
                <button className="btn btn-primary btn-block btn-lg" onClick={handleExport} disabled={isExportButtonDisabled}>
                  Download report
                </button>
              </div>
            </div>
          </PanelWrapper>
        )}
      </LeftSlidePanel>
    </div>
  );
};

export default ExportButton;
