import React, { useState, useEffect } from 'react';
import { InputLabelBig } from '../common/styles';
import axios from 'axios';
import { setupAxiosCSRF } from '../helpers';
import moment from 'moment';

const ReadReceipt = ({ reviewed, reviewId, readReceiptAt, agentReviewed }) => {
  const [isChecked, setIsChecked] = useState(!!readReceiptAt);
  const [acknowledgmentDate, setAcknowledgmentDate] = useState(readReceiptAt);

  useEffect(() => {
    setIsChecked(!!readReceiptAt);
    setAcknowledgmentDate(readReceiptAt);
  }, [readReceiptAt]);

  const handleCheckboxChange = () => {
    if (!isChecked) {
      try {
        setupAxiosCSRF();
        axios.put(`/agent_reviews/${reviewId}/mark_read`)
          .then((response) => {
            if (response.data.success) {
              setAcknowledgmentDate(response.data.read_receipt_at);
              setIsChecked(true);
            }
          }).catch(error => {
            console.error('Error acknowledging receipt:', error);
          });
      } catch (error) {
        console.error('Error calling mark read:', error);
      }
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY, hh:mm A");
  };

  return (
    <div>
      {isChecked ? (
        <div className='p-2'>
          <InputLabelBig><i className="fa fa-check-double"></i> Read receipt on {formatDate(acknowledgmentDate)} </InputLabelBig>
        </div>
      ) : reviewed && (
        <div className="form-check">
          <input
            type="checkbox"
            id="readReceipt"
            checked={isChecked}
            disabled={!agentReviewed}
            onChange={handleCheckboxChange}
            className='mr-2 form-check-input'
          />
          <InputLabelBig className="form-check-label">Acknowledged</InputLabelBig>
        </div>
      )}
    </div>
  );
};

export default ReadReceipt;
