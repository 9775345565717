import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Store } from 'react-notifications-component';
import LeftSlidePanel from '../common/popovers/LeftSlidePanel';
import MultiSelect from '../common/form/MultiSelect';
import { InputLabelBig } from '../common/styles';
import { setupAxiosCSRF } from '../helpers';

const PanelWrapper = styled.div`
  padding: 32px;
`;

const ShareCallButton = ({
  data,
  url,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [selected, setSelected] = useState(data.already_shared.map(k => k['value']));
  const [saved, setSaved] = useState(data.already_shared.map(k => k['value']));
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setupAxiosCSRF();
  }, []);

  useEffect(() => {
    if (!panelOpen) {
      setSelected(saved);
      setSubmitting(false);
    }
  }, [panelOpen]);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      await axios.post(url, {
          user_ids: selected,
      });
      setSaved(selected);
      setPanelOpen(false);
      Store.addNotification({
        title: "Success",
        message: "Your recording was successfully shared.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });
    } catch (e) {
      console.log('Error sharing the call ', e);
      Store.addNotification({
        title: "Error",
        message: "Your are not allowed to share this recording.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });
    }

    setSubmitting(false);
  };

  return (
    <div className="mr-2">
      <button style={{ marginLeft: 8 }} className="btn btn-primary" onClick={() => setPanelOpen(true)}>
        Share
      </button>

      <LeftSlidePanel
        width="450px"
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
      >
        {panelOpen && (
          <PanelWrapper>
            <h4 style={{ marginBottom: 32 }}>Share the call</h4>

            <InputLabelBig>Shared with</InputLabelBig>
            <MultiSelect
              initialValue={selected}
              options={data.users}
              onChange={setSelected}
            />

            <div className="row" style={{ paddingLeft: 32, paddingRight: 32, marginTop: 32 }}>
              <div className="offset-md-3 col-md-6">
                <button className="btn btn-primary btn-block btn-lg" onClick={handleSubmit} disabled={submitting || selected === saved}>
                  {submitting ? 'Submitting ...' : 'Submit'}
                </button>
              </div>
            </div>
          </PanelWrapper>
        )}
      </LeftSlidePanel>
    </div>
  );
};

export default ShareCallButton;
