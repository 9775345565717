import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
  position: relative;
  font-size: 12px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #FF5D39;
  width: 38px;
  height: 20px;
  border-radius: 8px;
  color: white;
`;

const DownloadButton = ({show, onClick}) => {
  if (!show) return null;

  return (
    <Wrapper>
      <Button onClick={onClick}>
       <span className="fa fa-download" />
      </Button>
    </Wrapper>
  )
};

export default DownloadButton;
