import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import SingleSelect from '../common/form/SingleSelect';
import LeftSlidePanel from '../common/popovers/LeftSlidePanel';
import { InputLabelBig, BlockInput, InputtyButton } from '../common/styles';
import { DATE_PREDEFINED_RANGES } from '../helpers';
import AdvancedFilters from './AdvancedFilters';

const FiltersRow = ({
  filters: initialFilters = {},
  callMaps,
  metrics: initialMetrics
}) => {
  const [callMapId, setCallMapId] = useState(initialFilters['call_map']);
  const [callMapOptions, setCallMapOptions] = useState([]);
  const [startDate, setStartDate] = useState(initialFilters['date_from_str'] ? moment(initialFilters['date_from_str'], 'MM/DD/YYYY') : moment().subtract(6,'d'));
  const [endDate, setEndDate] = useState(initialFilters['date_to_str'] ? moment(initialFilters['date_to_str'], 'MM/DD/YYYY') : moment());
  const [metrics, setMetrics] = useState(initialMetrics);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  useEffect(() => {
    setCallMapOptions([
      { label: 'Any call map', value: null },
      ...callMaps.map(cm => ({ value: cm[0], label: cm[1] }))
    ]);
  }, [callMaps]);

  useEffect(() => {
    notifyFilterChanges(callMapId, startDate, endDate);
  }, [callMapId]);

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    notifyFilterChanges(callMapId, start, end);
  };

  const handleAdvancedChange = (newMetrics) => {
    notifyFilterChanges(callMapId, startDate, endDate); // same as reloading widget/table data

    setAdvancedOpen(false);
    setMetrics(newMetrics);
  };

  const notifyFilterChanges = (callMapId, stDate, enDate) => {
    const filters = {
      call_map: callMapId,
      date_range: `${stDate.format('MM/DD/YYYY')} - ${enDate.format('MM/DD/YYYY')}`,
    };

    window.dispatchEvent(
      new CustomEvent('dashboard.filters', { detail: filters })
    );
  };

  return (
    <div className='row'>
      <div className='col-md-4 mb-4'>
        <InputLabelBig>Call Map</InputLabelBig>
        <SingleSelect
          initialValue={callMapId}
          options={callMapOptions}
          onChange={setCallMapId}
        />
      </div>
      <div className='col-md-4 mb-4'>
        <InputLabelBig>Period</InputLabelBig>
        <DateRangePicker
          initialSettings={{
            ranges: DATE_PREDEFINED_RANGES,
            alwaysShowCalendars: true,
            startDate,
            endDate,
          }}
          onCallback={handleDateRangeChange}
        >
          <BlockInput />
        </DateRangePicker>
      </div>
      <div className='col-md-4 mb-4'>
        <InputLabelBig className='invisible'>Advanced Filters</InputLabelBig>
        <InputtyButton onClick={() => setAdvancedOpen(true)}>
          Advanced Filters
        </InputtyButton>
      </div>

      <LeftSlidePanel
        width="450px"
        open={advancedOpen}
        onClose={() => setAdvancedOpen(false)}
      >
        {advancedOpen && (
          <AdvancedFilters metrics={metrics} onChange={handleAdvancedChange} />
        )}
      </LeftSlidePanel>
    </div>
  )
};

export default FiltersRow;
