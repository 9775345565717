import React, { useState, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { BlockInput } from '../common/styles';

const PromptSearch = ({
  eventName = 'prompts.search'
}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    debouncedSearchNotifier(search);

    return () => {
      debouncedSearchNotifier.cancel();
    };
  }, [search]);

  const handleSearch = useCallback((evt) => {
    const { target: { value } } = evt;

    setSearch(value);
  }, [setSearch]);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const notifySearch = useCallback((search) => {
    window.dispatchEvent(
      new CustomEvent(eventName, { detail: search })
    );
  }, []);

  const debouncedSearchNotifier = useMemo(
    () => debounce(notifySearch, 2000)
  , []);

  return (
    <div className="prompt-search mb-4">
      <BlockInput
        placeholder="Search by word or phrase"
        style={{ width: '100%' }}
        value={search}
        onChange={handleSearch}
      />
      <span className="btn-clear" onClick={clearSearch}>
        <i className="fa fa-times" />
      </span>
    </div>
  )
};

export default PromptSearch;
