import React, { useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BlockInput } from '../common/styles';
import { DATE_PREDEFINED_RANGES } from '../helpers';
import { InputLabelBig } from '../common/styles';

const Filters = ({
  onChange,
}) => {
  const [startDate, setStartDate] = useState(moment().subtract(6, 'd'));
  const [endDate, setEndDate] = useState(moment());

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    onChange({
      date_range: `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`
    });
  };

  return (
    <div className='row mb-4'>
      <div className='col-md-4'>
        <InputLabelBig>Period</InputLabelBig>
        <DateRangePicker
          initialSettings={{
            ranges: DATE_PREDEFINED_RANGES,
            alwaysShowCalendars: true,
            opens: 'left',
            startDate,
            endDate,
          }}
          onCallback={handleDateRangeChange}
        >
          <BlockInput />
        </DateRangePicker>
      </div>
    </div>
  );
};

export default Filters;
