import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { PaginatedTableContainer } from '../Reports/styles';
import ScoringsTable from './ScoringsTable';

const ScoringsMain = ({
  data: initialData,
}) => {
  const filtersListener = useRef(null);
  const scopeSwitchListener = useRef(null);
  const managerToggleListener = useRef(null)
  const params = useRef({});
  const visiblility = useRef(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(0);

  useEffect(() => {
    filtersListener.current = window.addEventListener('admin_reviews.filters', event => {
      const { detail: filters } = event;
      params.current = {
        ...params.current,
        "filters[date_range]": filters['date_range'],
        "filters[agents]": filters['agents'],
        "filters[reviewers]": filters['reviewers'],
        "filters[scorecards]": filters['scorecards'],
        "filters[campaigns]": filters['campaigns'],
        page: 1
      };

      if (visiblility.current && !filters['skip_fetch']) {
        fetchNewDataset(params.current);
      }
    });

    scopeSwitchListener.current = window.addEventListener('admin_reviews.scope', event => {
      const { detail: scope } = event;

      if (scope === 'scorings') {
        params.current = {
          ...params.current,
          page: 1
        };

        setVisible(true);
        fetchNewDataset(params.current);
      } else {
        setVisible(false);
      }
    });
    // add manager toggle event listener
    managerToggleListener.current = window.addEventListener('admin_reviews.manager_toggle', event => {
      const {detail: isManager } = event;
      params.current = {
        ...params.current,
        manager: isManager
      }

      if (visiblility.current) {
        fetchNewDataset(params.current)
      }
    })

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('admin_reviews.filters', filtersListener.current);
      }
      if (scopeSwitchListener.current) {
        window.removeEventListener('admin_reviews.scope', scopeSwitchListener.current);
      }
      if (managerToggleListener.current) {
        window.removeEventListener('admin_reviews.manager_toggle', managerToggleListener.current);
      }
    };
  }, []);

  useEffect(() => {
    visiblility.current = visible;
  }, [visible]);

  const fetchNewDataset = (params) => {
    axios.get('/admin_reviews/scoring_data', {
      params,
    }).then(res => {
      setData(res.data);
      setPage(0);
    });
  };

  const handlePageClick = ({ selected: newPage }) => {
    if (page === newPage) return;

    params.current = {
      ...params.current,
      page: newPage + 1,
    };

    setPage(newPage);

    axios.get('/admin_reviews/scoring_data', {
      params: params.current,
    }).then(res => {
      setData(res.data);
    });
  };

  if (!visible) return null;

  return (
    <main>
      <PaginatedTableContainer>
        <ScoringsTable
          scorings={data.scorings}
          scorecards={data.scorecards}
        />
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          forcePage={page}
          pageCount={data.pagination.total}
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
          className="paginator"
          activeClassName="active-page"
          pageRangeDisplayed={10}
        />
      </PaginatedTableContainer>
    </main>
  );
};

export default ScoringsMain;
