import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { customStyles as selectStyles } from '../common/form/SingleSelect';

const Container = styled.div`
  min-width: 200px;
`;

// fully controlled
const GroupingItem = ({
  value,
  options,
  axis,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const selection = options.find(option => option.value == value);

    setSelectedOption(selection);
  }, [options, value]);

  const handleChange = (selection) => {
    setSelectedOption(selection);
    onChange(axis, selection.value);
  };

  return (
    <Container className="mr-2">
      <Select
        value={selectedOption}
        options={options}
        onChange={handleChange}
        styles={selectStyles}
      />
    </Container>
  )
};

export default GroupingItem;
