import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AnalyticsScoreWidgets from '../Call/AnalyticsScoreWidgets';
import { PaginatedTableContainer } from './styles';
import RecordingsTable from './RecordingsTable';
import LoadingIndicator from '../common/LoadingIndicator';
import CallGraph from './CallGraph';
import AgentMetricsTable from './AgentMetricsTable';

const Container = styled.div`
  margin: 24px 0px;
  font-size: 18px;
  color: #000215;
`;

const getDefaultDateRange = () => {
  const startDate = moment().subtract(6,'d');
  const endDate = moment();

  return `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`;
};

const Main = ({
  isClient,
  data: initialData,
}) => {
  const filtersListener = useRef(null);
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(0);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    filtersListener.current = window.addEventListener('reports.filters', event => {
      const { detail: filters } = event;
      const newParams = {
        ...params,
        "filters[call_type]": filters['call_type'],
        "filters[call_map]": filters['call_map'],
        "filters[date_range]": filters['date_range'],
        "filters[user]": filters['user'],
        "filters[prompt_category]": filters['prompt_category'],
        "filters[compliance]": filters['compliance'],
        "filters[outcome]": filters['outcome'],
        "filters[disposition]": filters['disposition'],
        "filters[dialer_disposition]": filters['dialer_disposition'],
        "filters[keyword]": filters['keyword'],
        "filters[text_match]": filters['text_match'],
        "filters[meta_keyword]": filters['meta_keyword'],
        "filters[benchmark_ceil]": filters['benchmark_ceil'],
        "filters[completion_floor]": filters['completion_floor'],
        "filters[manager]": filters['manager'],
        "filters[campaign]": filters['campaign'],
        "filters[customer]": filters['customer'],
        "filters[contact]": filters['contact'],
        "filters[dialer_id]": filters['dialer_id'],
        "filters[phrase]": filters['phrase'],
        "filters[scored]": filters['scored'],
        "filters[compliance_violations_range]": filters['compliance_violations_range'],
        "filters[completion_range]": filters['completion_range'],
        "filters[monologue_range]": filters['monologue_range'],
        "filters[questions_range]": filters['questions_range'],
        "filters[talk_ratio_range]": filters['talk_ratio_range'],
        "filters[objections_range]": filters['objections_range'],
        "filters[duration_range]": filters['duration_range'],
        page: 1
      };

      setParams(newParams);
      setLoading(true);

      axios.get('/analytics/recordings', {
        params: newParams,
      }).then(res => {
        setData(res.data);
        setPage(0);
      }).finally(() => {
        setLoading(false);
      });
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('reports.filters', filtersListener.current);
      }
    };
  }, []);

  const handlePageClick = ({ selected: newPage }) => {
    if (page === newPage) return;

    const newParams = {
      ...params,
      page: newPage + 1,
    };

    setParams(newParams);
    setPage(newPage);

    axios.get('/analytics/recordings', {
      params: newParams,
    }).then(res => {
      setData(res.data);
    });
  };
  return (
    <main>
      {/*
      <AnalyticsScoreWidgets metrics={data.metrics} />
      <Container>
        {data.metrics['total_calls']['score'] >= 1000 && <div>
        <div className="alert alert-warning alert-dismissible show" role="alert">
        The number of recordings has been limited to 1000 records.
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </div></div>}
      </Container>
      */}
      <Container>
        <div>
          <strong>Period: </strong>
          {params['filters[date_range]'] || getDefaultDateRange()}
        </div>
      </Container>
      <Tabs>
        <TabList>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Average call length by agent</Tab>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Number of calls by agent</Tab>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Compliance violations by agent</Tab>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Questions asked by agent</Tab>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Talking ratio by agent</Tab>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Call score by agent</Tab>
        </TabList>

        <TabPanel>
          <CallGraph title='Average call length by agent' label='Average call length [min]' data={data.call_length_graph_data} number_of_calls={data.number_calls_graph_data}/>
        </TabPanel>
        <TabPanel>
          <CallGraph title='Number of calls by agent' label='Number of calls' data={data.number_calls_graph_data}/>
        </TabPanel>
        <TabPanel>
          <CallGraph title='Compliance violations by agent' label='Compliance violations' data={data.compliance_violations_graph_data} number_of_calls={data.number_calls_graph_data}/>
        </TabPanel>
        <TabPanel>
          <CallGraph title='Questions asked by agent' label='Questions asked' data={data.questions_asked_graph_data} number_of_calls={data.number_calls_graph_data}/>
        </TabPanel>
        <TabPanel>
          <CallGraph title='Talking ratio by agent' label='Talking ratio [%]' data={data.talking_ratio_graph_data} number_of_calls={data.number_calls_graph_data}/>
        </TabPanel>
        <TabPanel>
          <CallGraph title='Call score by agent' label='Call score' data={data.call_score_graph_data} number_of_calls={data.number_calls_graph_data}/>
        </TabPanel>
      </Tabs>
    </main>
  );
};

export default Main;
