import React from 'react';
import { useTable } from 'react-table/src/hooks/useTable'
import { DefaultTblStyle } from '../common/tables/styles';

const peopleColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  }
];

const PeopleTable = ({
  people,
  title,
  total,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: peopleColumns, data: people })

   return (
    <DefaultTblStyle>
      <div className="table-header d-flex align-items-center">
        <h4>{title}</h4>
        <span className="badge badge-secondary mt-1">{total}</span>
      </div>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
   );
 }

 export default PeopleTable;
