import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { BlockTitle } from './styles';
import { PROMPT_FG, PROMPT_BG } from './colors';
import { beautifyDuration } from '../helpers';

const PromptBox = styled.div`
  cursor: pointer;
  padding: 20px;
  border-radius: 16px;
  color: ${props => PROMPT_FG[props.type]};
  background-color: ${props => PROMPT_BG[props.type]};
  font-size: 16px;
  margin-bottom: 16px;
`;

const InfoText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #85868F;
  margin-bottom: 4px;
`;

const NOTABLE_EVENTS = {
  'slow-down': 'Agent spoke too fast'
};

const groupPromptOccurrences = prompts => {
  const promptDict = {};

  for (let prompt of prompts) {
    const promptId = prompt.id;
    promptDict[promptId] ||= {};
    promptDict[promptId].id ||= promptId;
    promptDict[promptId].content ||= prompt.content;

    promptDict[promptId].times ||= [];
    promptDict[promptId].playTimes ||= [];
    promptDict[promptId].count ||= 0;

    if (prompt.time !== undefined) {
      promptDict[promptId].times.push(prompt.time);
      promptDict[promptId].playTimes.push(getPlayTime(prompt.time));
    }

    promptDict[promptId].count += 1;
  }

  return Object.values(promptDict);
};

const trimRebuttedOccurrences = (prompt, followedTimes) => {
  const times = [];
  const playTimes = [];

  for (let i in prompt.times) {
    if (!followedTimes.includes(prompt.times[i])) {
      times.push(prompt.times[i]);
      playTimes.push(prompt.playTimes[i]);
    }
  }

  return {
    ...prompt,
    times,
    playTimes,
    count: times.length,
  };
};

const groupEventOccurrences = events => {
  const eventDict = {};

  for (let event of events) {
    const eventType = event.type;
    eventDict[eventType] ||= {};
    eventDict[eventType].type ||= eventType;

    eventDict[eventType].times ||= [];
    eventDict[eventType].playTimes ||= [];
    eventDict[eventType].count ||= 0;

    if (event.time !== undefined) {
      eventDict[eventType].times.push(event.time);
      eventDict[eventType].playTimes.push(getPlayTime(event.time));
    }

    eventDict[eventType].count += 1;
  }

  return Object.values(eventDict);
};

const groupProlongedSilenceOccurrences = prolongedSilences => {
  const prolongedSilenceDict = {};

  for (let silence of prolongedSilences) {
    const silenceType = 'prolongedSilence';
    prolongedSilenceDict[silenceType] ||= {};
    prolongedSilenceDict[silenceType].type ||= silenceType;

    prolongedSilenceDict[silenceType].times ||= [];
    prolongedSilenceDict[silenceType].playTimes ||= [];
    prolongedSilenceDict[silenceType].count ||= 0;

    if (silence.time !== undefined) {
      prolongedSilenceDict[silenceType].times.push(silence.time);
      prolongedSilenceDict[silenceType].playTimes.push(getPlayTime(silence.time));
    }

    prolongedSilenceDict[silenceType].count += 1;
  }

  return Object.values(prolongedSilenceDict);
};

const groupOvertalkDetectionOccurrences = overtalkDetections => {
  const overtalkDetectionDict = {};

  for (let overtalk of overtalkDetections) {
    const overtalkType = 'overtalkDetection';
    overtalkDetectionDict[overtalkType] ||= {};
    overtalkDetectionDict[overtalkType].type ||= overtalkType;

    overtalkDetectionDict[overtalkType].times ||= [];
    overtalkDetectionDict[overtalkType].playTimes ||= [];
    overtalkDetectionDict[overtalkType].count ||= 0;

    if (overtalk.time !== undefined) {
      overtalkDetectionDict[overtalkType].times.push(overtalk.time);
      overtalkDetectionDict[overtalkType].playTimes.push(getPlayTime(overtalk.time));
    }

    overtalkDetectionDict[overtalkType].count += 1;
  }

  return Object.values(overtalkDetectionDict);
}

const getPlayTime = time => Math.max(1, time - 2);

// to update overtalkDetections by adding time property and make it look like a prompt and can be clicked to the time overtalk started
const addTimeProperty = arr => arr.map(obj => ({ ...obj, time: obj.start }));

const Prompts = ({
  prompts,
  events: rawEventsArray,
  customerPromptIds,
  notifiablePromptIds,
  unfollowedPromptIds,
  followedPromptTimes,
  skippedGuides = [],
  unsaidGuides = [],
  overtalkDetections = [],
  prolongedSilences = [],
  heldTimeReached,
}) => {
  const [agentPrompts, setAgentPrompts] = useState(null);
  const [customerPrompts, setCustomerPrompts] = useState(null);
  const [violatingPrompts, setViolatingPrompts] = useState(null);
  const [unfollowedPrompts, setUnfollowedPrompts] = useState(null);
  const events = useRef(groupEventOccurrences(rawEventsArray));

  let updatedOvertalkDetections = []
  if (!!overtalkDetections?.length) {
    updatedOvertalkDetections = addTimeProperty(overtalkDetections)
  }
  const overtalkDetectionsGrouped = useRef(groupOvertalkDetectionOccurrences(updatedOvertalkDetections));

  let updatedProlongedSilences = [];
  if (!!prolongedSilences?.length) {
    updatedProlongedSilences = addTimeProperty(prolongedSilences)
  }
  const prolongedSilencesGrouped = useRef(groupProlongedSilenceOccurrences(updatedProlongedSilences));

  if (heldTimeReached) {
    // to make heldTimeReached look like a prompt and can be clicked to the time it happened
    heldTimeReached.time = heldTimeReached.reached
  }

  useEffect(() => {
    const groupedPrompts = groupPromptOccurrences(prompts);
    const agentPrompts = [];
    const customerPrompts = [];
    const violatingPrompts = [];
    const unfollowedPrompts = [];

    for (let prompt of groupedPrompts) {
      if (notifiablePromptIds.includes(prompt.id)) {
        violatingPrompts.push(prompt);
      } else if (customerPromptIds.includes(prompt.id)) {
        customerPrompts.push(prompt);
      } else {
        agentPrompts.push(prompt);
      }

      if (unfollowedPromptIds.includes(prompt.id)) {
        unfollowedPrompts.push(
          trimRebuttedOccurrences(prompt, followedPromptTimes)
        );
      }
    }

    setAgentPrompts(agentPrompts);
    setCustomerPrompts(customerPrompts);
    setViolatingPrompts(violatingPrompts);
    setUnfollowedPrompts(unfollowedPrompts.filter(prompt => prompt.count > 0));
  }, []);

  const handlePromptJump = prompt => {
    if (!prompt.times.length) return;

    window.dispatchEvent(
      new CustomEvent('replay.prompt_jump', { detail: prompt.playTimes })
    );
  };

  const handleAudioJump = time => {
    if (time === undefined) return;

    window.dispatchEvent(
      new CustomEvent('replay.jump', { detail: getPlayTime(time) })
    );
  };

  // a.k.a. - if not ready to present yet
  if (!agentPrompts || !customerPrompts || !violatingPrompts || !unfollowedPrompts) {
    return null;
  }

  const hasAgentPrompts = !!agentPrompts.length;
  const hasCustomerPrompts = !!customerPrompts.length;
  const hasViolatingPrompts = !!violatingPrompts.length;
  const hasUnfollowedPrompts = !!unfollowedPrompts.length;
  const hasSkippedGuides = !!skippedGuides.length;
  const hasUnsaidGuides = !!unsaidGuides.length;
  const hasOvertalkDetections = !!overtalkDetections?.length;
  const hasProlongedSilences = !!prolongedSilences?.length;
  const hasHeldTimeReached = !!heldTimeReached;
  const hasComplianceViolations = hasViolatingPrompts || hasSkippedGuides || hasUnsaidGuides || hasHeldTimeReached;
  const hasOtherViolations = hasUnfollowedPrompts || hasOvertalkDetections || hasProlongedSilences;
  const hasNotableEvents = !!events.current.length;

  return (
    <div className="row">
      <div className="col-md-6">
        <BlockTitle>Customer triggered prompts</BlockTitle>
        {customerPrompts.map((prompt, i) => (
          <PromptBox type="customer" onClick={() => handlePromptJump(prompt)} key={prompt.id + '_' + i}>
            <span className={cx("badge badge-info float-right mt-1", { 'd-none': prompt.count < 2 })}>{prompt.count}</span>
            <strong dangerouslySetInnerHTML={{ __html: prompt.content.replace('\n', '<br/>') }}></strong>
          </PromptBox>
        ))}
        {!hasCustomerPrompts && <InfoText>There were no customer triggered prompts during this call</InfoText>}

        <BlockTitle>Agent triggered prompts</BlockTitle>
        {agentPrompts.map((prompt, i) => (
          <PromptBox type="agent" onClick={() => handlePromptJump(prompt)} key={prompt.id + '_' + i}>
            <span className={cx("badge badge-primary float-right mt-1", { 'd-none': prompt.count < 2 })}>{prompt.count}</span>
            <strong dangerouslySetInnerHTML={{ __html: prompt.content.replace('\n', '<br/>') }}></strong>
          </PromptBox>
        ))}
        {!hasAgentPrompts && <InfoText>There were no agent triggered prompts during this call</InfoText>}
      </div>

      <div className="col-md-6">
        <BlockTitle>Compliance violations</BlockTitle>

        {hasViolatingPrompts && <InfoText>The agent said:</InfoText>}
        {violatingPrompts.map((prompt, i) => (
          <PromptBox type="violation" onClick={() => handlePromptJump(prompt)} key={prompt.id + '_' + i}>
            <span className={cx("badge badge-danger float-right mt-1 ml-1", { 'd-none': prompt.count < 2 })}>{prompt.count}</span>
            <span className='badge badge-danger float-right mt-1'>Non-compliance</span>
            <strong dangerouslySetInnerHTML={{ __html: prompt.content.replace('\n', '<br/>') }}></strong>
          </PromptBox>
        ))}

        {hasUnsaidGuides && <InfoText>The agent did not say in time:</InfoText>}
        {unsaidGuides.map((guide) => (
          <PromptBox type="violation" key={guide.id}>
            <strong dangerouslySetInnerHTML={{ __html: guide.content.replace('\n', '<br/>') }}></strong>
          </PromptBox>
        ))}

        {hasSkippedGuides && <InfoText>The agent did not say:</InfoText>}
        {skippedGuides.map((guide) => (
          <PromptBox type="violation" key={guide.id}>
            <strong dangerouslySetInnerHTML={{ __html: guide.content.replace('\n', '<br/>') }}></strong>
          </PromptBox>
        ))}

        {hasHeldTimeReached && <InfoText>The agent held the call for too long:</InfoText>}
        {hasHeldTimeReached && <PromptBox type="violation" onClick={() => handleAudioJump(heldTimeReached.time)} key={`held_time_reached_${heldTimeReached.start}`}>
          <strong>Hold started at {beautifyDuration(heldTimeReached.start)} and max held reached at {beautifyDuration(heldTimeReached.reached)}</strong>
        </PromptBox>}

        {!hasComplianceViolations && <InfoText>There were no compliance violations during this call</InfoText>}

        <BlockTitle>Other violations</BlockTitle>
        {hasUnfollowedPrompts && <InfoText>The agent did not address prompt:</InfoText>}
        {unfollowedPrompts.map((prompt, i) => (
          <PromptBox type="violation" onClick={() => handlePromptJump(prompt)} key={prompt.id + '_' + i}>
            <span className={cx("badge badge-danger float-right mt-1", { 'd-none': prompt.count < 2 })}>{prompt.count}</span>
            <strong dangerouslySetInnerHTML={{ __html: prompt.content.replace('\n', '<br/>') }}></strong>
          </PromptBox>
        ))}

        {hasProlongedSilences && <InfoText>The agent prolonged silence:</InfoText>}
        {hasProlongedSilences && prolongedSilencesGrouped.current.map((silenceEvent) => (
          <PromptBox type="violation" key={`evt-${silenceEvent.type}`} onClick={() => handlePromptJump(silenceEvent)}>
            <span className={cx("badge badge-danger float-right mt-1", { 'd-none': silenceEvent.count < 2 })}>{silenceEvent.count}</span>
            <strong>Prolonged silences </strong>
          </PromptBox>
        ))}

        {hasOvertalkDetections && <InfoText>The agent over talked the customer:</InfoText>}
        {hasOvertalkDetections && overtalkDetectionsGrouped.current.map((overtalkEvent) => (
          <PromptBox type="violation" key={`evt-${overtalkEvent.type}`} onClick={() => handlePromptJump(overtalkEvent)}>
            <span className={cx("badge badge-danger float-right mt-1", { 'd-none': overtalkEvent.count < 2 })}>{overtalkEvent.count}</span>
            <strong>Overtalk detections </strong>
          </PromptBox>
        ))}

        {!hasOtherViolations && <InfoText>There were no other violations during this call</InfoText>}

        <BlockTitle>Other notable events</BlockTitle>
        {events.current.map((event) => (
          <PromptBox type="violation" key={`evt-${event.type}`} onClick={() => handlePromptJump(event)}>
            <span className={cx("badge badge-danger float-right mt-1", { 'd-none': event.count < 2 })}>{event.count}</span>
            <strong>{NOTABLE_EVENTS[event.type]}</strong>
          </PromptBox>
        ))}
        {!hasNotableEvents && <InfoText>There were no other notable events during this call</InfoText>}
      </div>
    </div>
  );
};

export default Prompts;
