import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

const ItemLi = styled.li`
`;

const COLORS = {
  pass: '#00644B',
  fail: '#A70010',
  neut: '#000215',
  na: '#000215',
};

const PassButton = styled.div`
  flex-shrink: 0;
  text-align: center;
  cursor: ${(props) => props.readOnly ? 'inherit' : 'pointer'};
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background: ${(props) => props.checked ? COLORS.pass : 'white'};
  color: ${(props) => props.checked ? 'white' : COLORS.pass};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${(props) => props.checked ? COLORS.pass : '#5C5D69'};
`;

const FailButton = styled.div`
  flex-shrink: 0;
  text-align: center;
  cursor: ${(props) => props.readOnly ? 'inherit' : 'pointer'};
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background: ${(props) => props.checked ? COLORS.fail : 'white'};
  color: ${(props) => props.checked ? 'white' : COLORS.fail};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${(props) => props.checked ? COLORS.fail : '#5C5D69'};
`;

const NaButton = styled.div`
  flex-shrink: 0;
  text-align: center;
  cursor: ${(props) => props.readOnly ? 'inherit' : 'pointer'};
  width: 24px;
  height: 24px;
  font-size: 10px;
  margin-right: 12px;
  background: ${(props) => props.checked ? '#CCCCCC' : 'white'};
  color: #5C5D69;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: #5C5D69;
`;

const NotesField = styled.textarea`
  margin-top: 12px;
  margin-bottom: 18px;
  padding: 12px !important;
`;

const NotesParagraph = styled.p`
  color: #5C5D69;
  margin-top: 12px;
  margin-bottom: 18px;
`;

const NAValue = 'na';

const getStatusFromScore = score => {
  if (score === true) {
    return 'pass';
  } else if (score === false) {
    return 'fail';
  } else if (score === NAValue) {
    return 'na';
  } else {
    return 'neut';
  }
};

const ScorecardItem = ({
  item,
  score: initialScore,
  notes: initialNotes,
  readOnly,
  onScore,
  onNotes,
}) => {
  const [score, setScore] = useState(initialScore);
  const [notes, setNotes] = useState(initialNotes);
  const [status, setStatus] = useState('neut');

  const isInitialMount = useRef(true);
  useEffect(() => {
    setStatus(getStatusFromScore(score));

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onScore(item.id, score);
    }
  }, [score]);

  const togglePass = () => {
    if (readOnly) return;

    if (score === true) {
      setScore(null);
    } else {
      setScore(true);
    }
  };

  const toggleFail = () => {
    if (readOnly) return;

    if (score === false) {
      setScore(null);
    } else {
      setScore(false);
    }
  };

  const toggleNA = () => {
    if (readOnly) return;

    if (score === NAValue) {
      setScore(null);
    } else {
      setScore(NAValue);
    }
  };

  const handleNotesChange = useCallback((evt) => {
    const text = evt.target.value;

    setNotes(text);
    onNotes(item.id, text);
  }, [item, setNotes, onNotes]);

  let notesContent = null;
  if (status !== 'neut' && readOnly) {
    if (!!notes) {
      notesContent = (
        <NotesParagraph dangerouslySetInnerHTML={{ __html: notes.replace('\n', '<br/>') }} />
      );
    }
  } else if (status !== 'neut' && !readOnly) {
    notesContent = (
      <NotesField
        className='form-control'
        rows={4}
        placeholder="Notes"
        disabled={readOnly}
        value={notes}
        onChange={handleNotesChange}
      />
    );
  }

  return (
    <ItemLi style={{ color: COLORS[status] }}>
      <div className="d-flex">
        <PassButton checked={status === 'pass'} readOnly={readOnly} onClick={togglePass}>
          <i className="fa fa-check" />
        </PassButton>
        <FailButton checked={status === 'fail'} readOnly={readOnly} onClick={toggleFail}>
          <i className="fa fa-times" />
        </FailButton>
        <NaButton checked={status === 'na'} readOnly={readOnly} onClick={toggleNA}>
          N/A
        </NaButton>
        <span>
          {item.name}
          {item.auto_fail && (<span className="badge badge-danger ml-2">auto fail</span>)}
        </span>
      </div>
      {notesContent}
    </ItemLi>
  )
};

export {PassButton, FailButton, NaButton};

export default ScorecardItem;
