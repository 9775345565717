import React, { useState, useMemo, useRef, useCallback } from 'react';
import axios from 'axios';
import cx from 'classnames';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import { optionsToDict, dictToArr } from '../helpers';
import LoadingIndicator from '../common/LoadingIndicator';
import Configuration from './Configuration';
import ScoresTable from './ScoresTable';
import ScorecardItemScoresTable from './ScorecardItemScoresTable';
import TrendsChart from './TrendsChart';
import RecentTrendsTable from './RecentTrendsTable';
import GroupPicker from './GroupPicker';
import AgentsPicker from './AgentsPicker';
import { transformScoresData, commonToUserIds } from './helpers';

const LoadingContainer = styled.div`
  position: relative;
  height: 300px;
`;

const Main = ({
  data: baseData,
}) => {
  const [data, setData] = useState(null);
  const [itemsData, setItemsData] = useState(null);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const [visualizedAgents, setVisualizedAgents] = useState([]);
  const [trendsData, setTrendsData] = useState(null);
  const [trendsDates, setTrendsDates] = useState(null);

  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsAgents, setItemsAgents] = useState([]);
  const cachedItemsUserIds = useRef([]);

  const agents = useMemo(() => optionsToDict(baseData.agents), [baseData]);
  const groups = useMemo(() => optionsToDict(baseData.groups), [baseData]);
  const sortedScoresArr = useRef([]);

  const resetPrevState = () => {
    setData(null)
    setTrendsData(null);
    setTrendsDates(null);
    setVisualizedAgents([]);
    setItemsData(null);
    cachedItemsUserIds.current = [];
  };

  const handleConfig = (filters) => {
    const params = {
      "filters[period_unit]": filters.periodUnit,
      "filters[scorecard_id]": filters.scorecardId,
    };

    setFilters(filters);
    resetPrevState();

    if (filters.perScorecardItem) {
      fetchItemsData(params, itemsAgents, true);
    } else {
      setItemsAgents([]);
      fetchData(params);
    }
  };

  const fetchData = params => {
    setLoading(true);

    axios.get('/scored_calls/data', {
      params,
    }).then(({ data }) => {
      const sanitizedScores = transformScoresData(data.scores);
      setData(sanitizedScores);
      sortedScoresArr.current = orderBy(dictToArr(sanitizedScores), ['value']);

      setTrendsData({
        'all': data.aggregation.trends,
      });
      setTrendsDates(data.aggregation.periods);

      setVisualizedAgents([]);
    }).finally(() => {
      setLoading(false);
    });
  };

  const fetchItemsData = useCallback((params, agentIds, firstLoad = false) => {
    const userIds = commonToUserIds(agentIds, baseData.groupAgents);
    const dueFetchUserIds = difference(userIds, cachedItemsUserIds.current);
    if (dueFetchUserIds.length === 0) return;

    cachedItemsUserIds.current = uniq(cachedItemsUserIds.current.concat(dueFetchUserIds));
    if (firstLoad || !itemsData) setLoading(true);
    setItemsLoading(true);

    axios.get('/scored_calls/items_data', {
      params: {
        ...params,
        agent_ids: dueFetchUserIds,
      },
    }).then(({ data }) => {
      if (firstLoad || !itemsData) {
        setItemsData(data);
      } else {
        const newScores = {
          ...itemsData.scores,
          ...data.scores
        };
        const newSummaries = {
          ...itemsData.summaries,
          ...data.summaries
        };

        setItemsData({
          ...itemsData,
          scores: newScores,
          summaries: newSummaries,
        });
      }
    }).finally(() => {
      setLoading(false);
      setItemsLoading(false);
    });
  }, [itemsData, setLoading, setItemsLoading, setItemsData]);

  const addTrendsData = (agentIds, reset = false) => {
    const newAgentIds = reset ? agentIds : difference(agentIds, visualizedAgents);
    const cachedAgents = Object.keys(trendsData || {});
    const dueFetchAgents = difference(newAgentIds, cachedAgents);

    setVisualizedAgents(reset ? newAgentIds : visualizedAgents.concat(newAgentIds));

    if (dueFetchAgents.length > 0) {
      const params = {
        "filters[period_unit]": filters.periodUnit,
        "filters[scorecard_id]": filters.scorecardId,
        "agent_ids": dueFetchAgents,
      };

      axios.get('/scored_calls/trends', {
        params,
      }).then(res => {
        const { trends } = res.data;

        if (trendsData) {
          setTrendsData({ ...trendsData, ...trends });
        } else {
          setTrendsData(trends);
        }
      });
    }
  };

  const handleItemsAgentsChange = itemsAgents => {
    setItemsAgents(itemsAgents);

    debouncedItemsFetcher({
      "filters[period_unit]": filters.periodUnit,
      "filters[scorecard_id]": filters.scorecardId,
    }, itemsAgents);
  };
  const debouncedItemsFetcher = useMemo(
    () => debounce(fetchItemsData, 2000)
  , [itemsData, setLoading, setItemsData]);

  const handleAddVis = (agentIds) => {
    addTrendsData(agentIds);
  };

  const handleRemoveVis = (agentIds) => {
    setVisualizedAgents(difference(visualizedAgents, agentIds));
  };

  const handleAddGroupVis = (commonizedGroupId) => {
    addTrendsData([commonizedGroupId]);
  };

  const handleRemoveGroupVis = (commonizedGroupId) => {
    setVisualizedAgents(difference(visualizedAgents, [commonizedGroupId]));
  };

  const showBestTrends = () => {
    addTrendsData(sortedScoresArr.current.slice(-5).map(s => s.key), true);
  };

  const showWorstTrends = () => {
    addTrendsData(sortedScoresArr.current.slice(0, 5).map(s => s.key), true);
  };

  const hideTrends = () => {
    setVisualizedAgents([]);
  };

  const showScorecardScores = !filters.perScorecardItem && !!data;
  const showItemScores = filters.perScorecardItem && !!itemsData;

  return (
    <main>
      <Configuration data={baseData} onChange={handleConfig} />
      {filters.perScorecardItem && (
        <AgentsPicker
          groups={baseData.groups}
          agents={baseData.agents}
          onSelect={handleItemsAgentsChange}
          initialValue={[]}
        />
      )}
      <LoadingContainer className={cx({'d-none': !loading})}>
        <LoadingIndicator visible={loading} />
      </LoadingContainer>
      <div className={cx({'d-none': loading})}>
        <div className="mt-5">
          {(trendsData && trendsDates) && (
            <TrendsChart
              agents={agents}
              groups={groups}
              data={trendsData}
              dates={trendsDates}
              visualizedAgents={visualizedAgents}
              periodUnit={filters.periodUnit}
            />
          )}
        </div>
        <div className="mt-5">
          {trendsData && (
            <RecentTrendsTable
              agents={agents}
              groups={groups}
              data={trendsData}
              visualizedAgents={visualizedAgents}
              onRemoveVis={handleRemoveVis}
            />
          )}
        </div>
        <div>
          {trendsData && (
            <GroupPicker
              groups={groups}
              data={trendsData}
              visualizedAgents={visualizedAgents}
              onAddVis={handleAddGroupVis}
              onRemoveVis={handleRemoveGroupVis}
            />
          )}
        </div>
        <div className="mt-5">
          {showScorecardScores && (
            <ScoresTable
              scores={data}
              agents={baseData.agents}
              visualizedAgents={visualizedAgents}
              periodUnit={filters.periodUnit}
              onAddVis={handleAddVis}
              onRemoveVis={handleRemoveVis}
              onShowBestTrends={showBestTrends}
              onShowWorstTrends={showWorstTrends}
              onHideTrends={hideTrends}
            />
          )}
          {showItemScores && (
            <ScorecardItemScoresTable
              agents={baseData.agents}
              groupAgents={baseData.groupAgents}
              visibleAgents={itemsAgents}
              data={itemsData}
              periodUnit={filters.periodUnit}
              loading={itemsLoading}
            />
          )}
        </div>
      </div>
    </main>
  )
};

export default Main;
