import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Store } from 'react-notifications-component';
import LeftSlidePanel from '../common/popovers/LeftSlidePanel';
import MultiSelect from '../common/form/MultiSelect';
import { InputLabelBig } from '../common/styles';
import { setupAxiosCSRF } from '../helpers';

const PanelWrapper = styled.div`
  padding: 32px;
`;

const MessageField = styled.textarea`
  margin-bottom: 18px;
  padding: 12px !important;
`;

const AskReviewButton = ({
  users,
  url,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [reviewers, setReviewers] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setupAxiosCSRF();
  }, []);

  useEffect(() => {
    if (!panelOpen) {
      setMessage('');
      setReviewers([]);
      setSubmitting(false);
    }
  }, [panelOpen]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      await axios.post(url, {
        review: {
          message,
          user_ids: reviewers,
        },
      });

      setPanelOpen(false);
      Store.addNotification({
        title: "Success",
        message: "The review request is submitted",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });
    } catch (e) {
      console.log('Error asking for a review: ', e);
    }

    setSubmitting(false);
  };

  return (
    <div className="mr-2">
      <button className="btn btn-primary" onClick={() => setPanelOpen(true)}>
        Ask for a review
      </button>

      <LeftSlidePanel
        width="450px"
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
      >
        {panelOpen && (
          <PanelWrapper>
            <h4 style={{ marginBottom: 32 }}>Ask for a review</h4>

            <InputLabelBig>Message (optional)</InputLabelBig>
            <MessageField
              className='form-control'
              rows={4}
              placeholder="Message"
              value={message}
              onChange={handleMessageChange}
            />

            <InputLabelBig>Reviewers</InputLabelBig>
            <MultiSelect
              initialValue={reviewers}
              options={users}
              onChange={setReviewers}
            />

            <div className="row" style={{ paddingLeft: 32, paddingRight: 32, marginTop: 32 }}>
              <div className="offset-md-3 col-md-6">
                <button className="btn btn-primary btn-block btn-lg" onClick={handleSubmit} disabled={submitting || !reviewers.length}>
                  {submitting ? 'Submitting ...' : 'Submit'}
                </button>
              </div>
            </div>
          </PanelWrapper>
        )}
      </LeftSlidePanel>
    </div>
  );
};

export default AskReviewButton;
