import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { BackDrop } from '../styles';

const LeftSlidePanel = ({
  open = false,
  width = 'auto',
  children,
  onClose,
}) => {
  const [slidedOut, setSlidedOut] = useState(open);
  // const [visible, setVisible] = useState(open);

  useEffect(() => {
    setSlidedOut(open);
  }, [open]);

  return (
    <Card className={cx("card card-shadow card-rect", { 'd-none': !open })} style={{ width, transform: slidedOut ? 'translateX(-100%) translateX(-10px)' : 'none' }}>
      <Closer onClick={onClose}>
        <i className='fa fa-times' />
      </Closer>
      {children}
    </Card>
  )
};

export default LeftSlidePanel;

const Card = styled.div`
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 100vw;
  height: calc(100vh);
  transition: 0.2s all;
`;

const Closer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  right: 20px;
  top: 20px;
  background-color: #ff5d39;
  color: white;
`;
