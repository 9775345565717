import React from 'react';
import { beautifyDuration } from '../../helpers';
import SimpleValueWidget from './common/SimpleValueWidget';
import GoalAndScoreWidget from './common/GoalAndScoreWidget';
import GoalAndScoreLabel from './common/GoalAndScoreLabel';

const PALETTE = { score: '#9FA509', background: '#F8FCCA', target: '#D4E157' };

const AhtWidget = ({
  metric
}) => {
  const { score, goal } = metric;

  const intScore = Math.round(score);

  if (!goal) {
    return (
      <SimpleValueWidget
        title="Average call length"
        value={beautifyDuration(intScore)}
      />
    );
  }

  const max = Math.max(intScore, goal);

  return (
    <GoalAndScoreWidget
      title="Average call length"
      label={beautifyDuration(intScore)}
      max={max}
      goal={goal}
      score={intScore}
      palette={PALETTE}
    >
      <GoalAndScoreLabel
        goal={beautifyDuration(goal)}
        score={beautifyDuration(intScore)}
        palette={PALETTE}
      />
    </GoalAndScoreWidget>
  );
};

export default AhtWidget;
