import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const customStyles = {
  control: (provided) => {
    return {
      ...provided,
      borderRadius: '16px',
      borderColor: '#EBEBEC'
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: '5px 12px',
    };
  },
  indicatorSeparator: () => ({ display: 'hidden' }),
};

const MultiSelect = ({
  initialValue,
  options,
  onChange,
  disabled,
  placeholder,
  ...reactSelectProps
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const selection = options.filter(option => initialValue.includes(option.value));

    setSelectedOptions(selection);
  }, [options]);

  const handleChange = (selection) => {
    setSelectedOptions(selection);
    onChange(selection.map(option => option.value));
  };

  return (
    <Select
      isMulti
      value={selectedOptions}
      options={options}
      onChange={handleChange}
      styles={customStyles}
      isDisabled={disabled}
      placeholder={placeholder}
      {...reactSelectProps}
    />
  );
};

export default MultiSelect;
