import React, { useRef } from 'react';
import compact from 'lodash/compact';
import { useTable } from 'react-table/src/hooks/useTable'
import { DefaultTblStyle } from '../common/tables/styles';

const evalFormsColumns = (isAdmin, showLinks) => compact([
  {
    Header: 'Name',
    accessor: 'name',
  },
  showLinks && {
    Header: '',
    id: 'actions',
    Cell: ({ row }) => {
      return (
        <div className="text-right">
          {/* TODO: access control based on user role */}
          {isAdmin && <a href={`/eval_forms/${row.original.id}/edit`} className="mr-2">Show/Edit</a>}
          {isAdmin && <a href={`/eval_forms/${row.original.id}`} data-method="delete" data-confirm="Are you sure?">Delete</a>}
        </div>
      );
    }
  }
]);

const EvalFormsTable = ({
  evalForms,
  isAdmin,
  showLinks
}) => {
  const columns = useRef(
    evalFormsColumns(isAdmin, showLinks)
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: columns.current, data: evalForms })

  return (
    <DefaultTblStyle noTitle>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
  );
}

export default EvalFormsTable;
