import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table/src/hooks/useTable';
import { DefaultTblStyle } from '../common/tables/styles';
import { PassButton, FailButton, NaButton } from '../Call/scoring/ScorecardItem';
import styled from 'styled-components';
import moment from 'moment';

const FilterBlock = styled.div`
  margin-top: 24px;
`;

const FilterContent = styled.div`
  max-width: 400px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
`;


const generateTblColumns = (dates, showScore) => {
    const columns = [
      {
        Header: 'Item',
        accessor: 'item',
        Cell: ({ row, value }) => {
          if ( typeof value == 'object' ) {
            return (
              <div key="item">
                <span>{value['name']}</span>
                {value['autofail'] && <span className="badge badge-danger ml-2">auto fail</span>}
              </div>
            );
          } else {
            return value;
          }
        }
      }
    ];
    for (let date of dates) {
      columns.push(
        {
          Header: moment(date.substring(0, 10), "MM DD YYYY").format('M/D/YY'),
          id: date + (Math.random() + 1).toString(36).substring(7),
          accessor: d => d[date],
          Cell: ({ row, value }) => {
            if (value && typeof value === 'object') {
              // If the scorecard is selected and empty do not show the score.
              if (!showScore) {
                return;
              }
              let score = value['score']?.toString() || '';
              if (value['autoscored']) {
                score += '*';
              }
              return score;
            } else if (typeof value == 'number') {
              return value.toString();
            } else {
              if (value !== true && value !== false) {
                if (!value || value === 'na') {
                  return (
                  <div key="na">
                    <NaButton checked={false} readOnly={true}>
                      N/A
                    </NaButton>
                  </div>
                  );
                } else {
                  return (<div key="call"><a href={'calls/' + value} target="_blank">View</a></div>);
                }
              } else if (value) {
                return (
                  <div key="true">
                    <PassButton checked={value} readOnly={true}>
                      <i className="fa fa-check"></i>
                    </PassButton>
                  </div>
                );
              } else {
                return (
                  <div key='false'>
                    <FailButton checked={value} readOnly={true}>
                      <i className="fa fa-times"></i>
                    </FailButton>
                  </div>
                )
              }
            }
          }
        }
      )
    }
    return columns;
  };


const ScorecardTable = ({
    data,
    agentMetrics,
    agent,
    filterParams
  }) => {
    const [columns, setColumns] = useState([]);
    const scorecardIsSelected = Boolean(filterParams['filters[scorecard]']);
    const tableData = scorecardIsSelected ? ((data[agent] || {})['data'] || []): [];
    const showScore = tableData.length > 2 ? true : false;

    useEffect(()=>{
      if (data && agent) {
        setColumns(
          generateTblColumns(scorecardIsSelected ? ((data[agent] || {})['recording_dates'] || []): [], showScore)
        );
      }
    },[filterParams, data, agent]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data: tableData});
    return (
      <DefaultTblStyle>
        <h4>Scorecard</h4>
        <h4 className="text-center">Date scored</h4>
        <div id="agent_scorecards">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr key={row.id} {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          key={cell.id}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </DefaultTblStyle>
     );
  }


  export default ScorecardTable;
