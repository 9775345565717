import React, { useMemo } from 'react';
import { useTable } from 'react-table/src/hooks/useTable'
import { useSortBy } from 'react-table/src/plugin-hooks/useSortBy';
import styled from 'styled-components';
import truncate from 'lodash/truncate';
import { ScrollingTblStyle } from '../common/tables/styles';
import PALETTE from '../palette';
import { numericFalsyLast, caseInsensitiveComp, commonToUserIds } from './helpers';
import { PERIOD_UNIT } from './Configuration';

const AGENT_NAME_COLUMN = 'agentName';
const CALL_COUNT_COLUMN = 'callCount';
const AVG_SCORE_COLUMN = 'avgScore';
const CALL_COUNT_LOWER_LIMIT = 20;

const ScorecardItemHeader = styled.div`
  min-width: 200px;
`;
const AgentNameCell = styled.div`
  white-space: nowrap;
`;
const cellBgColor = cell => {
  if (cell.column.id === AGENT_NAME_COLUMN || cell.column.id === CALL_COUNT_COLUMN) return null;

  const score = cell.value;
  if (score === undefined || score === null) return null;

  let bgColor = PALETTE.RED.bg;

  if (score >= 85) {
    bgColor = PALETTE.GREEN.bg;
  } else if (score >= 70) {
    bgColor = PALETTE.YELLOW.bg;
  }

  return bgColor;
};
const scoreCellRenderer = ({ value: score }) => {
  if (score === undefined || score === null) return <small className='de-emphasize'>NA</small>;

  let content = [];
  let scoreColor = PALETTE.RED.fg;

  if (score >= 85) {
    scoreColor = PALETTE.GREEN.fg;
  } else if (score >= 70) {
    scoreColor = PALETTE.YELLOW.fg;
  }
  content.push(
    <span key="score" style={{ color: scoreColor }}>{score}%</span>
  );

  return content;
};

const generateTblColumns = (items) => {
  const columns = [
    {
      Header: 'Agent',
      accessor: AGENT_NAME_COLUMN,
      sortType: 'caseInsensitiveComp',
      Cell: ({ value: agentName }) => <AgentNameCell>{agentName}</AgentNameCell>
    },
    {
      Header: 'Number of calls',
      accessor: CALL_COUNT_COLUMN,
      sortType: 'numericFalsyLast',
    },
    {
      Header: 'Avg. score',
      accessor: AVG_SCORE_COLUMN,
      sortType: 'numericFalsyLast',
      Cell: scoreCellRenderer,
    }
  ];

  for (let item of items) {
    columns.push({
      Header: <ScorecardItemHeader title={item.name}>{truncate(item.name, {length: 50})}</ScorecardItemHeader>,
      accessor: item.id,
      sortType: 'numericFalsyLast',
      Cell: scoreCellRenderer,
    });
  }

  return columns;
};

const ScorecardItemScoresTable = ({
  agents,
  groupAgents,
  visibleAgents,
  data,
  periodUnit,
  loading,
}) => {
  const visibleUserIds = useMemo(() => {
    return commonToUserIds(visibleAgents, groupAgents);
  }, [groupAgents, visibleAgents]);
  const periodLabel = useMemo(() => {
    switch (periodUnit) {
      case PERIOD_UNIT.DAY: {
        return 'Yesterday';
      }
      case PERIOD_UNIT.WEEK: {
        return 'Last week';
      }
      case PERIOD_UNIT.MONTH: {
        return 'Last month';
      }
    }

    return 'N/A'; //wont reach here
  }, [periodUnit]);

  const sortTypes = useMemo(
    () => ({
      numericFalsyLast,
      caseInsensitiveComp,
    }),
    []
  );
  const columns = useMemo(() => {
    if (!data.items) return [];

    return generateTblColumns(data.items);
  }, [data]);
  const tblData = useMemo(() => {
    if (!agents || !data) return [];

    const rows = [];
    for (let agent of agents) {
      const agentId = agent[0];
      if (!visibleUserIds.includes(agentId)) continue;

      const agentName = agent[1];
      const agentScores = data.scores[agentId];
      const agentSummary = data.summaries[agentId];

      if (!agentScores) continue;
      if (!agentSummary) continue;
      if (agentSummary.count < CALL_COUNT_LOWER_LIMIT) continue;

      const row = { agentId, agentName };
      for (let itemId in agentScores) {
        const scoreSummary = agentScores[itemId];

        if (scoreSummary.count > 0) {
          row[itemId] = (scoreSummary.sum / scoreSummary.count).toFixed(1)*1;
        }
      }

      row[CALL_COUNT_COLUMN] = agentSummary.count;
      if (agentSummary.count > 0) {
        row[AVG_SCORE_COLUMN] = (agentSummary.sum / agentSummary.count).toFixed(1)*1;
      }

      rows.push(row);
    }

    return rows;
  }, [agents, data, visibleUserIds]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
        columns: columns,
        data: tblData,
        sortTypes,
        initialState: {
          sortBy: [{ id: AVG_SCORE_COLUMN, desc: true }],
        },
      }, useSortBy);

  return (
    <div>
      <h4 className="mb-3">
        {periodLabel} avg.
        {loading && (
          <img src="/images/loader.gif" height="30px" className="ml-1" />
        )}
      </h4>
      <ScrollingTblStyle noTitle>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(
                      {
                        ...column.getSortByToggleProps(),
                      }
                    )}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' ↓'
                          : ' ↑'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const bgColor = cellBgColor(cell);
                    return (
                      <td
                        {...cell.getCellProps()}
                        {...(bgColor ? {style: { backgroundColor: bgColor }} : {})}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </ScrollingTblStyle>
    </div>
  );
};

export default ScorecardItemScoresTable;
