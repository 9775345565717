import React from 'react';
import styled from 'styled-components';
import TotalCallsWidget from '../Dashboard/widgets/TotalCallsWidget';
import ActiveAgentsWidget from '../Dashboard/widgets/ActiveAgentsWidget';
import AhtWidget from '../Dashboard/widgets/AhtWidget';
import CompletionWidget from '../Dashboard/widgets/CompletionWidget';
import MonologueWidget from '../Dashboard/widgets/MonologueWidget';
import QuestionsWidget from '../Dashboard/widgets/QuestionsWidget';
import TalkRatioWidget from '../Dashboard/widgets/TalkRatioWidget';
import ComplianceViolationsWidget from './widgets/ComplianceViolationsWidget';

const METRICS = ['compliance', 'completion', 'monologue', 'questions', 'talk_ratio'];

const WidgetComponents = {
  compliance: ComplianceViolationsWidget,
  completion: CompletionWidget,
  monologue: MonologueWidget,
  questions: QuestionsWidget,
  talk_ratio: TalkRatioWidget,
};

const Panel = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: calc((100% - 120px) / 5);

    h5 {
      margin-bottom: 24px;
    }

    /* GoalAndScoreLabel component */
    .score-board {
      display: block;

      > div:first-child {
        margin-right: 0px;
      }
    }
  }
`;

const ReportScoreWidgets = ({
  metrics
}) => {
  return (
    <Panel className="mt-4">
      {METRICS.map(metricKey => {
        const WidgetComp = WidgetComponents[metricKey];
        const metric = metrics[metricKey];

        return (
          <div key={metricKey} className="mb-4">
            <WidgetComp metric={metric} />
          </div>
        );
      })}
    </Panel>
  )
};

export default ReportScoreWidgets;
