import React from 'react';
import SimpleValueWidget from './common/SimpleValueWidget';

const ComplianceWidget = ({
  metric
}) => {
  const { score, goal } = metric;

  return (
    <SimpleValueWidget
      title="Compliance violations"
      value={score}
    />
  )
};

export default ComplianceWidget;
