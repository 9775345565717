import React, {useState, useEffect} from 'react';
import { useTable } from 'react-table/src/hooks/useTable';
import { DefaultTblStyle } from '../common/tables/styles';
import { FilterContent, FilterBlock } from './Main';
import SingleSelect from '../common/form/SingleSelect';


const generateTblColumns = (agents, updateAgentChanged, agentNumber, agentOptions) => {
  const columns = [
    {
      Header: '',
      accessor: 'item',
      Cell: ({ row, value }) => {
        if ( typeof value == 'object' ) {
          return (
            <div key="item">
              <span>{value['name']}</span>
              {value['autofail'] && <span className="badge badge-danger ml-2">auto fail</span>}
            </div>
          );
        } else {
          if (value === 'Item') {
            return (<strong>{value}</strong>);
          } else {
            return value;
          }
        }
      }
    }
  ];
  for (let i = 0; i < agentNumber; i++) {
    columns.push(
      {
        Header: '',
        id: (agents[i] || 'agent' + i) + (Math.random() + 1).toString(36).substring(7),
        accessor: agents[i] || 'agent' + i,
        Cell: ({ row, value }) => {
          if (row['index'] === 0) {
            return (
              <div key={'agent' + i}>
                <FilterContent>
                  <FilterBlock>
                      <SingleSelect
                        initialValue={agents[i]}
                        options={agentOptions}
                        onChange={(e) => updateAgentChanged(i)(e)}
                      />
                  </FilterBlock>
                </FilterContent>
              </div>
            )} else if ( typeof value === 'number') {
              return value.toFixed(0);
            } else {
              return value;
            }
          }
      }
    );
  }
  return columns;
};


const Benchmarking = ({data, agentOptions, filterParams}) => {
  const scorecardIsSelected = Boolean(filterParams['filters[scorecard]']);
  const [agentNumber, setAgentNumber] = useState(1);
  const [agents, setAgents] = useState([null]);
  const [columns, setColumns] = useState(generateTblColumns(agents, updateAgentChanged, agentNumber, agentOptions));

  const updateAgentChanged = index => value => {
    let newArr = [...agents];
    newArr[index] = value;
    setAgents(newArr);
  }

  useEffect(()=>{
    setColumns(
      generateTblColumns(agents, updateAgentChanged, agentNumber, agentOptions)
    );
  },[data, agentNumber, agents]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: (scorecardIsSelected ? data: [])});

  const addAgent = () => {
    if (agentNumber >= 5) {
      alert('The maximum number of agents that can be compared is 5.');
    } else {
      setAgentNumber(agentNumber + 1);
      let newArr = [...agents];
      newArr.push(null)
      setAgents(newArr);
    }
  };

  const removeAgent = () => {
    if (agentNumber > 1) {
      setAgentNumber(agentNumber - 1);
      let newArr = [...agents];
      newArr.pop()
      setAgents(newArr);
    }
  };
  return (
    <DefaultTblStyle>
      <h4>Benchmarking</h4>
      <button style={{marginLeft: '30px'}} className="btn btn-primary btn-sm" onClick={addAgent}>Add agent</button>
      <button style={{marginLeft: '30px'}} className="btn btn-primary btn-sm" onClick={removeAgent}>Remove agent</button>
      <div id="benchmarking">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        key={cell.id}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </DefaultTblStyle>
   );
}

export default Benchmarking;
