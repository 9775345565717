import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import LinearWidgets from './LinearWidgets';
import ChartWidgets from './ChartWidgets';
import AgentMetricsTable from './AgentMetricsTable';
import RecordingsTable from '../Reports/RecordingsTable';
import { PaginatedTableContainer } from '../Reports/styles';

const genFilterParams = filters => {
  const params = {};

  if (filters['call_map']) {
    params["filters[call_map]"] = filters['call_map'];
  }
  if (filters['date_range']) {
    params["filters[date_range]"] = filters['date_range'];
  }

  return params;
}

const Main = ({
  filters: initialFilters = {},
  data: initialData
}) => {
  const filtersListener = useRef(null);
  const [data, setData] = useState(initialData);
  const [agent] = useState(initialData['agent']);
  const [page, setPage] = useState(0);
  const [params, setParams] = useState({
    ...genFilterParams(initialFilters),
    agent_id: agent?.id,
  });
  const [filterParams, setFilterParams] = useState(genFilterParams(initialFilters));

  useEffect(() => {
    filtersListener.current = window.addEventListener('dashboard.filters', event => {
      const { detail: filters } = event;

      const filterParams = genFilterParams(filters);
      const newParams = {
        ...params,
        ...filterParams,
        page: agent ? 1 : null,
      };

      setFilterParams(filterParams);
      setParams(newParams);

      axios.get('/dashboard/recordings', {
        params: newParams,
      }).then(res => {
        setData(res.data);
        setPage(0);
      });
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('dashboard.filters', filtersListener.current);
      }
    };
  }, []);

  // Agent dashboard only
  const handlePageClick = ({ selected: newPage }) => {
    if (page === newPage) return;

    const newParams = {
      ...params,
      skip_metrics: true,
      page: newPage + 1,
    };

    setParams(newParams);
    setPage(newPage);

    axios.get('/dashboard/recordings', {
      params: newParams,
    }).then(res => {
      setData({
        ...data,
        ...res.data
      });
    });
  };

  return (
    <main>
      <LinearWidgets metrics={data.metrics} hideAgents={!!agent} />
      <ChartWidgets metrics={data.metrics} />
      <div className='row'>
        <div className='col-md-12'>
          {agent ? (
            <PaginatedTableContainer>
              <RecordingsTable
                title={`Calls by ${agent.name}`}
                recordings={data.recordings}
                hideAgent
              />
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                previousLabel="<"
                forcePage={page}
                pageCount={data.pagination.total}
                onPageChange={handlePageClick}
                renderOnZeroPageCount={null}
                className="paginator"
                disabledClassName="disabled-page"
                activeClassName="active-page"
                pageRangeDisplayed={10}
              />
            </PaginatedTableContainer>
          ) : (
            <AgentMetricsTable
              data={data.agent_metrics}
              visibleMetrics={data.visible_metrics}
              filterParams={filterParams}
            />
          )}
        </div>
      </div>
    </main>
  )
};

export default Main;
