import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { BackDrop } from '../styles';

const LeftSlidePanel = ({
  open = false,
  width = 'auto',
  children,
  onClose,
}) => {
  const [slidedOut, setSlidedOut] = useState(open);
  // const [visible, setVisible] = useState(open);

  useEffect(() => {
    setSlidedOut(open);
  }, [open]);

  return (
    <BackDrop className={cx({ 'd-none': !open })}>
      <Card className="card card-shadow" style={{ width, transform: slidedOut ? 'translateX(-100%) translateX(-30px)' : 'none' }}>
        <Closer onClick={onClose}>
          <i className='fa fa-times' />
        </Closer>
        {children}
      </Card>
    </BackDrop>
  )
};

export default LeftSlidePanel;

const Card = styled.div`
  position: absolute;
  top: 20px;
  left: 100vw;
  height: calc(100vh - 40px);
  transition: 0.2s all;
`;

const Closer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff5d39;
  color: white;
`;

