import React, { useState, useMemo, useEffect, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import MultiSelect from '../common/form/MultiSelect';
import Checkbox from '../common/form/Checkbox';
import { InputLabelBig, BlockInput } from '../common/styles';
import { DATE_PREDEFINED_RANGES, arrToSelectorOption, momentFromDateStr } from '../helpers';

const FilterBlock = styled.div`
  margin-bottom: 24px;
  flex-grow: 1;
  flex-basis: 0;

  &:not(:first-child) {
    margin-left: 30px;
  }
`;

const FiltersRow = ({
  data,
}) => {
  const [startDate, setStartDate] = useState(
    momentFromDateStr(data.initialFilters?.date_from_str, moment().subtract(89,'d'))
  );
  const [endDate, setEndDate] = useState(
    momentFromDateStr(data.initialFilters?.date_to_str, moment())
  );
  const [agentIds, setAgentIds] = useState(data.initialFilters?.agents || []);
  const [reviewerIds, setReviewerIds] = useState(data.initialFilters?.reviewers || []);
  const [formIds, setFormIds] = useState(data.initialFilters?.forms || []);
  const [scorecardIds, setScorecardIds] = useState([]);
  const [campaigns, setCampaigns] = useState(data.initialFilters?.campaigns || []);
  const [needFollowUp, setNeedFollowUp] = useState(false);
  const [options, setOptions] = useState({
    agents: [],
    reviewers: [],
    forms: [],
    scorecards: [],
    campaigns: [],
  });
  const [reviewScoped, setReviewScoped] = useState(true);
  const scopeSwitchListener = useRef(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    const groupOptions = data.groups.map(group => {
      return {
        value: `g:${group[0]}`,
        label: <span><i className="fa fa-users"></i> {group[1]}</span>,
      };
    });
    const agentOptions = [
      { label: 'Anyone', value: null },
      ...groupOptions,
      ...data.agents.map(arrToSelectorOption)
    ];
    const reviewerOptions = [
      { label: 'Anyone', value: null },
      ...groupOptions,
      ...data.reviewers.map(arrToSelectorOption)
    ];
    const formOptions = data.forms.map(arrToSelectorOption);
    const scorecardOptions = data.scorecards.map(arrToSelectorOption);
    const campaignOptions = data.campaigns.map(arrToSelectorOption);

    setOptions({
      ...options,
      agents: agentOptions,
      reviewers: reviewerOptions,
      forms: formOptions,
      scorecards: scorecardOptions,
      campaigns: campaignOptions,
    });
  }, [data]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      updateGlobalFilterRefs();
    } else {
      notifyFilterChanges();
    }
  }, [agentIds, reviewerIds, formIds, scorecardIds, campaigns, needFollowUp]);

  useEffect(() => {
    scopeSwitchListener.current = window.addEventListener('admin_reviews.scope', event => {
      const { detail: scope } = event;

      setReviewScoped(scope === 'reviews');
    });

    if (scopeSwitchListener.current) {
      window.removeEventListener('admin_reviews.scope', scopeSwitchListener.current);
    }
  }, []);

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    notifyFilterChanges({ startDate: start, endDate: end });
  };

  const notifyFilterChanges = (overrides = {}) => {
    const state = {
      startDate,
      endDate,
      agentIds,
      reviewerIds,
      formIds,
      scorecardIds,
      needFollowUp,
      campaigns,
      ...overrides
    };

    const payload = {
      agents: state.agentIds,
      reviewers: state.reviewerIds,
      forms: state.formIds,
      scorecards: state.scorecardIds,
      campaigns: state.campaigns,
      need_follow_up: state.needFollowUp,
      date_range: `${state.startDate.format('MM/DD/YYYY')} - ${state.endDate.format('MM/DD/YYYY')}`,
      skip_fetch: state.skipFetch,
    };

    window.dispatchEvent(
      new CustomEvent('admin_reviews.filters', { detail: payload })
    );
  };

  const updateGlobalFilterRefs = () => {
    if (!data.initialFilters) return;

    setTimeout(() => {
      notifyFilterChanges({ skipFetch: true }); // needed when switching to scorings
    }, 500); // need time until `Main` and `ScoringsMain` mounts
  };

  return (
    <div>
      {reviewScoped && (
        <div className="d-flex align-items-center mb-3">
          <InputLabelBig className="mr-2 mb-0">Requires follow-up only</InputLabelBig>
          <Checkbox initialValue={needFollowUp} onChange={setNeedFollowUp} />
        </div>
      )}
      <div className='d-flex'>
        <FilterBlock>
          <InputLabelBig>Period</InputLabelBig>
          <DateRangePicker
            initialSettings={{
              ranges: DATE_PREDEFINED_RANGES,
              alwaysShowCalendars: true,
              startDate,
              endDate,
            }}
            onCallback={handleDateRangeChange}
          >
            <BlockInput />
          </DateRangePicker>
        </FilterBlock>
        <FilterBlock>
          <InputLabelBig>Agents</InputLabelBig>
          <MultiSelect
            initialValue={agentIds}
            options={options.agents}
            onChange={setAgentIds}
            placeholder="Anyone"
          />
        </FilterBlock>
        <FilterBlock>
          <InputLabelBig>{reviewScoped ? 'Reviewers' : 'Scorers'}</InputLabelBig>
          <MultiSelect
            initialValue={reviewerIds}
            options={options.reviewers}
            onChange={setReviewerIds}
            placeholder="Anyone"
          />
        </FilterBlock>
        <FilterBlock>
          <InputLabelBig>{reviewScoped ? 'Form' : 'Scorecard'}</InputLabelBig>
          {reviewScoped ? (
            <MultiSelect
              key="form-filter"
              initialValue={formIds}
              options={options.forms}
              onChange={setFormIds}
              placeholder="Any form"
            />
          ) : (
            <MultiSelect
              key="scorecard-filter"
              initialValue={scorecardIds}
              options={options.scorecards}
              onChange={setScorecardIds}
              placeholder="Any scorecard"
            />
          )}
        </FilterBlock>
        {!reviewScoped && options.campaigns.length > 0 && (
          <FilterBlock>
            <InputLabelBig>Campaign</InputLabelBig>
            <MultiSelect
              initialValue={campaigns}
              options={options.campaigns}
              onChange={setCampaigns}
              placeholder="Any campaign"
            />
          </FilterBlock>
        )}
      </div>
    </div>
  );
};

export default FiltersRow;
