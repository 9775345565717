import React from 'react';
import { TagCloud } from 'react-tagcloud'

const Main = ({
  data,
}) => {
  debugger;
  return (
    <main>
    <TagCloud
      minSize={12}
      maxSize={35}
      tags={data.top_words}
    />
    </main>
  )
};

export default Main;