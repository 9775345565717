import { GROUPING } from './GroupingsPanel';

export const getNavToReviewsParams = (filters, factors) => {
  const overrideFilters = {};
  for (let filterKey in factors) {
    const filterValue = factors[filterKey];
    switch (filterKey) {
      case GROUPING.AGENT:
        overrideFilters.agents = [filterValue];
        break;
      case GROUPING.REVIEWER:
        overrideFilters.reviewers = [filterValue];
        break;
      case GROUPING.FORM:
        overrideFilters.forms = [filterValue];
        break;
      default:
        break;
    }
  }

  return new URLSearchParams({
    ...filters,
    ...overrideFilters
  }).toString();
};

export const dissectNavFromAnalyticsParams = () => {
  const params = Object.fromEntries(  
    new URLSearchParams(window.location.search)
  );

  const saneParams = {};
  for (let paramKey in params) {
    const paramValueStr = params[paramKey];
    if (!paramValueStr) continue;

    if (paramKey !== 'dateRange') {
      saneParams[paramKey] = paramValueStr.split(',');
    } else {
      saneparams[paramKey] = paramValueStr;
    }
  }

  return saneParams;
};
