import React, { useMemo } from 'react';
import styled from 'styled-components';
import { InputLabelBig } from '../common/styles';
import { commonizeGroupId } from './helpers';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const GroupPicker = ({
  groups,
  data,
  visualizedAgents,
  onAddVis,
  onRemoveVis,
}) => {
  const hasGroups = useMemo(() => Object.keys(groups).length > 0, [groups]);

  return (
    <Container>
      <InputLabelBig className="mt-3">{hasGroups ? 'Trends by group' : 'No groups'}</InputLabelBig>
      {Object.keys(groups).map(groupId => {
        const groupName = groups[groupId];
        const commonizedId = commonizeGroupId(groupId);
        const selected = visualizedAgents.includes(commonizedId);

        return (
          <button
            key={`group-${groupId}`}
            className={`btn ${selected ? 'btn-primary' : 'btn-primary-outline'} btn-lg ml-3 mt-3`}
            onClick={() => selected ? onRemoveVis(commonizedId) : onAddVis(commonizedId)}
          >
            <i className="fa fa-users" />&nbsp;{groupName}
          </button>
        )
      })}
    </Container>
  );
};

export default GroupPicker;
