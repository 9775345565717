import axios from 'axios';
import moment from 'moment';
import PALETTE from './palette';

export const DATE_PREDEFINED_RANGES = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'This Week': [moment().startOf('week'), moment().endOf('week')],
  'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Year': [moment().startOf('year'), moment().endOf('year')],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
};

export const METRICS = {
  completion: 'Completion',
  compliance: 'Compliance violations',
  total_calls: 'Total of calls made',
  active_agents: 'Active agents',
  monologue: 'Monologue',
  questions: 'Questions',
  talk_ratio: 'Talk / Listen',
  aht: 'Call length',
};

export const setupAxiosCSRF = () => {
  const tokenDom = document.querySelector("meta[name=csrf-token]")
  if (tokenDom) {
     const csrfToken = tokenDom.content
     axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  }
};

export const beautifyDuration = (durationInSecs) => {
  const mins = Math.floor(durationInSecs / 60);
  const secs = durationInSecs % 60;

  if (!mins) return `${secs}s`;

  return `${mins}:${padNum(secs, 2)}`;
};

export const meterTime = totalSecs => {
  const roundedSecs = Math.round(totalSecs);
  const mins = Math.floor(roundedSecs / 60);
  const secs = roundedSecs % 60;

  return `${padNum(mins, 2)}:${padNum(secs, 2)}`;
};

export const padNum = (num, size) => {
  const s = "000000000" + num;
  return s.substring(s.length-size);
};

export const withinGoalRange = (score, goal) => {
  return (score <= goal * 1.1) && (score >= goal * 0.9);
};

export const formatMetricValue = (value, metric, hasGoal) => {
  if (['total_calls', 'active_agents', 'compliance'].includes(metric)) return Math.round(value);
  if (['completion', 'talk_ratio'].includes(metric)) return `${Math.round(value)}%`;
  if (metric === 'aht') return beautifyDuration(Math.round(value));

  if (metric === 'questions') {
    return hasGoal ? Math.round(value) : `${Math.round(value)}%`;
  }
  if (metric === 'monologue') {
    return hasGoal ? beautifyDuration(Math.round(value)) : `${Math.round(value)}%`;
  }

  return '-';
};

export const scoreColor = score => {
  let color = PALETTE.NEUTRAL['900'];
  if (score >= 75) {
    color = PALETTE.GREEN.light;
  } else if (score < 30) {
    color = PALETTE.RED.light;
  }

  return color;
};

export const arrToSelectorOption = arr => ({ value: arr[0], label: arr[1] });

export const dictToArr = dict => {
  const arr = [];
  for (let key in dict) {
    arr.push({ key, value: dict[key] });
  }

  return arr;
};

export const optionsToDict = options => {
  const dict = {};
  for (let item of options) {
    dict[item[0]] = item[1];
  }

  return dict;
};

export const deltaBetween = (val, comp) => {
  if (val === comp) return 0;

  if (comp === 0) {
    return 100;
  } else {
    return Math.round(((val - comp) / comp) * 100);
  }
};

export const momentFromDateStr = (str, defaults = null, dateFormat = 'MM/DD/YYYY') => {
  if (!str) return defaults;

  return moment(str, dateFormat);
};

export const getAsteriskNotation = (scorecards, scored_scorecards) => {
  let scoredMap = new Map();

  if (scored_scorecards && typeof scored_scorecards === 'object' && scored_scorecards !== null) {
    scoredMap = new Map(Object.entries(scored_scorecards));
  }

  const autoScored = scorecards.filter(scorecard => scorecard.auto_score && scoredMap.has(scorecard.id));
  const manualScored = scorecards.filter(scorecard => !scorecard.auto_score && scoredMap.has(scorecard.id));

  if (autoScored.length > 0 && manualScored.length > 0) {
    return '**';
  } else if (autoScored.length > 0 && manualScored.length === 0) {
    return '*';
  }

  return '';
};
