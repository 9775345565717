import React, { useRef } from 'react';
import styled from 'styled-components';
import { BlockInput } from '../styles';

const Container = styled.div`
  position: relative;

  .clear-btn {
    position: absolute;
    top: 9px;
    right: 8px;
    cursor: pointer;
    padding: 0px 4px;
  }
`;

const ClearableBlockInput = ({
  reactiveClear = false,
  onClear,
  ...otherProps
}) => {
  const inputRef = useRef();

  const handleClearClick = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    onClear();
  };

  const showClearBtn = !reactiveClear || !!otherProps.value;

  return (
    <Container>
      <BlockInput ref={inputRef} {...otherProps} />
      {showClearBtn && (
        <span className="clear-btn" onClick={handleClearClick}>
          <i className="fa fa-times" />
        </span>
      )}
    </Container>
  )
};

export default ClearableBlockInput;
