import React from 'react';
import ScorecardsTable from './ScorecardsTable';

const Main = ({
  isManager,
  hasPermission,
  data,
}) => {
  return (
    <main>
      <ScorecardsTable
        scorecards={data.scorecards}
        showLinks={isManager}
        hasPermission={hasPermission}
        />
    </main>
  )
};

export default Main;
