import styled from 'styled-components';

export default styled.div`
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000215;
`;
