import React, { useState} from 'react';

const Analytics = ({
  embeddableId
}) => {
  return (
    <main>
      <div class="accordion" id="analyticsAccordion">
        <div class="card">
          <div class="card-header" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true">
            <span class="title">Analytics</span>
            <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
          </div>
          <div id="collapseOne" class="collapse show" data-parent="#analyticsAccordion">
            <div class="card-body">
              <em-beddable token={embeddableId}/>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Analytics;