import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { PaginatedTableContainer } from '../Reports/styles';
import { setupAxiosCSRF } from '../helpers';
import ReviewsTable from './ReviewsTable';

const Main = ({
  data: initialData,
  allowDelete,
}) => {
  const filtersListener = useRef(null);
  const scopeSwitchListener = useRef(null);
  const managerToggleListener = useRef(null)
  const deleteListener = useRef(null);
  const params = useRef({});
  const visiblility = useRef(true);
  const [visible, setVisible] = useState(true);
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setupAxiosCSRF();

    filtersListener.current = window.addEventListener('admin_reviews.filters', event => {
      const { detail: filters } = event;
      params.current = {
        ...params.current,
        "filters[date_range]": filters['date_range'],
        "filters[agents]": filters['agents'],
        "filters[reviewers]": filters['reviewers'],
        "filters[forms]": filters['forms'],
        "filters[need_follow_up]": filters['need_follow_up'],
        page: 1
      };

      if (visiblility.current && !filters['skip_fetch']) {
        fetchNewDataset(params.current);
      }
    });

    scopeSwitchListener.current = window.addEventListener('admin_reviews.scope', event => {
      const { detail: scope } = event;

      if (scope === 'reviews') {
        params.current = {
          ...params.current,
          page: 1
        };

        setVisible(true);
        fetchNewDataset(params.current);
      } else {
        setVisible(false);
      }
    });

    managerToggleListener.current = window.addEventListener('admin_reviews.manager_toggle', event => {
      const {detail: isManager } = event;
      params.current = {
        ...params.current,
        manager: isManager
      }

      if (visiblility.current) {
        fetchNewDataset(params.current)
      }
    })

    deleteListener.current = window.addEventListener('admin_reviews.delete', event => {
      const { detail: reviewId } = event;

      axios.delete(`/admin_reviews/${reviewId}`)
        .then(() => {
          fetchNewDataset(params.current, true);
        });
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('admin_reviews.filters', filtersListener.current);
      }
      if (scopeSwitchListener.current) {
        window.removeEventListener('admin_reviews.scope', scopeSwitchListener.current);
      }
      if (deleteListener.current) {
        window.removeEventListener('admin_reviews.delete', deleteListener.current);
      }

      if (managerToggleListener.current) {
        window.removeEventListener('admin_reviews.manager_toggle', managerToggleListener.current);
      }
    };
  }, []);

  useEffect(() => {
    visiblility.current = visible;
  }, [visible]);

  const fetchNewDataset = (params, preservePage = false) => {
    axios.get('/admin_reviews/data', {
      params,
    }).then(res => {
      setData(res.data);
      if (!preservePage) setPage(0);
    });
  };

  const handlePageClick = ({ selected: newPage }) => {
    if (page === newPage) return;

    params.current = {
      ...params.current,
      page: newPage + 1,
    };

    setPage(newPage);

    axios.get('/admin_reviews/data', {
      params: params.current,
    }).then(res => {
      setData(res.data);
    });
  };

  if (!visible) return null;

  return (
    <main>
      <PaginatedTableContainer>
        <ReviewsTable reviews={data.reviews} allowDelete={allowDelete}/>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          forcePage={page}
          pageCount={data.pagination.total}
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
          className="paginator"
          activeClassName="active-page"
          pageRangeDisplayed={10}
        />
      </PaginatedTableContainer>
    </main>
  );
};

export default Main;
