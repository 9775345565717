import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { useTable } from 'react-table/src/hooks/useTable'
import { useSortBy } from 'react-table/src/plugin-hooks/useSortBy';
import { ScrollingTblStyle } from '../common/tables/styles';
import ClearableBlockInput from '../common/form/ClearableBlockInput';
import PALETTE from '../palette';
import { numericFalsyLast, caseInsensitiveComp } from './helpers';
import { PERIOD_UNIT } from './Configuration';

const tblColumns = [
  {
    Header: 'Agent',
    accessor: 'agentName',
    sortType: 'caseInsensitiveComp',
  },
  {
    Header: 'Score',
    accessor: 'score',
    sortType: 'numericFalsyLast',
    Cell: ({ value: score }) => {
      if (score === undefined || score === null) return <small className='de-emphasize'>NA</small>;

      let content = [];
      let scoreColor = PALETTE.NEUTRAL['900'];

      if (score >= 75) {
        scoreColor = PALETTE.GREEN.light;
      } else if (score < 30) {
        scoreColor = PALETTE.RED.light;
      }
      content.push(
        <span key="score" style={{ color: scoreColor }}>{score}</span>
      );

      return content;
    },
  },
  {
    Header: 'Trends',
    id: 'trends',
    Cell: ({ row, visAgentIds, addVis, removeVis }) => {
      const { agentId } = row.original;
      const visualised = visAgentIds.includes(agentId);

      return (
        <button
          className={cx('trends-btn btn btn-sm', visualised ? 'btn-primary' : 'btn-primary-outline')}
          onClick={() => visualised ? removeVis([agentId]) : addVis([agentId])}
        >
          <span className={cx({ 'd-none': !visualised })}>
            <i className='fa fa-check' />
          </span>
        </button>
      );
    },
  }
];

const ScoresTable = ({
  scores,
  agents,
  visualizedAgents,
  periodUnit,
  onAddVis,
  onRemoveVis,
  onShowBestTrends,
  onShowWorstTrends,
  onHideTrends,
}) => {
  const [agentSearch, setAgentSearch] = useState();
  const periodLabel = useMemo(() => {
    switch (periodUnit) {
      case PERIOD_UNIT.DAY: {
        return 'Yesterday';
      }
      case PERIOD_UNIT.WEEK: {
        return 'Last week';
      }
      case PERIOD_UNIT.MONTH: {
        return 'Last month';
      }
    }

    return 'N/A'; //wont reach here
  }, [periodUnit]);

  const sortTypes = useMemo(
    () => ({
      numericFalsyLast,
      caseInsensitiveComp,
    }),
    []
  );
  const scoreRows = useMemo(() => {
    const rows = [];
    for (let agent of agents) {
      const agentId = agent[0];
      const agentName = agent[1];

      if (agentSearch && !(agentName || '').toLowerCase().includes(agentSearch)) {
        continue;
      }

      rows.push({
        agentId,
        agentName,
        score: scores[agentId],
      });
    }

    return rows;
  }, [scores, agents, agentSearch]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: tblColumns, data: scoreRows, sortTypes }, useSortBy);

  const handleSearch = (evt) => {
    const { target: { value } } = evt;

    setAgentSearch(value.trim().toLowerCase());
  };
  const handleClearSearch = () => {
    setAgentSearch(null);
  };
  const debouncedSearchHandler = useMemo(
    () => debounce(handleSearch, 1500)
  , []);

  return (
    <div>
      <h4>{periodLabel} avg.</h4>
      <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
        <ClearableBlockInput
          placeholder="Search agents"
          style={{ width: 400 }}
          onChange={debouncedSearchHandler}
          onClear={handleClearSearch}
        />
        <div className="d-flex align-items-center">
          <button
            className="btn btn-primary btn-lg"
            onClick={onShowBestTrends}
          >Show trends for best 5 agents</button>
          <button
            className="btn btn-primary btn-lg ml-3"
            onClick={onShowWorstTrends}
          >Show trends for worst 5 agents</button>
          <button
            className="btn btn-primary-outline btn-lg ml-3"
            onClick={onHideTrends}
          >Hide all trends</button>
        </div>
      </div>

      <ScrollingTblStyle noTitle>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' ↓'
                          : ' ↑'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell', { visAgentIds: visualizedAgents, addVis: onAddVis, removeVis: onRemoveVis })}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </ScrollingTblStyle>
    </div>
   );
};

export default ScoresTable;
