import React, { useState, useMemo, useEffect } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import SingleSelect from '../common/form/SingleSelect';
import { arrToSelectorOption } from '../helpers';
import { InputLabelBig, BlockInput, ScopeSwitchLabel } from '../common/styles';

const FilterBlock = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  &:not(:first-child) {
    margin-left: 30px;
  }
`;

export const PERIOD_UNIT = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

export const PERIOD_UNIT_LABELS = {
  [PERIOD_UNIT.DAY]: 'Day',
  [PERIOD_UNIT.WEEK]: 'Week',
  [PERIOD_UNIT.MONTH]: 'Month',
};

const Configuration = ({
  data,
  onChange,
}) => {
  const [periodUnit, setPeriodUnit] = useState(PERIOD_UNIT.WEEK);
  const [perScorecardItem, setPerScorecardItem] = useState(false);
  const [scorecardId, setScorecardId] = useState(null);

  const options = useMemo(() => ({
    scorecards: [
      { label: 'Any scorecard', value: null },
      ...data.scorecards.map(arrToSelectorOption)
    ],
    periodUnits: Object.values(PERIOD_UNIT).map(pUnit => ({ value: pUnit, label: PERIOD_UNIT_LABELS[pUnit] })),
  }), [data]);

  const handleApply = () => {
    onChange({
      periodUnit,
      perScorecardItem,
      scorecardId
    });
  };

  return (
    <div className="scored-calls-analytics-config">
      <div className="d-flex mb-3">
        <FilterBlock>
          <div className="d-flex justify-content-between">
            <InputLabelBig>Scorecard</InputLabelBig>
            <div className="d-flex align-items-center mb-2">
              <Switch
                checked={perScorecardItem}
                onChange={setPerScorecardItem}
                onColor="#FF5D39"
                offColor="#888"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={33}
              />
              <ScopeSwitchLabel className="mb-0 ml-2">Per scorecard items</ScopeSwitchLabel>
            </div>
          </div>
          <SingleSelect
            initialValue={scorecardId}
            options={options.scorecards}
            onChange={setScorecardId}
            menuPortalTarget={document.body}
            classNamePrefix="scored-calls-select"
          />
        </FilterBlock>
        <FilterBlock>
          <InputLabelBig>Period unit</InputLabelBig>
          <SingleSelect
            initialValue={periodUnit}
            options={options.periodUnits}
            onChange={setPeriodUnit}
            menuPortalTarget={document.body}
            classNamePrefix="scored-calls-select"
          />
        </FilterBlock>
      </div>
      <div className="d-flex mb-3">
        <FilterBlock className="d-flex justify-content-end align-items-end">
          <button
            className="btn btn-primary btn-lg"
            disabled={perScorecardItem && !scorecardId}
            onClick={handleApply}
          >Apply</button>
        </FilterBlock>
      </div>
    </div>
  )
};

export default Configuration;
