import React, { useEffect, useState } from 'react';
import { VictoryPie } from 'victory-pie';
import { VictoryLabel } from 'victory-core';
import CenteredLabel from './CenteredLabel';

const getPieData = (percent) => {
  return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
};

const getRangedPieData = (percentStart, percentEnd) => {
  return [
    { x: 1, y: percentStart },
    { x: 2, y: percentEnd - percentStart },
    { x: 3, y: 100 - percentEnd },
  ]
};

const GoalAndScoreChart = ({
  goal,
  score,
  max,
  min = 0,
  label,
  palette,
}) => {
  const [targetRange, setTargetRange] = useState([0,0]);
  const [chartMax, setChartMax] = useState(0);

  useEffect(() => {
    setTargetRange(
      [ goal * 0.9, goal * 1.1 ]
    );
  }, [goal]);

  useEffect(() => {
    setChartMax(max * 1.01);
  }, [max]);

  if (!chartMax) return null;

  const scoreData = getPieData((score / chartMax) * 100);
  const placeholderData = getPieData(100);
  const targetData = getRangedPieData((targetRange[0] / chartMax) * 100, (targetRange[1] / chartMax) * 100);

  return (
    <div className="container">
      <svg viewBox="0 0 400 400" width="100%" height="100%">
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400} height={400}
          data={placeholderData}
          innerRadius={160}
          radius={200}
          labels={() => null}
          style={{
            data: { fill: palette.background }
          }}
        />
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400} height={400}
          data={scoreData}
          innerRadius={160}
          radius={200}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: { fill: ({ datum }) => {
              return datum.x === 1 ? palette.score : "transparent";
            } }
          }}
        />

        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400} height={400}
          data={targetData}
          innerRadius={125}
          radius={145}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: { fill: ({ datum }) => {
              return datum.x === 2 ? palette.target : "transparent";
            } }
          }}
        />
      </svg>
      <CenteredLabel>{label}</CenteredLabel>
    </div>
  )
};

export default GoalAndScoreChart;
