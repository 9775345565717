import React, { useState } from 'react';
import Switch from 'react-switch';
import { ScopeSwitchLabel } from '../common/styles';

const TitleScope = () => {
  const [showScorings, setShowScorings] = useState(false);

  const handleScopeChange = showScorings => {
    setShowScorings(showScorings);

    window.dispatchEvent(
      new CustomEvent('admin_reviews.scope', { detail: showScorings ? 'scorings' : 'reviews' })
    );
  };

  return (
    <div>
      <h4>{showScorings ? 'Call scores' : 'Reviews'}</h4>
      <div className="d-flex align-items-center mt-4">
        <ScopeSwitchLabel className="mr-2" highlighted={!showScorings}>Reviews</ScopeSwitchLabel>
        <Switch
          checked={showScorings}
          onChange={handleScopeChange}
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          onColor="#888"
          offColor="#888"
          height={15}
          width={33}
        />
        <ScopeSwitchLabel className="ml-2" highlighted={showScorings}>Call scores</ScopeSwitchLabel>
      </div>
    </div>
  )
};

export default TitleScope;
