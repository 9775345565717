import React from 'react';
import SimpleValueWidget from './common/SimpleValueWidget';

const TotalCallsWidget = ({
  metric
}) => {
  const { score, goal } = metric;

  return (
    <SimpleValueWidget
      title="Total of calls made"
      value={score}
    />
  )
};

export default TotalCallsWidget;
