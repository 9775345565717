import React from 'react';
import { VictoryPie } from "victory-pie";
import CenteredLabel from './CenteredLabel';

const getPieData = (percent) => {
  return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
};

const DoubleDonut = ({
  primaryPercent,
  secondaryPercent,
  label = '34%',
  palette = {},
}) => {
  const primaryData = getPieData(primaryPercent);
  const placeholderData = getPieData(100);

  return (
    <div className="container">
      <svg viewBox="0 0 400 400" width="100%" height="100%">
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400} height={400}
          data={placeholderData}
          innerRadius={160}
          radius={200}
          labels={() => null}
          style={{
            data: { fill: ({ datum }) => {
              const color = palette.background || (primaryPercent > 50 ? "#DDECE6" : "#FFE0DD");
              return color;
            } }
          }}
        />
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400} height={400}
          data={primaryData}
          innerRadius={160}
          radius={200}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: { fill: ({ datum }) => {
              let color = palette.score || (datum.y > 50 ? "#00644B" : "#A70010");
              return datum.x === 1 ? color : "transparent";
            }
            }
          }}
        />

        {!!secondaryPercent && (
          <VictoryPie
            standalone={false}
            animate={{ duration: 1000 }}
            width={400} height={400}
            data={getPieData(secondaryPercent)}
            innerRadius={125}
            radius={145}
            cornerRadius={25}
            labels={() => null}
            style={{
              data: { fill: ({ datum }) => {
                const color = datum.y > 50 ? "green" : "red";
                return datum.x === 1 ? color : "transparent";
              }
              }
            }}
          />
        )}
      </svg>
      <CenteredLabel>{label}</CenteredLabel>
    </div>
  )
};

export default DoubleDonut;
