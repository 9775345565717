import React from 'react';
import { WidgetStyle } from '../../../common/styles';
import DoubleDonut from '../../../common/charts/DoubleDonut';
import { ChartWrapper } from './styles';

const SimpleScoreWidget = ({
  title,
  ...chartProps
}) => {
  return (
    <WidgetStyle>
      <h5>{title}</h5>
      <ChartWrapper>
        <DoubleDonut {...chartProps} />
      </ChartWrapper>
    </WidgetStyle>
  );
};

export default SimpleScoreWidget;
