import React from "react";
import ExportButton from "./ExportButton";

const ManagerSwitch = ({agentUrl}) => {
  return (
    <>
    <a href={agentUrl}>
      <i className="fa fa-exchange-alt mr-2" aria-hidden="true"></i> As manager
      </a>
      <ExportButton />
    </>
  );
}

export default ManagerSwitch;
