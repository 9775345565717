import React, { useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BlockInput, InputLabelBig } from '../common/styles';
import { DATE_PREDEFINED_RANGES } from '../helpers';
import Select from 'react-select';

const Filters = ({
  onChange,
  agents,
  groups,
}) => {
  const [startDate, setStartDate] = useState(moment().subtract(6, 'd'));
  const [endDate, setEndDate] = useState(moment());
  const [selectedAgent, setSelectedAgent] = useState('');
  const [isScored, setIsScored] = useState(false);

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    onChange({
      date_range: `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`
    });
  };

  const handleAgentChange = (selectedOptions) => {
    setSelectedAgent(selectedOptions);

    if (selectedOptions.some(option => option.value === 'All')) {
      onChange({ agent: 'All' });
    } else {
      onChange({ agent: selectedOptions.map(option => option.value) });
    }
  };

  const groupOptions = groups.map(group => {
    return {
      value: `g:${group[0]}`,
      label: <span><i className="fa fa-users"></i> {group[1]}</span>,
    };
  });

  const agentOptions = [
    { label: 'All', value: 'All' },
    ...groupOptions,
    ...agents.map(usr => ({ value: usr[0], label: usr[1] }))
  ];

  const handleScoredChange = (event) => {
    setIsScored(event.target.checked);

    onChange({
      isScored: event.target.checked
    });
  };

  return (
    <>
    <div className='row mb-4'>
      <div className='col-md-4'>
        <InputLabelBig>Period</InputLabelBig>
        <DateRangePicker
          initialSettings={{
            ranges: DATE_PREDEFINED_RANGES,
            alwaysShowCalendars: true,
            opens: 'left',
            startDate,
            endDate,
          }}
          onCallback={handleDateRangeChange}
        >
          <BlockInput />
        </DateRangePicker>
      </div>
    </div>
    <div className='row mb-4'>
      <div className='col-md-4'>
        <InputLabelBig>Agent</InputLabelBig>
        <Select
            isMulti
            options={agentOptions}
            value={selectedAgent}
            onChange={handleAgentChange}
          />
      </div>
    </div>
    <div className="row mb-4">
      <div className='col-md-4'>
        <input
          type="checkbox"
          checked={isScored}
          onChange={handleScoredChange}
          className='mr-2'
        />
        <InputLabelBig>Scored calls only</InputLabelBig>
      </div>
    </div>
    </>
  );
};

export default Filters;


