import React, { useRef } from 'react';
import moment from 'moment';
import compact from 'lodash/compact';
import { useTable } from 'react-table/src/hooks/useTable'
import { DefaultTblStyle } from '../common/tables/styles';
import PALETTE from '../palette';
import { beautifyDuration } from '../helpers';

const callsColumns = [
  {
    Header: 'Date',
    id: 'date_time',
    accessor: r => r.date,
  },
  {
    Header: 'Contacts',
    id: 'contacts',
    accessor: r => r.contacts || '-',
  },
  {
    Header: 'Call map',
    accessor: 'call_map',
  },
  {
    Header: <span>Compliance<br />violations</span>,
    accessor: 'compliance_violations',
  },
  {
    Header: 'Call length',
    accessor: r => beautifyDuration(r.duration),
  },
  {
    Header: 'Disposition',
    accessor: 'disposition',
    Cell: ({ value }) => {
      return <span dangerouslySetInnerHTML={{__html: value}} />
    },
  },
  {
    Header: 'Score',
    accessor: 'score',
    Cell: ({ value }) => {
      if (value === null || value === undefined) return '';

      let color = PALETTE.NEUTRAL['900'];
      if (value >= 75) {
        color = PALETTE.GREEN.light;
      } else if (value < 30) {
        color = PALETTE.RED.light;
      }

      return <span style={{ color }}>{parseInt(value)}</span>;
    },
  },
  {
    Header: '',
    id: 'view_details',
    Cell: ({ row }) => {
      return <a href={`/calls/${row.original.id}`} target="_blank">Details</a>
    }
  }
];

const CallsTable = ({
  calls,
  title = 'Calls',
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: callsColumns, data: calls })

   return (
    <DefaultTblStyle>
      <h4>{title}</h4>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
   );
 }

 export default CallsTable;
