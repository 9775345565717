import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import WaveSurfer from "wavesurfer.js";
import MarkersPlugin from 'wavesurfer.js/src/plugin/markers';
import { toAudioPlayerDurationFormat, downloadFile } from '../../helpers';
import SpeedControl from './player/SpeedControl';
import DownloadButton from './player/DownloadButton';
import { PROMPT_FG } from './colors';


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: #FAFAFA;
  border-radius: 16px;
  height: 70px;
  padding: 15px 24px;
`;

const LoaderWraper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusBar = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 140px;
  font-weight: 400;
  font-size: 12px;
  color: #000215;

  span {
    display: inline-block;
    margin-left: 5px;
    opacity: 0.6;
  }
`;

const PlayButtonWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 45px;
`;

const PlayButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #FF5D39;
  width: 33px;
  height: 33px;
  border-radius: 33px;
  color: white;
`;

const PlayerWrapper = styled.div`
  flex-grow: 1;
`;

const SpeedControlContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const DownloadButtonContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 45px;
`;

const formWaveSurferOptions = (ref, prompts, customerPromptIds, notifiablePromptIds, overtalkDetections, heldTimeReached, prolongedSilences) => {
  const markers = [];
  for (let prompt of prompts) {
    if (prompt.time === undefined) continue;

    let promptType = 'agent';
    if (notifiablePromptIds.includes(prompt.id)) {
      promptType = 'violation';
    } else if (customerPromptIds.includes(prompt.id)) {
      promptType = 'customer';
    }

    markers.push({
      time: Math.max(1, prompt.time),
      color: PROMPT_FG[promptType],
      position: 'bottom'
    })
  }

  for (let overtalk of overtalkDetections) {
    if (overtalk.start === undefined || overtalk.duration === undefined) continue;

    let promptType = 'violation';

    markers.push({
      time: Math.max(1, overtalk.start),
      color: PROMPT_FG[promptType],
      position: 'bottom'
    });
  }

  for (let prolongedSilence of prolongedSilences) {
    if (prolongedSilence.start === undefined) continue;

    let promptType = 'violation';

    markers.push({
      time: Math.max(1, prolongedSilence.start),
      color: PROMPT_FG[promptType],
      position: 'bottom'
    });
  }

  if (heldTimeReached) {
    if (heldTimeReached.start === undefined || heldTimeReached.reached === undefined) return;
    let promptType = 'violation';

    markers.push({
      time: Math.max(1, heldTimeReached.reached),
      color: PROMPT_FG[promptType],
      position: 'bottom'
    });
  }

  return {
    container: ref,
    waveColor: "#D6D7DA",
    progressColor: "#000215",
    cursorColor: "transparent",
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 40,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use this so that playing sped up audio will not result in higher pitch,
    // see https://github.com/katspaugh/wavesurfer.js/issues/149#issuecomment-310880071
    backend: 'MediaElement',
    // Cannot use the PeakCache to improve rendering speed of large waveforms,
    // as it is incompatible with the MediaElement backend
    partialRender: false,
    plugins: [
      MarkersPlugin.create({
        markers,
      }),
    ]
  }
};

const AudioReplay = ({
  urls,
  prompts,
  notifiablePromptIds,
  customerPromptIds,
  overtalkDetections,
  prolongedSilences,
  heldTimeReached,
  wip = false,
  isManager,
  isAdmin,
  allowDownloadRecording,
  recordingID,
}) => {
  if (wip || urls.length !== 1) return null;

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const jumpListener = useRef(null);
  const promptJumpListener = useRef(null);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [audioUrl, setAudioUrl] = useState(urls[0]);
  const [audioUrlRefreshCount, setAudioUrlRefreshCount] = useState(0);
  const [lastPlaybackTime, setLastPlaybackTime] = useState(0);

  const canDownload = allowDownloadRecording && (isManager || isAdmin);

  const isPlaying = () => wavesurfer.current && wavesurfer.current.isPlaying();

  useEffect(() => {
    const options = formWaveSurferOptions(waveformRef.current, prompts, customerPromptIds, notifiablePromptIds, overtalkDetections, heldTimeReached, prolongedSilences);
    wavesurfer.current = WaveSurfer.create(options);

    fetchAudio(audioUrl);

    wavesurfer.current.on("ready", function() {
      setLoading(false);
      setIsReady(true);
      setDuration(wavesurfer.current.getDuration());

      wavesurfer.current.seekTo(lastPlaybackTime / wavesurfer.current.getDuration());

      if (isPlaying()) {
        handlePlayPause();
      }
    });

    wavesurfer.current.on("audioprocess", function() {
      updatePlayStatus();
    });

    wavesurfer.current.on("seek", function() {
      updatePlayStatus();
    });

    wavesurfer.current.on('finish', function () {
      updatePlayStatus();
    });

    wavesurfer.current.on("error", function(err) {
      console.error("An error occurred with the audio player:", err);
      refreshAudioUrl();
    });

    jumpListener.current = window.addEventListener('replay.jump', event => {
      const { detail: seconds } = event;
      if (!wavesurfer.current) return;

      wavesurfer.current.skip(seconds - wavesurfer.current.getCurrentTime());
      if (!isPlaying()) wavesurfer.current.play();
    });

    promptJumpListener.current = window.addEventListener('replay.prompt_jump', event => {
      const { detail: times } = event;
      if (!wavesurfer.current) return;

      const currentTime = wavesurfer.current.getCurrentTime();

      let targetTime = times[0];
      for (let time of times) {
        if (time > currentTime) {
          targetTime = time;
          break;
        }
      }

      wavesurfer.current.skip(targetTime - currentTime);
      if (!isPlaying()) wavesurfer.current.play();
    });

    return () => {
      if (wavesurfer.current) {
        setLastPlaybackTime(wavesurfer.current.getCurrentTime());
        wavesurfer.current.destroy();
      }

      if (jumpListener.current) {
        window.removeEventListener('replay.jump', jumpListener.current);
      }

      if (promptJumpListener.current) {
        window.removeEventListener('replay.prompt_jump', promptJumpListener.current);
      }
    }
  }, [audioUrl]);

  const updatePlayStatus = () => {
    setCurrentTime(wavesurfer.current.getCurrentTime());
  };

  const refreshAudioUrl = async () => {
    if (audioUrlRefreshCount >= 2) {
      console.warn("Refreshed audio URL too many times, giving up");
      return
    }
    setAudioUrlRefreshCount(audioUrlRefreshCount + 1)
    try {
      const response = await axios.post(`/calls/${recordingID}/audio_url`, { call_id: recordingID });
      const newUrl = response.data.url;
      setAudioUrl(newUrl);
      setLoading(true);
    } catch (error) {
      console.error('Failed to refresh audio URL: ', error);
    }
  };

  const fetchAudio = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        // TODO: this 400 code is specific to Thrio i.e. Google storage, not sure if S3 uses the same
        if (response.status === 400) {
          console.log('Audio URL expired, refreshing...');
          wavesurfer.current.pause();
          setLastPlaybackTime(wavesurfer.current.getCurrentTime());
          refreshAudioUrl();
        } else {
          throw new Error(`Failed to fetch audio: ${response.status}`);
        }
      } else {
        setAudioUrlRefreshCount(0)
        wavesurfer.current.load(url);
      }
    } catch (error) {
      console.error('Failed to fetch audio:', error);
    }
  };

  const handlePlayPause = async () => {
    if (!isReady) return;

    if (!isPlaying()) {
      try {
        await wavesurfer.current.play();
      } catch (err) {
        console.error("An error occurred during audio playback:", err);
      }
    } else {
      wavesurfer.current.pause();
      setLastPlaybackTime(wavesurfer.current.getCurrentTime());
    }
  };

  // speed is properly changed only when the player is paused
  const handleSpeedChange = (speed) => {
    const wasPlaying = isPlaying();
    if (wasPlaying) wavesurfer.current.pause();
    wavesurfer.current.setPlaybackRate(speed);
    if (wasPlaying) wavesurfer.current.play();
  };

  const handleAudioDownload = async (fileURL, fileName) => {
    try {
      setDownloading(true);

      const response = await fetch(fileURL);
      if (!response.ok) {
          console.error("There was an error with the fetch request");
          setDownloading(false);
          return;
      }

      const blob = await response.blob();
      await downloadFile(blob, `${fileName}.wav`);

      setDownloading(false);
    } catch (err) {
      console.error("An error occurred during audio download:", err);
      setDownloading(false);
    }
  };

  const isLoaded = duration !== null;

  return (
    <div className="row">
      <div className="col-12">
        <Wrapper>
          {loading && (
            <LoaderWraper>
              <img className="mr-2" src="/images/loader.gif" height="40px" />
            </LoaderWraper>
          )}
          <PlayButtonWrapper>
            {!loading && (
              <PlayButton onClick={handlePlayPause}>
                {isPlaying() ? '❚❚' : '▶'}
              </PlayButton>
            )}
          </PlayButtonWrapper>
          <StatusBar>
            {!loading && (
              <div>
                {toAudioPlayerDurationFormat(currentTime)}
                <span>| {toAudioPlayerDurationFormat(duration)}</span>
              </div>
            )}
          </StatusBar>
          <PlayerWrapper>
           <div ref={waveformRef} />
          </PlayerWrapper>
          <SpeedControlContainer>
            <SpeedControl show={!loading} onChange={handleSpeedChange} />
          </SpeedControlContainer>
          {canDownload && <DownloadButtonContainer>
            {downloading ?
              (<img className="mr-2 my-0" src="/images/loader.gif" height="33px" />) :
              (<DownloadButton show={!loading} onClick={() => handleAudioDownload(audioUrl, recordingID)} />)
            }
          </DownloadButtonContainer>}
        </Wrapper>
      </div>
    </div>
  );
};

export default AudioReplay;
