import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';
import SingleSelect from '../common/form/SingleSelect';
import MultiSelect from '../common/form/MultiSelect';
import SliderSelect from '../common/form/SliderSelect';
import SliderRange from '../common/form/SliderRange';
import Checkbox from '../common/form/Checkbox';
import ClearableBlockInput from '../common/form/ClearableBlockInput';
import { InputLabelBig, BlockInput } from '../common/styles';

const DATE_PREDEFINED_RANGES = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'This Week': [moment().startOf('week'), moment().endOf('week')],
  'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
};

const FilterBlock = styled.div`
  margin-top: 24px;
`;

const FilterContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: 0;
  overflow-y: auto;
  overflow-x: visible;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
`;

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  height: 100%;
`;

const AdvancedFilters = ({
  filters,
  data,
  onChange,
  onReset,
}) => {
  const [callMapId, setCallMapId] = useState(filters.callMapId);
  const [callType, setCallType] = useState(filters.callType);
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [promptCategoryId, setPromptCategoryId] = useState(filters.promptCategoryId);
  const [complianceId, setComplianceId] = useState(filters.complianceId);
  const [outcome, setOutcome] = useState(filters.outcome);
  const [disposition, setDisposition] = useState(filters.disposition);
  const [dialerDisposition, setDialerDisposition] = useState(filters.dialerDisposition);
  const [benchmarkCeil, setBenchmarkCeil] = useState(filters.benchmarkCeil);
  const [completionFloor, setCompletionFloor] = useState(filters.completionFloor);
  const [campaign, setCampaign] = useState(filters.campaign);
  const [metaSearch, setMetaSearch] = useState(filters.metaSearch);
  const [customer, setCustomer] = useState(filters.customer);
  const [contact, setContact] = useState(filters.contact);
  const [dialerId, setDialerId] = useState(filters.dialerId);
  const [phrase, setPhrase] = useState(filters.phrase);
  const [complianceViolationsRange, setComplianceViolationsRange] = useState(filters.complianceViolationsRange);
  const [completionRange, setCompletionRange] = useState(filters.completionRange);
  const [monologueRange, setMonologueRange] = useState(filters.monologueRange);
  const [questionsRange, setQuestionsRange] = useState(filters.questionsRange);
  const [talkRatioRange, setTalkRatioRange] = useState(filters.talkRatioRange);
  const [objectionsRange, setObjectionsRange] = useState(filters.objectionsRange);
  const [durationRange, setDurationRange] = useState(filters.durationRange);
  const [options, setOptions] = useState({
    callMaps: [],
    promptCategories: [],
    campaigns: [],
    callTypes: [],
    dialerDispositions: [],
    outcomes: [
      { label: 'Any outcome', value: null },
      { label: 'None', value: 'undefined' },
      { label: 'Success', value: 'success' },
      { label: 'Fail', value: 'fail' },
    ],
  });
  const [dispositionOptions, setDispositionOptions] = useState([]);
  const [compliancesOptions, setComplianceOptions] = useState(
    [
      { label: 'Any violation', value: null },
      ...data.complianceViolations.map(c => ({ value: c.id, label: c.name }))
    ]
  );
  const isInitialMount = useRef(true);

  useEffect(() => {
    const callMapOptions = [
      { label: 'Any call map', value: null },
      ...data.callMaps.map(cm => ({ value: cm[0], label: cm[1] }))
    ];
    const promptCategoryOptions = [
      { label: 'Any prompt', value: null },
      ...data.promptCategories.map(pc => ({ value: pc[1], label: pc[0] }))
    ];
    const campaignOptions = data.dialerMeta?.campaign ?
      data.dialerMeta?.campaign?.map(c => ({ value: c, label: c }))
      : [];
    const callTypeOptions = data.dialerMeta?.call_type ? [
      { label: 'Any call type', value: null },
      ...data.dialerMeta?.call_type?.map(ct => ({ value: ct, label: ct }))
    ] : [];
    const dialerDispositionOptions = data.dialerMeta?.disposition ?
      data.dialerMeta?.disposition?.map(d => ({ value: d, label: d }))
      : [];

    setOptions({
      ...options,
      callMaps: callMapOptions,
      promptCategories: promptCategoryOptions,
      campaigns: campaignOptions,
      callTypes: callTypeOptions,
      dialerDispositions: dialerDispositionOptions,
    });
  }, [data]);

  useEffect(() => {
    // ensure disposition is not reset when filters slider is just reopen
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setDisposition([]);
    }

    if (callMapId) {
      loadDispositions(callMapId);
      loadCompliances(callMapId);
    }
  }, [callMapId]);


  const handleSave = () => {
    onChange({
      startDate,
      endDate,
      callMapId,
      callType,
      promptCategoryId,
      complianceId,
      benchmarkCeil,
      completionFloor,
      outcome,
      disposition,
      dialerDisposition,
      campaign,
      metaSearch,
      customer,
      contact,
      dialerId,
      phrase,
      complianceViolationsRange,
      completionRange,
      monologueRange,
      questionsRange,
      talkRatioRange,
      objectionsRange,
      durationRange,
    });
  };

  const handleDateRangeChange = (start, end) => {
    if (end.diff(start, 'days') > 31) {
      alert('The time range is too large. Please limit period to 1 month.');
    } else {
      setStartDate(start);
      setEndDate(end);
    }
  };

  const resetComplianceViolations = () => {
    setComplianceOptions(
      [
        { label: 'Any violation', value: null },
        ...data.complianceViolations.map(c => ({ value: c.id, label: c.name }))
      ]
    );
  }

  const handleSetCallMapId = (value) => {
    // If the call map id is set to any call map (i.e. null) reset the compliance violations to default
    if (!value) {
      resetComplianceViolations();
    }
    setCallMapId(value);
  };

  const handleOnReset = () => {
    resetComplianceViolations();
    onReset();
  }

  const loadDispositions = useCallback((callMapId) => {
    axios.get('/agent_scorecards/dispositions', { params: { call_map_id: callMapId } })
          .then(res => {
            setDispositionOptions(
              res.data.dispositions.map(disp => ({ value: disp, label: disp }))
            );
          });
  }, [setDispositionOptions]);

  const loadCompliances = useCallback((callMapId) => {
    axios.get('/agent_scorecards/compliance_violations', { params: { call_map_id: callMapId } })
          .then(res => {
            setComplianceOptions(
              [
                { label: 'Any violation', value: null },
                ...res.data.compliance_violations.map(item => ({ value: item.id, label: item.name }))
              ]
            );
          });
  }, [setComplianceOptions]);

  const hasCampaign = !!data.dialerMeta?.campaign;
  const hasCallType = !!data.dialerMeta?.call_type;
  const hasDialerDisposition = !!data.dialerMeta?.disposition;

  return (
    <PanelWrapper>
      <h4 style={{ padding: '0px 32px 32px' }}>Advanced filters</h4>
      <FilterContent>
        <InputLabelBig>Period</InputLabelBig>
        <DateRangePicker
          initialSettings={{
            ranges: DATE_PREDEFINED_RANGES,
            alwaysShowCalendars: true,
            opens: 'left',
            startDate,
            endDate,
          }}
          onCallback={handleDateRangeChange}
        >
          <BlockInput />
        </DateRangePicker>

        {hasCallType && <FilterBlock>
          <InputLabelBig>Call type</InputLabelBig>
          <SingleSelect
            initialValue={callType}
            options={options.callTypes}
            onChange={setCallType}
          />
        </FilterBlock>}

        {hasCampaign && <FilterBlock>
          <InputLabelBig>Campaign</InputLabelBig>
          <MultiSelect
            initialValue={campaign}
            options={options.campaigns}
            onChange={setCampaign}
            placeholder="Any campaign"
          />
        </FilterBlock>}

        <FilterBlock>
          <InputLabelBig>Call data</InputLabelBig>
          <ClearableBlockInput
            placeholder="Search call data"
            reactiveClear
            value={metaSearch}
            onChange={(e) => setMetaSearch(e.target.value)}
            onClear={() => setMetaSearch('')}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Call map</InputLabelBig>
          <SingleSelect
            initialValue={callMapId}
            options={options.callMaps}
            onChange={handleSetCallMapId}
          />
        </FilterBlock>

        {!hasDialerDisposition && <FilterBlock>
          <InputLabelBig>Disposition</InputLabelBig>
          <MultiSelect
            initialValue={disposition}
            options={dispositionOptions}
            disabled={!callMapId}
            onChange={setDisposition}
            placeholder="Any disposition"
          />
        </FilterBlock>}

        {hasDialerDisposition && <FilterBlock>
          <InputLabelBig>Disposition</InputLabelBig>
          <MultiSelect
            initialValue={dialerDisposition}
            options={options.dialerDispositions}
            onChange={setDialerDisposition}
            placeholder="Any disposition"
          />
        </FilterBlock>}

        <FilterBlock>
          <InputLabelBig>Customer name</InputLabelBig>
          <ClearableBlockInput
            placeholder=""
            reactiveClear
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
            onClear={() => setCustomer('')}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Contact</InputLabelBig>
          <ClearableBlockInput
            placeholder="Contact phone number"
            reactiveClear
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            onClear={() => setContact('')}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Dialer ID</InputLabelBig>
          <ClearableBlockInput
            placeholder=""
            reactiveClear
            value={dialerId}
            onChange={(e) => setDialerId(e.target.value)}
            onClear={() => setDialerId('')}
          />
        </FilterBlock>

        {/* <FilterBlock>
          <InputLabelBig>Word or phrase (agent)</InputLabelBig>
          <ClearableBlockInput
            placeholder=""
            value={phrase}
            onChange={(e) => setPhrase(e.target.value)}
            onClear={() => setPhrase('')}
          />
        </FilterBlock> */}

        <FilterBlock>
          <InputLabelBig>Prompt</InputLabelBig>
          <SingleSelect
            initialValue={promptCategoryId}
            options={options.promptCategories}
            onChange={setPromptCategoryId}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Compliance</InputLabelBig>
          <SingleSelect
            initialValue={complianceId}
            options={compliancesOptions}
            onChange={setComplianceId}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Outcome</InputLabelBig>
          <SingleSelect
            initialValue={outcome}
            options={options.outcomes}
            onChange={setOutcome}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Call length</InputLabelBig>
          <SliderRange
            initialValue={durationRange}
            min={0}
            max={120}
            step={1}
            unit="m"
            onChange={setDurationRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Compliance violations</InputLabelBig>
          <SliderRange
            initialValue={complianceViolationsRange}
            min={0}
            max={20}
            step={1}
            onChange={setComplianceViolationsRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Completion</InputLabelBig>
          <SliderRange
            initialValue={completionRange}
            min={0}
            max={100}
            step={1}
            unit="%"
            onChange={setCompletionRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Monologue</InputLabelBig>
          <SliderRange
            initialValue={monologueRange}
            min={0}
            max={600}
            step={10}
            unit="s"
            onChange={setMonologueRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Questions asked</InputLabelBig>
          <SliderRange
            initialValue={questionsRange}
            min={0}
            max={15}
            step={1}
            onChange={setQuestionsRange}
          />
        </FilterBlock>

        <FilterBlock>
          <InputLabelBig>Talk / Listen ratio</InputLabelBig>
          <SliderRange
            initialValue={talkRatioRange}
            min={0}
            max={100}
            step={1}
            unit="%"
            onChange={setTalkRatioRange}
          />
        </FilterBlock>

        {/* <FilterBlock>
          <InputLabelBig>Customer objections</InputLabelBig>
          <SliderRange
            initialValue={objectionsRange}
            min={0}
            max={15}
            step={1}
            onChange={setObjectionsRange}
          />
        </FilterBlock> */}

        <FilterBlock>
          <InputLabelBig>Benchmark less than</InputLabelBig>
          <SliderSelect
            initialValue={benchmarkCeil}
            min={0}
            max={100}
            step={1}
            onChange={setBenchmarkCeil}
          />
        </FilterBlock>

        {/* <FilterBlock>
          <InputLabelBig>Completion more than</InputLabelBig>
          <SliderSelect
            initialValue={completionFloor}
            min={0}
            max={100}
            step={1}
            onChange={setCompletionFloor}
          />
        </FilterBlock> */}
      </FilterContent>

      <div className="row" style={{ paddingLeft: 32, paddingRight: 32, marginTop: 24 }}>
        <div className="col-md-6">
          <button className="btn btn-neutral btn-block btn-lg" onClick={handleOnReset} style={{ fontWeight: 500 }}>Clear Filters</button>
        </div>
        <div className="col-md-6">
          <button className="btn btn-primary btn-block btn-lg" onClick={handleSave}>Apply Filters</button>
        </div>
      </div>
    </PanelWrapper>
  );
};

export default AdvancedFilters;
