import React, { useState } from 'react';
import Filters from './Filters';
import PeopleSection from './PeopleSection';
import ToggleSwitch from '../LiveCalls/ToggleSwitch';


const Main = ({
  data, showManagerContext, url
}) => {
  const [filters, setFilters] = useState();

  const handleFiltersChange = (updateBody) => {
    setFilters({
      ...(filters || {}),
      ...updateBody
    });
  };

  return (
    <main>
      <ToggleSwitch showManagerContext={showManagerContext} url={url}></ToggleSwitch>
      <Filters onChange={handleFiltersChange} />

      <PeopleSection
        initialData={data.agents}
        filters={filters}
        endpoint="/review_activities/agents"
        tableTitle="Agents without reviews"
        className="mb-5"
      />

      <PeopleSection
        initialData={data.managers}
        filters={filters}
        endpoint="/review_activities/managers"
        tableTitle="Managers without reviews"
      />
    </main>
  );
};

export default Main;
