import React, { useState } from 'react';
import ToggleSwitcher from '../ToggleSwitcher';

const ReviewsScoresSwitch = ({showManagerContext}) => {
  const [toggle, setToggle] = useState(false);
  const handleToggleChange = (isManager) => {
    setToggle(isManager);

    // Dispatching the custom event for manager toggle
    window.dispatchEvent(new CustomEvent('admin_reviews.manager_toggle', { detail: isManager }));
  };
  return (
    <div>
      {showManagerContext && <ToggleSwitcher toggle={toggle} handleToggleChange={handleToggleChange} />}
    </div>
  )
}

export default ReviewsScoresSwitch;
