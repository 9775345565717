import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const customStyles = {
  control: (provided) => {
    return {
      ...provided,
      borderRadius: '16px',
      borderColor: '#EBEBEC'
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: '5px 12px',
    };
  },
  indicatorSeparator: () => ({ display: 'hidden' }),
};

const SingleSelect = ({
  initialValue,
  options,
  disabled = false,
  onChange,
  ...reactSelectProps
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const selection = options.find(option => option.value == initialValue);

    setSelectedOption(selection);
  }, [options]);

  const handleChange = (selection) => {
    setSelectedOption(selection);
    onChange(selection.value);
  };

  return (
    <Select
      value={selectedOption}
      options={options}
      isDisabled={disabled}
      onChange={handleChange}
      styles={customStyles}
      {...reactSelectProps}
    />
  );
};

export default SingleSelect;
