import React, { useMemo, useCallback } from 'react';
import cx from 'classnames';
import { useTable } from 'react-table/src/hooks/useTable';
import { DefaultTblStyle } from '../common/tables/styles';
import PALETTE from '../palette';
import { deltaBetween } from '../helpers';
import { isCommonizedGroupId, stripCommonizedGroupId } from './helpers';

const generateTblColumns = (deleteAgentRow) => [
  {
    Header: 'Agent',
    accessor: 'agentName',
  },
  {
    Header: 'Today',
    id: 'day',
    Cell: ({ row: { original } }) => {
      return scoreCell(original.day[1], original.day[0]);
    },
  },
  {
    Header: 'This week',
    id: 'week',
    Cell: ({ row: { original } }) => {
      return scoreCell(original.week[1], original.week[0]);
    },
  },
  {
    Header: 'This month',
    id: 'month',
    Cell: ({ row: { original } }) => {
      return scoreCell(original.month[1], original.month[0]);
    },
  },
  // {
  //   Header: 'This year',
  //   id: 'year',
  //   Cell: ({ row: { original } }) => {
  //     return scoreCell(original.year[1], original.year[0]);
  //   },
  // },
  {
    Header: '',
    id: 'actions',
    Cell: ({ row: { original } }) => {
      if (original.agentId === 'all') return null;

      return (
        <a className="pointer" onClick={() => deleteAgentRow(original.agentId)}>
          <span className="fa fa-trash" />
        </a>
      );
    },
  }
];

const scoreCell = (score, pastScore) => {
  let content = [];
  let scoreColor = PALETTE.NEUTRAL['900'];

  if (score === null || score === undefined) {
    return <span key="score" style={{ color: scoreColor }}>N/A</span>;
  }

  if (score >= 75) {
    scoreColor = PALETTE.GREEN.light;
  } else if (score < 30) {
    scoreColor = PALETTE.RED.light;
  }
  content.push(
    <span key="score" style={{ color: scoreColor }}>{score}</span>
  );

  if (pastScore === null || pastScore === undefined) return content;

  const delta = deltaBetween(score, pastScore);
  let deltaColor = PALETTE.NEUTRAL['900'];
  let deltaContent;
  if (delta === 0) {
    deltaContent = '0%';
  } else if (delta > 0) {
    deltaColor = PALETTE.GREEN.light;
    deltaContent = `↑ ${delta}%`;
  } else {
    deltaColor = PALETTE.RED.light;
    deltaContent = `↓ ${Math.abs(delta)}%`;
  }
  content.push(
    <small key="delta" style={{ color: deltaColor }}>&nbsp;&nbsp;{deltaContent}</small>
  );

  return content;
};

const RecentTrendsTable = ({
  data,
  agents,
  groups,
  visualizedAgents,
  onRemoveVis,
}) => {
  const trendRows = useMemo(() => {
    const rows = [
      {
        agentId: 'all',
        agentName: 'All',
        ...data['all'].recents
      },
    ];
    for (let agentId of visualizedAgents) {
      if (!data[agentId]) continue;

      rows.push(
        {
          agentId,
          agentName: isCommonizedGroupId(agentId) ?
            <span><i className="fa fa-users" />&nbsp;{groups[stripCommonizedGroupId(agentId)]}</span> :
            agents[agentId],
          ...data[agentId].recents
        }
      );
    }

    return rows;
  }, [data, agents, groups, visualizedAgents]);

  const handleDeleteRow = useCallback((agentId) => {
    onRemoveVis([agentId]);
  }, [onRemoveVis]);

  const trendColumns = useMemo(() => {
    return generateTblColumns(handleDeleteRow);
  }, [handleDeleteRow]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: trendColumns, data: trendRows });

  return (
    <DefaultTblStyle noTitle>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
  );
};

export default RecentTrendsTable;
