import React from 'react';
import styled from 'styled-components';
import TotalCallsWidget from '../Dashboard/widgets/TotalCallsWidget';
import ActiveAgentsWidget from '../Dashboard/widgets/ActiveAgentsWidget';
import AhtWidget from '../Dashboard/widgets/AhtWidget';

const METRICS = ['total_calls', 'active_agents', 'aht'];

const WidgetComponents = {
  total_calls: TotalCallsWidget,
  active_agents: ActiveAgentsWidget,
  aht: AhtWidget,
};

const Panel = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: calc((100% - 120px) / 5);

    h5 {
      margin-bottom: 24px;
    }

    /* GoalAndScoreLabel component */
    .score-board {
      display: block;

      > div:first-child {
        margin-right: 0px;
      }
    }
  }
`;

const AnalyticsScoreWidgets = ({
  metrics
}) => {
  return (
      <Panel className="mt-4">
        {METRICS.map(metricKey => {
          const WidgetComp = WidgetComponents[metricKey];
          const metric = metrics[metricKey];

          return (
            <div key={metricKey} className="mb-4">
              <WidgetComp metric={metric} />
            </div>
          );
        })}
      </Panel>
  )
};

export default AnalyticsScoreWidgets;
