import React, { useState, useEffect } from 'react';
import MultiSelect from '../common/form/MultiSelect';
import AgentsPicker from '../ScoredCallsAnalytics/AgentsPicker';

const AgentFilter = ({users, groups}) => {
  const [userIds, setUserIds] = useState(getUserIdsFromUrl);

  function getUserIdsFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const userIds = urlParams.getAll('users[]');
    return userIds.length > 0 ? userIds : [];
  }

  const handleSelection = userIds => {
    setUserIds(userIds)

    const urlParams = new URLSearchParams(window.location.search);

    urlParams.delete('users[]')

    userIds.forEach(id => {
      urlParams.append('users[]', id)
    })

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`
    window.location.href = newUrl
  };

  return (
    <div className="mb-4">
      <AgentsPicker
        groups={groups}
        agents={users}
        onSelect={handleSelection}
        initialValue={userIds}
      />      
    </div>
  );
}

export default AgentFilter;
