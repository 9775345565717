import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { PaginatedTableContainer } from './styles';
import RecordingsTable from './RecordingsTable';
import LoadingIndicator from '../common/LoadingIndicator';
import { setupAxiosCSRF } from '../helpers';

const Main = ({
  isClient,
  isAdmin,
  useCampaign,
  data: initialData,
}) => {
  const filtersListener = useRef(null);
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(0);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    filtersListener.current = window.addEventListener('reports.filters', event => {
      const { detail: filters } = event;
      const newParams = {
        ...params,
        "filters[call_type]": filters['call_type'],
        "filters[call_map]": filters['call_map'],
        "filters[date_range]": filters['date_range'],
        "filters[users]": filters['users'],
        "filters[agent_prompt_labels]": filters['agent_prompt_labels'],
        "filters[customer_prompt_labels]": filters['customer_prompt_labels'],
        "filters[violation_labels]": filters['violation_labels'],
        "filters[outcome]": filters['outcome'],
        "filters[termination_type]": filters['termination_type'],
        "filters[disposition]": filters['disposition'],
        "filters[dialer_disposition]": filters['dialer_disposition'],
        "filters[keyword]": filters['keyword'],
        "filters[text_match]": filters['text_match'],
        "filters[meta_keyword]": filters['meta_keyword'],
        "filters[benchmark_ceil]": filters['benchmark_ceil'],
        "filters[completion_floor]": filters['completion_floor'],
        "filters[manager]": filters['manager'],
        "filters[campaign]": filters['campaign'],
        "filters[customer]": filters['customer'],
        "filters[contact]": filters['contact'],
        "filters[dialer_id]": filters['dialer_id'],
        "filters[phrase]": filters['phrase'],
        "filters[scored]": filters['scored'],
        "filters[dnis]": filters['dnis'],
        "filters[compliance_violations_range]": filters['compliance_violations_range'],
        "filters[completion_range]": filters['completion_range'],
        "filters[monologue_range]": filters['monologue_range'],
        "filters[questions_range]": filters['questions_range'],
        "filters[talk_ratio_range]": filters['talk_ratio_range'],
        "filters[objections_range]": filters['objections_range'],
        "filters[duration_range]": filters['duration_range'],
        "filters[score_range]": filters['score_range'],
        "filters[account]": filters['account'],
        page: 1
      };

      setParams(newParams);
      setLoading(true);

      axios.get('/reports/recordings', {
        params: newParams,
      }).then(res => {
        setData(res.data);
        setPage(0);
      }).finally(() => {
        setLoading(false);
      });
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('reports.filters', filtersListener.current);
      }
    };
  }, []);

  const handlePageClick = ({ selected: newPage }) => {
    if (page === newPage) return;

    const newParams = {
      ...params,
      page: newPage + 1,
    };

    setParams(newParams);
    setPage(newPage);

    axios.get('/reports/recordings', {
      params: newParams,
    }).then(res => {
      setData(res.data);
    });
  };

  return (
    <main>
      <PaginatedTableContainer style={{ opacity: loading ? 0.5 : 1 }}>
        <LoadingIndicator visible={loading} />
        <RecordingsTable
          total={data.pagination.total_entries}
          recordings={data.recordings}
          hideScore={isClient}
          useCampaign={useCampaign}
          isAdmin={isAdmin}
        />
        <ReactPaginate
          breakLabel="..."
          nextLabel="&rsaquo;"
          previousLabel="&lsaquo;"
          forcePage={page}
          pageCount={data.pagination.total}
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
          className="paginator"
          activeClassName="active-page"
          pageRangeDisplayed={15}
        />
      </PaginatedTableContainer>
    </main>
  );
};

export default Main;
