import React, { useMemo } from 'react';
import { commonizeGroupId } from './helpers';
import { InputLabelBig } from '../common/styles';
import MultiSelect from '../common/form/MultiSelect';

const ALL = 'All';

const AgentsPicker = ({
  groups,
  agents,
  onSelect,
  initialValue
}) => {
  const selectOptions = useMemo(() => {
    const groupOptions = groups.map(group => {
      return {
        value: commonizeGroupId(group[0]),
        label: <span><i className="fa fa-users"></i> {group[1]}</span>,
      };
    });

    return [
      { label: ALL, value: ALL },
      ...groupOptions,
      ...agents.map(usr => ({ value: usr[0], label: usr[1] }))
    ];
  }, [agents, groups]);

  return (
    <div>
      <InputLabelBig>Agents</InputLabelBig>
      <MultiSelect
        initialValue={initialValue}
        options={selectOptions}
        onChange={onSelect}
        placeholder="Select agents and groups"
        menuPortalTarget={document.body}
        classNamePrefix="scored-calls-select"
      />
    </div>
  );
};

export default AgentsPicker;
