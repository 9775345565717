import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { VictoryChart } from 'victory-chart';
import { VictoryAxis } from 'victory-axis';
import { VictoryBar } from 'victory-bar';
import { VictoryTooltip } from 'victory-tooltip';
import { VictoryLabel } from 'victory-core';
import compact from 'lodash/compact';
import styled from 'styled-components';
import moment from 'moment';
import PALETTE from '../palette';
import { beautifyDuration } from '../helpers';
import {
  TYPE_NUM_CALLS,
  TYPE_CALL_LENGTH,
  TYPE_VIOLATIONS,
  TYPE_QUESTIONS,
  TYPE_TALK_LISTEN,
  TYPE_SCORE
} from './constants';

const ChartContainer = styled.div`
  // max-height: 300px;
  // height: 300px;
`;

const transformData = (period, index) => {
  const startDate = moment(period.start_date);
  const endDate = moment(period.end_date);
  const value = period.value;

  return {
    x: index + 1,
    y: value,
    ts: startDate,
    tsEnd: endDate,
  };
};

const chartStyle = {
  data: {
    fill: PALETTE.BRAND.light,
  },
};
const axisStyle = {
  tickLabels: {fontSize: 8, padding: 2}
};
const tooltipTextStyle = {
  fill: '#000000',
  fontSize: 8
};
const tooltipBubbleStyle = {
  fill: '#FFFFFF',
  stroke: PALETTE.BRAND.light,
  strokeWidth: 1
};

const TrendChart = ({
  data,
  interval,
  type,
}) => {
  const [chartData, setChartData] = useState(null);
  const [allZeros, setAllZeros] = useState(true);
  const [smallYRange, setSmallYRange] = useState(false);

  useEffect(() => {
    if (data) {
      const cData = data.map(transformData);
      setChartData(cData);
      setAllZeros(!compact(cData.map(d => d.y)).length);
      setSmallYRange(cData.filter(d => d.y < 5).length === cData.length);
    } else {
      setChartData(null);
      setAllZeros(true);
      setSmallYRange(false);
    }
  }, [data]);

  const getXTickText = useCallback((_, dIndex) => {
    const tickData = chartData[dIndex];
    const { ts: date, tsEnd: endDate } = tickData;

    if (interval === 'month') {
      return date.format('MMM YYYY');
    } else if (interval === 'day') {
      return date.format('ddd, Do');
    } else if (interval === 'week') {
      return date.isSame(endDate) ? date.format('MMM D') : `${date.format('MMM D')} - ${endDate.format('MMM D')}`;
    } else {
      return date.format('YYYY-MM-DD');
    }
  }, [chartData]);

  const getTooltipText = useCallback(({ datum }) => {
    const { _x, y } = datum;
    if (!y) return null;

    const displayValue = Math.round(y);
    if (type === TYPE_TALK_LISTEN) {
      return `${displayValue}%`;
    } else if (type === TYPE_CALL_LENGTH) {
      return beautifyDuration(displayValue);
    }

    return displayValue;
  }, [type]);

  if (!chartData) return null;

  return (
    <ChartContainer>
      <VictoryChart
        width={600}
        height={300}
        padding={{ top: 10, bottom: 20, left: 20, right: 20 }}
        domainPadding={{ x: 30, y: [0, 20] }}
        domain={allZeros ? { y: [0, 100] } : {}}
      >
        <VictoryAxis
          tickValues={chartData.map(d => d.x)}
          tickFormat={getXTickText}
          style={axisStyle}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(t) => Math.round(t)}
          domain={smallYRange ? [0, 5] : []}
          style={axisStyle}
        />
        <VictoryBar
          style={chartStyle}
          data={chartData}
          animate={{
            duration: 200
          }}
          labels={getTooltipText}
          labelComponent={
            <VictoryLabel
              dy={-5}
              style={tooltipTextStyle}
            />
          }
          // labelComponent={
          //   <VictoryTooltip
          //     style={tooltipTextStyle}
          //     flyoutStyle={tooltipBubbleStyle}
          //     pointerWidth={5}
          //     pointerLength={3}
          //     dy={-3}
          //   />
          // }
        />
      </VictoryChart>
    </ChartContainer>
  );
};

TrendChart.propTypes = {
  data: PropTypes.array,
  interval: PropTypes.oneOf(['day', 'week', 'month', 'year']),
  type: PropTypes.oneOf([TYPE_NUM_CALLS,
                          TYPE_CALL_LENGTH,
                          TYPE_VIOLATIONS,
                          TYPE_QUESTIONS,
                          TYPE_TALK_LISTEN,
                          TYPE_SCORE])
};

export default TrendChart;
