import React, { useEffect, useState, useRef, useCallback } from 'react';
import cx from 'classnames';
import LeftSlideModaless from '../common/popovers/LeftSlideModaless';
import { scoreColor, getAsteriskNotation } from '../helpers';
import ScoringPanel from './scoring/ScoringPanel';

const ScoringButton = ({
  data,
  readOnly: initialReadOnly,
  allowAutoScore
}) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(initialReadOnly);
  const [results, setResults] = useState(data.results || {});
  const [isAutoScoring, setIsAutoScoring] = useState(false);
  const [autoScoreError, setAutoScoreError] = useState(null);
  const panelRef = useRef(null);

  useEffect(() => {
    const appElem = document.getElementById('beta');

    if (panelOpen) {
      appElem.classList.add('shrinked');
    } else {
      appElem.classList.remove('shrinked');
    }
  }, [panelOpen]);

  useEffect(() => {
    if (isAutoScoring) {
      console.log('Starting polling due to isAutoScoring state change');

      pollAutoScoreStatus();
    }
  }, [isAutoScoring, pollAutoScoreStatus]);

  const handlePanelClose = useCallback(() => {
    if (!panelRef.current || !panelRef.current.hasUnsavedScores() || confirm("The score is not saved, continue?")) {
      setPanelOpen(false);
    }
  }, [setPanelOpen]);

  const pollAutoScoreStatus = useCallback(async () => {
    try {
      console.log('Polling status...');
      const statusUrl = `/calls/${data.callId}/autoscore_status`;
      console.log('Polling URL:', statusUrl);

      const response = await fetch(statusUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
      });

      console.log('Status response:', response);
      const result = await response.json();
      console.log('Status result:', result);

      if (result.complete) {
        console.log('Complete - checking for results');
        if (result.results) {
          console.log('Updating results:', result.results);
          setResults(result.results);
        } else {
          console.log('No results data in response');
        }
        setIsAutoScoring(false);
      } else if (result.failed) {
        console.log('Failed');
        setAutoScoreError("Auto-scoring failed");
        setIsAutoScoring(false);
      } else if (result.processing) {
        console.log('Still processing - polling again');
        setTimeout(() => pollAutoScoreStatus(), 2000);
      } else {
        // Handle pending/initial state
        console.log('Status pending or initializing - polling again');
        setTimeout(() => pollAutoScoreStatus(), 2000);
      }
    } catch (error) {
      console.error('Error polling autoscore status:', error);
      setAutoScoreError("Error checking auto-score status");
      setIsAutoScoring(false);
    }
  }, [data.callId]);

  const handleAutoScore = async () => {
    try {
      console.log('Starting auto-score');
      setIsAutoScoring(true);  // This will trigger the polling via useEffect
      setAutoScoreError(null);

      const response = await fetch(`/calls/${data.callId}/autoscore`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to start auto-scoring');
      }
    } catch (error) {
      console.error('Error starting auto-score:', error);
      setAutoScoreError("Failed to start auto-scoring");
      setIsAutoScoring(false);
    }
  };

  return (
    <div>
      {(data.score_visible || data.isAdmin) && (
        <>
          <button
            className={cx("btn btn-primary", { 'd-none': panelOpen })}
            onClick={() => setPanelOpen(true)}
            disabled={isAutoScoring}>
            {isAutoScoring ? (
              <>
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                Auto-scoring...
              </>
            ) : (
              <>
                {data.scorecards.length > 1 ? 'Average score' : 'Score'}
                {results?.total != null && (
                  <small
                    className="ml-2 badge badge-white"
                    style={{ color: scoreColor(results.total) }}>
                    {results.total}
                    {getAsteriskNotation(data.scorecards || [], results.scorecards)}
                  </small>
                )}
              </>
            )}
          </button>
          {autoScoreError && (
            <div className="alert alert-danger mt-2">
              {autoScoreError}
            </div>
          )}
        </>
      )}
      <LeftSlideModaless
        width="460px"
        open={panelOpen}
        onClose={handlePanelClose}
      >
        <ScoringPanel
          ref={panelRef}
          callId={data.callId}
          scores={data.scores}
          notes={data.notes}
          scorecards={data.scorecards || []}
          scorer={data.scorer}
          scoredAt={data.scoredAt}
          lastScorer={data.lastScorer}
          lastScoredAt={data.lastScoredAt}
          results={results}
          readOnly={readOnly}
          allowAutoScore={allowAutoScore}
          onResultsChange={setResults}
          onFinalize={() => setReadOnly(true)}
          isAdmin={data.isAdmin}
          onAutoScore={handleAutoScore}
          isAutoScoring={isAutoScoring}
        />
      </LeftSlideModaless>
    </div>
  );
};

export default ScoringButton;
