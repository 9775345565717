import moment from 'moment';
import orderBy from 'lodash/orderBy';

const PROMPT_TOP_MARGING = 120;

export const isInViewport = (el, heightOffset = 0) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) - heightOffset) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const toastPrompt = (el, alwaysOnTop = false) => {
  const width = el.outerWidth();
  const height = el.outerHeight();
  const top = addToastPrompt(el.attr('id'), height, alwaysOnTop);

  el.css('width', width);
  el.css('position', 'fixed');
  el.css('top', top);
  el.css('z-index', 99);
  el.css('box-shadow', '3px 3px 5px 0px rgba(0,0,0,0.1)');
  el.css('transition', 'top 0.5s');
  if (window.toastPrompts.length > 1) {
    rearrangeToastPrompts();
  }
};

export const detoastPrompt = (el) => {
  el.css('width', 'auto');
  el.css('position', 'relative');
  el.css('top', 'auto');
  el.css('z-index', 'auto');
  el.css('box-shadow', 'none');

  removeToastPrompt(el.attr('id'));
};

const addToastPrompt = (elemId, height, alwaysOnTop = false) => {
  window.toastPrompts ||= [];

  window.toastPrompts.unshift({ id: elemId, height, fixedTop: alwaysOnTop });
  window.toastPrompts = orderBy(window.toastPrompts, ['fixedTop'], ['desc']);

  return PROMPT_TOP_MARGING;
};

const removeToastPrompt = (elemId) => {
  window.toastPrompts ||= [];

  const toastedIndex = window.toastPrompts.findIndex((tp) => tp.id === elemId);
  if (toastedIndex > -1) {
    window.toastPrompts.splice(toastedIndex, 1);
    rearrangeToastPrompts();
  }
};

const getToastPromptTop = (elemId) => {
  // let preceedingHeightSum = $('.fixed-top').outerHeight() + 5;
  let preceedingHeightSum = PROMPT_TOP_MARGING;

  for (let toastPrompt of window.toastPrompts) {
    if (toastPrompt.id === elemId) break;
    preceedingHeightSum += (toastPrompt.height + 10);
  }

  return preceedingHeightSum;
};

const rearrangeToastPrompts = () => {
  for (let toastPrompt of window.toastPrompts) {
    $(`#${toastPrompt.id}`).css('top', getToastPromptTop(toastPrompt.id));
  }
};

export const toHHMMSS = (value) => {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  // if (hours   < 10) {hours   = "0"+hours;}
  // if (minutes < 10) {minutes = "0"+minutes;}
  // if (seconds < 10) {seconds = "0"+seconds;}

  let string = `${seconds}s`
  if (minutes > 0) string = `${minutes}m ${string}`
  if (hours > 0) string = `${hours}h ${string}`

  return string
}

export const toAudioPlayerDurationFormat = (value) => {
  const secs = parseInt(value, 10);

  return moment().startOf('day')
        .seconds(secs)
        .format('HH:mm:ss');
};

export const downloadFile = async (blob, fileName) => {
  try {
    const url = URL.createObjectURL(blob);
    const aTag = document.createElement('a');
    aTag.style.display = 'none';
    aTag.href = url;
    aTag.download = fileName;
    document.body.appendChild(aTag);
    aTag.click();
    window.URL.revokeObjectURL(url);
    aTag.remove();
  } catch (err) {
    console.error("An error occurred during download:", err);
    alert("An error occurred during file download. Please try again.");
  }
};

export const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, "text/html")
  return doc.documentElement.textContent
}

export const setJsonInputDataProp = (inputId, propKey, propValue) => {
  const elem = $(`#${inputId}`);
  let data = {}
  try {
    data = JSON.parse(elem.val() || '{}');
  } catch(error) {
    console.error(error, elem.val())
  }

  data[propKey] = propValue;

  elem.val(JSON.stringify(data, undefined, 4));
}

export const tryNonFuseMatch = (exact, message, trigger) => {
  // If not an exact match request, let Fuse handle it
  if (!exact) {
    return {
      eligible: false,
      matched: false
    }
  }

  // Clean the strings consistently
  const cleanMessage = message.toLowerCase().trim();
  const cleanTrigger = trigger.toLowerCase().trim();

  // Single word exact match
  if (isWord(trigger)) {
    return {
      eligible: true,
      matched: pureWordsInSentence(message).includes(cleanTrigger)
    }
  }

  // For an exact phrase match, we want:
  // 1. The words must appear consecutively in this exact order
  // 2. Each word must have word boundaries
  const words = cleanTrigger.split(/\s+/);
  const escapeRegex = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const pattern = words.map(word => `\\b${escapeRegex(word)}\\b`).join('\\s+');

  return {
    eligible: true,
    matched: new RegExp(pattern, 'i').test(cleanMessage)
  }
}

export const isWord = str => {
  if (!str) return false;
  const cleaned = str.trim();
  return cleaned.length > 0 && !cleaned.includes(' ');
}

export const pureWordsInSentence = sentence => {
  if (!sentence) return [];
  return sentence
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')  // Remove all non-word chars except hyphens
    .trim()
    .split(/\s+/)  // Split on any whitespace
    .filter(word => word.length > 0);  // Remove empty strings
}

export const scrubSensitiveInfo = sentence => {
  if (!sentence) return sentence;
  if (justNumerals(sentence)) return '***';

  // See https://medium.com/@shemar.gordon32/how-to-split-and-keep-the-delimiter-s-d433fb697c65
  // also can search for "regex lookaround"

  const tokens = sentence.split(/(?=[^a-zA-Z0-9'])|(?<=[^a-zA-Z0-9'])/g)

  // this string "one, two. three? four won't $20 LA L-A" is split into
  // ['one', ',', ' ', 'two', '.', ' ', 'three', '?', ' ', 'four', ' ', "won't", ' ', '$', '20', ' ', 'LA', ' ', 'L', '-', 'A']

  let modifiedTokens = [];
  let lastNumericToken = null;
  let consecutiveNumeric = false;
  let delimitersHolder = [];
  let lastTokenWasDelimiter = false;
  tokens.forEach(token => {
    if (startsWithNonDelimiter(token)) {
      lastTokenWasDelimiter = false;
      // if a token starts with a non-delimiter, then it can be a number
      if (isNumeric(token)) {
        if (lastNumericToken) {
          consecutiveNumeric = true;
        } else {
          lastNumericToken = token;
          consecutiveNumeric = false;
        }
      } else {
        if (lastNumericToken) {
          if (token === 'the' || token === 'of') {
            delimitersHolder.push(token);
            return;
          }
          if (consecutiveNumeric) {
            modifiedTokens.push('***');
            if (delimitersHolder.length > 0) {
              modifiedTokens.push(delimitersHolder[delimitersHolder.length - 1]);
            }
          } else {
            modifiedTokens.push(lastNumericToken);
            if (delimitersHolder.length > 0) {
              modifiedTokens = modifiedTokens.concat(delimitersHolder)
            }
          }
        }
        modifiedTokens.push(token);
        lastNumericToken = null;
        // this is correct for sure, but not needed really, because lastNumericToken is set to null
        consecutiveNumeric = false;
        delimitersHolder = [];
      }
    } else {
      if (lastNumericToken) {
        if (lastTokenWasDelimiter && delimitersHolder.length > 0) {
          // this is a bit much, done so we can preserve all punctuations after consecutive numbers
          delimitersHolder[delimitersHolder.length - 1] = delimitersHolder[delimitersHolder.length - 1] + token;
        } else {
          delimitersHolder.push(token);
        }
      } else {
        modifiedTokens.push(token);
      }
      lastTokenWasDelimiter = true;
    }
  });

  // clean up, in case a single number is stranded at the end, put it back
  if (lastNumericToken) {
    if (consecutiveNumeric) {
      modifiedTokens.push('***');
      if (lastTokenWasDelimiter && delimitersHolder.length > 0) {
        modifiedTokens.push(delimitersHolder[delimitersHolder.length - 1]);
      }
    } else {
      modifiedTokens.push(lastNumericToken);
      if (delimitersHolder.length > 0) {
        modifiedTokens = modifiedTokens.concat(delimitersHolder)
      }
    }
  }

  return modifiedTokens.join('');
};

const justNumerals = token => {
  return token.match(/^[0-9][0-9]+\.$/) ||
    token.match(/^[0-9][0-9]+\,$/) ||
    token.match(/^[0-9][0-9]+$/);
};

const isNumeric = token => {
  token = token.toLowerCase();
  return token === 'oh' || token === 'zero' ||
    token === 'one' ||
    token === 'two' ||
    token === 'three' ||
    token === 'four' ||
    token === 'five' ||
    token === 'six' ||
    token === 'seven' ||
    token === 'eight' ||
    token === 'nine' ||
    token === 'ten' ||
    token === 'eleven' ||
    token === 'twelve' ||
    token === 'january' ||
    token === 'february' ||
    token === 'march' ||
    token === 'april' ||
    token === 'may' ||
    token === 'june' ||
    token === 'july' ||
    token === 'august' ||
    token === 'september' ||
    token === 'october' ||
    token === 'november' ||
    token === 'december' ||
    token.match(/^[0-9]+$/) ||
    token.match(/^[0-9]+st$/) ||
    token.match(/^[0-9]+nd$/) ||
    token.match(/^[0-9]+rd$/) ||
    token.match(/^[0-9]+th$/);
};

const zero = '0'.charCodeAt(0);
const nine = '9'.charCodeAt(0);
const lowerA = 'a'.charCodeAt(0);
const lowerZ = 'z'.charCodeAt(0);
const upperA = 'A'.charCodeAt(0);
const upperZ = 'Z'.charCodeAt(0);
const singleQuote = "'".charCodeAt(0);

const startsWithNonDelimiter = token => {
  if (!token) return false;

  const firstChar = token.charCodeAt(0);
  return firstChar >= zero && firstChar <= nine ||
    firstChar >= lowerA && firstChar <= lowerZ ||
    firstChar >= upperA && firstChar <= upperZ ||
    firstChar == singleQuote;
};

const CONTINUOUS_OVERLAP_GAP = 1;

const before = (a, b) => {
  return b.start_ts >= a.end_ts;
}

const intersection = (a, b) => {
  const start_ts = Math.max(a.start_ts, b.start_ts);
  const end_ts = Math.min(a.end_ts, b.end_ts);
  return { start_ts, end_ts };
}

const tooShort = (interval, threshold) => {
  return interval.end_ts - interval.start_ts < threshold;
}

export const overlapsDetected = (agentTalkStartStops, customerTalkStartStops, currentTime, numberOfSecondsElapsed,
  threshold, shortUtteranceThreshold) => {
  if (agentTalkStartStops.length === 0 || customerTalkStartStops.length === 0 || currentTime < threshold) return [];

  const overlaps = [];

  for (let i = agentTalkStartStops.length - 1; i >= 0; i--) {
    const agent = agentTalkStartStops[i];

    if (agent.end_ts < currentTime - numberOfSecondsElapsed) {
      break;
    }
    if (tooShort(agent, shortUtteranceThreshold)) continue;

    for (let j = customerTalkStartStops.length - 1; j >= 0; j--) {
      const customer = customerTalkStartStops[j];

      if (customer.end_ts < currentTime - numberOfSecondsElapsed) {
        break;
      }

      if (tooShort(customer, shortUtteranceThreshold)) continue;
      if (before(customer, agent)) break;
      if (before(agent, customer)) continue;

      const overlap = intersection(agent, customer);
      overlaps.push(overlap);
    }
  }

  return overlaps;
}

export const overlapsWithContinuousGapDetected = (overlaps) => {
  if (overlaps.length === 0) return [];

  const lumpedOverlaps = [];
  let overlapStart = null;
  let overlapEnd = null;

  overlaps.reverse().forEach(overlap => {
    if (overlapEnd !== null && overlap.start_ts > overlapEnd + CONTINUOUS_OVERLAP_GAP) {
      lumpedOverlaps.push({ start_ts: overlapStart, end_ts: overlapEnd });
      overlapStart = null;
      overlapEnd = null;
    }

    if (overlapStart === null) {
      overlapStart = overlap.start_ts;
    }

    overlapEnd = overlap.end_ts;
  });

  if (overlapStart !== null && overlapEnd !== null) {
    lumpedOverlaps.push({ start_ts: overlapStart, end_ts: overlapEnd });
  }

  return lumpedOverlaps;
}

export const overlapsAboveThresholdDetected = (overlaps, threshold) => {
  if (overlaps.length === 0) return [];

  return overlaps
    .filter(o => o.end_ts - o.start_ts >= threshold)
    .map(o => ({ start: o.start_ts, duration: Math.floor(o.end_ts - o.start_ts) }));
}

export const detectOverlaps = (agentTalkStartStops, customerTalkStartStops, currentTime, numberOfSecondsElapsed,
  threshold, shortUtteranceThreshold) => {
  if (agentTalkStartStops.length === 0 && customerTalkStartStops.length === 0 && currentTime === 0) return [];

  const overlaps = overlapsDetected(agentTalkStartStops, customerTalkStartStops, currentTime, numberOfSecondsElapsed,
    threshold, shortUtteranceThreshold);
  const lumpedOverlaps = overlapsWithContinuousGapDetected(overlaps);
  const overlapsAboveThreshold = overlapsAboveThresholdDetected(lumpedOverlaps, threshold);

  return overlapsAboveThreshold;
}
