import React, { useState } from 'react';
import Slider from 'rc-slider';
import compact from 'lodash/compact';
import { defaultStyles, Wrapper, Label } from './SliderSelect';

const SliderRange = ({
  initialValue,
  min,
  max,
  step,
  unit = '',
  styles = defaultStyles,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue || [min, max]);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  return (
    <Wrapper>
      <Label left>
        {compact([`${value[0]}`, unit]).join(' ')}
      </Label>
      <Slider
        min={min}
        max={max}
        defaultValue={initialValue}
        step={step}
        range
        {...styles}
        onChange={handleChange}
      />
      <Label>
        {compact([value[1], unit]).join(' ')}
      </Label>
    </Wrapper>
  );
};

export default SliderRange;
