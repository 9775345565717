$(document).on('DOMContentLoaded', function() {
  const togglePassword = document.querySelector(".toggle-password");
  const passwordInput = document.querySelector(".password-input");
  const toggleCurrentPassword = document.getElementById("toggle-current-password");
  const currentPasswordInput = document.getElementById("password-current-input");

  if (toggleCurrentPassword) {
    toggleCurrentPassword.addEventListener("click", function() {
      if (currentPasswordInput.type === "password") {
        currentPasswordInput.type = "text";
        toggleCurrentPassword.innerHTML = '<i class="fas fa-eye-slash fa-lg"></i>';
      } else {
        currentPasswordInput.type = "password";
        toggleCurrentPassword.innerHTML = '<i class="fas fa-eye fa-lg"></i>';
      }
      });
  }

  if (togglePassword) {
    togglePassword.addEventListener("click", function() {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        togglePassword.innerHTML = '<i class="fas fa-eye-slash fa-lg"></i>';
      } else {
        passwordInput.type = "password";
        togglePassword.innerHTML = '<i class="fas fa-eye fa-lg"></i>';
      }
    });
  }
});
