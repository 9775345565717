import styled from 'styled-components';

export const PaginatedTableContainer = styled.div`
  position: relative;

  .paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-top: 24px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      a { color: #FF5D39 !important; }

      &.active-page {
        background-color: #FF5D39;
        border-radius: 50%;
        a { color: #FFFFFF !important; }
      }

      &.disabled-page {
        visibility: hidden;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;
