import React from 'react';
import styled from 'styled-components';

const TotalRecords = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #FF5D39;
  text-align: center;
  margin-top: 12px; 
`;

const TotalRecordsDisplay = ({ total, startOffset, endOffset }) => (
  <TotalRecords>
    Showing {startOffset}-{Math.min(endOffset, total)} of {total.toLocaleString()}
  </TotalRecords>
);

export default TotalRecordsDisplay;
