// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import JSConfetti from "js-confetti"
import LocalTime from "local-time"
import "./set_timezone";

LocalTime.start()

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("trix")
// require("@rails/actiontext")
// require("channels")
require("bootstrap")
require("@fortawesome/fontawesome-free/js/all")
require("bootstrap-daterangepicker")
// require("local-time").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

global.Rails = Rails
global.$ = jQuery

$(document).on('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip();

  setTimeout(function () {
    $(".alert:not(.alert-persist)").alert('close')
  }, 12000)

  $('.trix-content:not(.form-control) div')
    .map((i, el) => el.childNodes)
    .each((i, nodeList) => {
      if (nodeList[0] && nodeList[1] && nodeList[0].nodeValue === "\n" && nodeList[1].localName === "br") {
        nodeList[1].remove()
      }
    })
})

$(document).on('load', function () {
  $('a.disabled').on('click', function (e) {
    e.preventDefault();
  });
})
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(document).on('turbolinks:load', function () {
  const celebrate = document.getElementById("prompt-invisible-celebrations")
  var jsConfetti = new JSConfetti({ celebrate })
  global.jsConfetti = jsConfetti
})
