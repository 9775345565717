import React from 'react';
import styled from 'styled-components';
import { WidgetStyle } from '../../../common/styles';

const Value = styled.div`
  text-align: center;
  font-size: 56px;
  font-weight: 600;
  color: #000215;
  padding-top: 16px;
`;

const SimpleScoreWidget = ({
  title,
  value,
}) => {
  return (
    <WidgetStyle>
      <h5>{title}</h5>
      <Value>{value}</Value>
    </WidgetStyle>
  )
};

export default SimpleScoreWidget;
