import React, {useState, useEffect} from "react";
import axios from 'axios';
import { setupAxiosCSRF } from "../helpers";

const GenerateButton = ({ recordingId, english }) => {
  const [summary, setSummary] = useState(null);
  const [summaryStatus, setSummaryStatus] = useState('unprocessed');
  const [intents, setIntents] = useState(null);
  const [sentiments, setSentiments] = useState(null);
  const [topics, setTopics] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [audioReady, setAudioReady] = useState(false);

  useEffect(() => {
    fetchCallSummary();
  }, []);

  const fetchCallSummary = async () => {
    setupAxiosCSRF();
    try {
      const response = await axios.get(`/calls/${recordingId}/fetch_call_summary`);
      if (response.status === 200) {
        setSummary(response.data.summary);
        setIntents(response.data.intents);
        setSentiments(response.data.sentiments);
        setTopics(response.data.topics);
        setSummaryStatus(response.data.summary_status);
        setAudioReady(response.data.audio_ready);

        response.data.intents.forEach((intent, index) => {
          intentsList.push(<li key={index}>{intent.value}</li>);
        });

        if (response.data.summary) {
          setButtonClicked(false);
        }
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
    } catch (error) {
      console.error("Error fetching summary:", error);
      setButtonClicked(false);
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }

  const handleGenerate = async () => {
    setButtonClicked(true);
    setupAxiosCSRF();
    try {
      const response = await axios.post(`/calls/${recordingId}/generate_call_summary`);
      if (response.status === 200) {
        const id = setInterval(fetchCallSummary, 5000);
        setIntervalId(id);
      }
    } catch (error) {
      console.error("Error generating summary:", error);
      setButtonClicked(false);
    }
  }

  return (
    <div>
      { english && summary ? (
        <div>
          <br/>
          <b>Summary: </b>
          {summary}
          <br/>
          <br/>
        </div>
      ) : (
        <>
        { summaryStatus === 'audio_pending' && <p>Audio processing, please wait...</p>}
        { summaryStatus === 'processing' && <p>Processing, please wait...</p>}
        {(summaryStatus === 'unprocessed' &&  !buttonClicked && audioReady) && (
            <button
              onClick={handleGenerate}
              className="btn btn-primary mt-3"
            >
              Generate
            </button>
          )}
        </>
      )}

      {intents && intents.length > 0 ? (
        <div>
        <b>Intent: </b>
        <ul>
          {intents.map((intent) => (
            <li>{intent.value} ({intent.score})</li>
          ))}
        </ul>
        </div>
      ) : (
        <div></div>
      )}

      {sentiments && sentiments.length > 0 ? (
        <div>
        <b>Sentiment: </b>
        <ul>
          {sentiments.map((sentiment) => (
            <li>{sentiment.value} ({sentiment.score})</li>
          ))}
        </ul>
        </div>
      ) : (
        <div></div>
      )}

      {topics && topics.length > 0 ? (
        <div>
        <b>Topics: </b>
        <ul>
          {topics.map((topic) => (
            <li>{topic.value} ({topic.score})</li>
          ))}
        </ul>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default GenerateButton;
