import React, { useEffect, useRef } from 'react';
import axios from 'axios';

const ExportButton = () => {
  const filtersListener = useRef(null);
  const params = useRef({});

  useEffect(() => {
    filtersListener.current = window.addEventListener('admin_reviews.filters', event => {
      const { detail: filters } = event;
      params.current = {
        ...params.current,
        "filters[date_range]": filters['date_range'],
        "filters[agent]": filters['agent'],
        "filters[reviewer]": filters['reviewer'],
        "filters[forms]": filters['forms'],
        "filters[need_follow_up]": filters['need_follow_up'],
        page: 1
      };
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('admin_reviews.filters', filtersListener.current);
      }
    };
  }, []);

  const handleExport = (e) => {
    e.preventDefault();
    const exportURL = '/admin_reviews/export';

    axios({
      url: exportURL,
      method: 'GET',
      responseType: 'blob',
      params: params.current,
    }).then((response) => {
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'reviews.xlsx';

      if (contentDisposition) {
          const matches = contentDisposition.split(';')
            .map(s => s.trim())
            .filter(s => s.startsWith('filename='))
          if (matches.length > 0) {
            filename = matches[0].replace('filename=', '').trim();
          }
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((error) => {
      console.error("Error exporting data:", error);
    });
  }

  return (
    <a href='#' className='ml-4' onClick={handleExport}><span className="fa fa-download mr-2"></span> Export</a>
  );
}

export default ExportButton;
