import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  font-size: 12px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #FF5D39;
  width: 38px;
  height: 20px;
  border-radius: 8px;
  color: white;
`;

const Dropdown = styled.ul`
  position: absolute;
  z-index: 10;
  overflow: hidden;
  top: 25px;
  left: 0px;
  width: 38px;
  background: white;
  list-style: none;
  padding-left: 0px;
  text-align: center;
  color: #FF5D39;
  border: 1px solid #FF5D39;
  border-radius: 8px;
`;

const DropdownItem = styled.li`
  cursor: pointer;
  color: ${props => props.active ? 'white' : '#FF5D39'};
  background: ${props => props.active ? '#FF5D39' : 'white'};
  padding: 3px 0px;

  &:hover {
    color: white;
    background: #FF5D39;
  }
`;

const SpeedControl = ({
  options = [1, 1.25, 1.5, 2],
  show,
  onChange,
}) => {
  const [speed, setSpeed] = useState(1);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  if (!show) return null;

  const handleSpeedChange = speedOption => {
    setSpeed(speedOption);
    setDropdownOpen(false);
    onChange(speedOption);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Wrapper title="Playback rate">
      <Button onClick={toggleDropdown}>{speed}x</Button>
      {dropdownOpen && (
        <Dropdown>
          {options.map((option, index) => (
            <DropdownItem
              key={`option-${index}`}
              active={option === speed}
              onClick={() => handleSpeedChange(option)}
            >{option}x</DropdownItem>
          ))}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default SpeedControl;
