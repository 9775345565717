import React from 'react';
import { VictoryChart } from 'victory-chart';
import { VictoryAxis } from 'victory-axis';
import { VictoryBar } from 'victory-bar';
import { VictoryTooltip } from 'victory-tooltip';
import { number } from 'prop-types';
import { set } from 'lodash';
import PALETTE from '../palette';


const chartStyle = {
  data: {
    fill: PALETTE.BRAND.light,
  },
};

const getHeight = (data) => {
  if (data.length < 10) {
    return 800;
  } else if (data.length < 50) {
    return 1200;
  } else if (data.length < 100) {
    return 1600;
  } else {
    return 2000;
  }
};


const prepareData = (data) => {
  let setTooltip = false;
  let names = {};
  if (data.hasOwnProperty('number_of_calls')) {
    setTooltip = true;
    for (let item of data.number_of_calls) {
      names[item.name] = item.value;
    }
  }
  const tmp = data.data;
  const result = [];
  for (let i = 0; i < tmp.length; i++) {
    const val = {
      name: tmp[i].name,
      x: i + 1,
      y: Math.round(Number(tmp[i].value) * 100) / 100
    }
    if (setTooltip) {
      val['label'] = `Number of calls: ${names[tmp[i].name]}`
    }
    result.push(val)
  }
  return result;
};

const CallGraph = (data) => {
  const sample = prepareData(data);
  return sample.length > 0 && (
    <div>
      <VictoryChart
        width={800}
        height={getHeight(sample)}
        padding={{ top: 20, bottom: 150, left: 150, right: 150 }}
      >
      <VictoryAxis
        tickValues={sample.map(d => d.name)}
      />
      <VictoryAxis
        dependentAxis
        label={data.label}
      />
      <VictoryBar horizontal
        barRatio={0.2}
        labelComponent={<VictoryTooltip/>}
        data={sample}
        style={chartStyle}
      />
      </VictoryChart>
    </div>
  );
};

export default CallGraph;
