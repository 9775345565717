import React, { useState } from 'react';
import styled from 'styled-components';

const CheckboxRow = styled.div`
  input[type='checkbox'] {
    display: block; // rid of unnecessary bottom space
    width: 18px;
    height: 18px;
  }
`;

const Checkbox = ({
  initialValue,
  disabled = false,
  onChange,
}) => {
  const [checked, setChecked] = useState(initialValue);

  const handleChange = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <CheckboxRow>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
    </CheckboxRow>
  );
};

export default Checkbox;
