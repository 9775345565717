import React from 'react';
import SimpleScoreWidget from './common/SimpleScoreWidget';
import GoalAndScoreWidget from './common/GoalAndScoreWidget';
import GoalAndScoreLabel from './common/GoalAndScoreLabel';

const PALETTE = { score: '#951841', background: '#FFE1EC', target: '#E52E6B' };

const TalkRatioWidget = ({
  metric
}) => {
  const { score, goal } = metric;

  const intScore = Math.round(score);

  if (!goal) {
    return (
      <SimpleScoreWidget
        title="Talk / Listen"
        primaryPercent={score}
        label={`${intScore}%`}
        palette={PALETTE}
      />
    )
  }

  return (
    <GoalAndScoreWidget
      title="Talk / Listen"
      label={`${intScore}%`}
      max={100}
      goal={goal}
      score={intScore}
      palette={PALETTE}
    >
      <GoalAndScoreLabel
        goal={`${goal}%`}
        score={`${intScore}%`}
        palette={PALETTE}
      />
    </GoalAndScoreWidget>
  );
};

export default TalkRatioWidget;
