import React, { useState } from 'react';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import LeftSlidePanel from '../common/popovers/LeftSlidePanel';

const PanelWrapper = styled.div`
  padding: 32px;
`;

const ReviewRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const ReviewLabel = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
  color: black;
  font-size: 16px;
  font-weight: 600;
`;

const ReviewLink = styled.a`
  color: black !important;
`;

const AttachReviewsButton = ({
  data: {
    callId,
    agentId,
    reviews,
    attachedReviewIds: initialSelection,
    completeReviews,
  },
  readOnly,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [selection, setSelection] = useState(new Set(initialSelection));
  const [savedSelection, setSavedSelection] = useState(new Set(initialSelection));
  const [saving, setSaving] = useState(false);

  const handleSelectionChange = (reviewId) => {
    setSelection((currentSelection) => {
      const updatedSelection = new Set(currentSelection);
      if (updatedSelection.has(reviewId)) {
        updatedSelection.delete(reviewId);
      } else {
        updatedSelection.add(reviewId);
      }
      return updatedSelection;
    });
  };

  const submitReviewToggle = (reviewId) => {
    setSaving(true);
    axios.put(`/calls/${callId}/toggle_review`, { review_id: reviewId })
      .catch(err => console.log(err))
      .finally(() => {
        setSaving(false);
      });
  };

  const submitReviews = () => {

    // For each review ID in the current selection...
    for (const reviewId of selection) {
      if (!savedSelection.has(reviewId)) {
        submitReviewToggle(reviewId);
      }
    }

    // For each review ID in the initial selection...
    for (const reviewId of savedSelection) {
      if (!selection.has(reviewId)) {
        submitReviewToggle(reviewId);
      }
    }

    setSavedSelection(selection)
  }

  return (
    <div className="mr-2">
      <button className="btn btn-primary" onClick={() => setPanelOpen(true)}>
        Attach to reviews
      </button>

      <LeftSlidePanel
        width="450px"
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
      >
        <PanelWrapper>
          <h4 style={{ marginBottom: 32 }}>Attach to reviews</h4>
          {!readOnly && (
            <a
              href={`/agent_reviews/${agentId}/new?recording_id=${callId}`}
              className="btn btn-primary btn-block btn-lg mb-5"
              target="_blank"
            >
              Start new review
            </a>
          )}
          {reviews.map(review => (
            <ReviewRow key={review.id} className='ml-2'>
              <input
                type="checkbox"
                checked={selection.has(review.id)}
                onChange={_e => handleSelectionChange(review.id)}
                disabled={readOnly || saving }
                className='form-check-input'
              />
              <ReviewLabel>
                <div>
                  <div>{review.formName}</div>
                  {!!review.subject && <div className="small de-emphasize">{review.subject}</div>}
                </div>
                <div className="ml-1">({review.createdAt})</div>
              </ReviewLabel>
            </ReviewRow>
          ))}

          {!readOnly && (
            <button className="btn btn-primary btn-block btn-lg mt-5" onClick={submitReviews} disabled={saving || isEqual(savedSelection, selection)} hidden={savedSelection.size === 0 && selection.size === 0}>
              Attach to selected reviews
            </button>
          )}

          {!!completeReviews.length && (
            <div>
              <h4 style={{ marginTop: 48, marginBottom: 24 }}>Completed reviews</h4>
              {completeReviews.map(review => (
                <ReviewRow key={review.id}>
                  <ReviewLabel>
                    <ReviewLink href={`/agent_reviews/${review.id}/edit`} target="_blank">{review.formName}</ReviewLink>
                    <span className="ml-1">({review.createdAt})</span>
                  </ReviewLabel>
                </ReviewRow>
              ))}
            </div>
          )}
        </PanelWrapper>
      </LeftSlidePanel>
    </div>
  );
};

export default AttachReviewsButton;
