import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SingleSelect from '../common/form/SingleSelect';
import ScorecardTable from './ScorecardTable';
import FiltersRow from './FiltersRow';
import LoadingIndicator from '../common/LoadingIndicator';
import Benchmarking from './Benchmarking';

const Container = styled.div`
  margin: 24px 0px;
  font-size: 18px;
  color: #000215;
`;

const FilterBlock = styled.div`
  margin-top: 24px;
`;

const FilterContent = styled.div`
  min-width: 200px;
  max-width: 400px;
  padding-right: 32px;
  padding-bottom: 8px;
`;

const ScorecardTableContent = styled.div`
  line-height: 22px;
`;

const getDefaultDateRange = () => {
  const startDate = moment().subtract(6,'d');
  const endDate = moment();

  return `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`;
};

const Main = ({
  isClient,
  showManagerContext,
  data: initialData,
}) => {
  const filtersListener = useRef(null);
  const [data, setData] = useState(initialData);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState(null);

  useEffect(() => {
    filtersListener.current = window.addEventListener('agent_scorecards.filters', event => {
      const { detail: filters } = event;
      const newParams = {
        ...params,
        "filters[call_type]": filters['call_type'],
        "filters[call_map]": filters['call_map'],
        "filters[date_range]": filters['date_range'],
        "filters[prompt_category]": filters['prompt_category'],
        "filters[compliance]": filters['compliance'],
        "filters[outcome]": filters['outcome'],
        "filters[disposition]": filters['disposition'],
        "filters[dialer_disposition]": filters['dialer_disposition'],
        "filters[keyword]": filters['keyword'],
        "filters[text_match]": filters['text_match'],
        "filters[meta_keyword]": filters['meta_keyword'],
        "filters[benchmark_ceil]": filters['benchmark_ceil'],
        "filters[completion_floor]": filters['completion_floor'],
        "filters[manager]": filters['manager'],
        "filters[campaign]": filters['campaign'],
        "filters[customer]": filters['customer'],
        "filters[contact]": filters['contact'],
        "filters[dialer_id]": filters['dialer_id'],
        "filters[phrase]": filters['phrase'],
        "filters[scorecard]": filters['scorecard'],
        "filters[compliance_violations_range]": filters['compliance_violations_range'],
        "filters[completion_range]": filters['completion_range'],
        "filters[monologue_range]": filters['monologue_range'],
        "filters[questions_range]": filters['questions_range'],
        "filters[talk_ratio_range]": filters['talk_ratio_range'],
        "filters[objections_range]": filters['objections_range'],
        "filters[duration_range]": filters['duration_range'],
      };

      setParams(newParams);
      setLoading(true);

      axios.get('/agent_scorecards/recordings', {
        params: newParams,
      }).then(res => {
        setData(res.data);
      }).finally(() => {
        setLoading(false);
      });
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('agent_scorecards.filters', filtersListener.current);
      }
    };
  }, []);
  const agentName = data.agent_scorecard[agent]?.agent?.name || '';
  const scorecard  = data.scorecard_options.find(el => el.value === params['filters[scorecard]'])?.label

  const agentOptions = data.agent_and_group_options.map(item => {
    if (item.group) {
      return {
        value: `g:${item.value}`,
        label: <span><i className="fa fa-users"></i> {item.label}</span>,
      };
    } else {
      return {value: item.value, label: item.label}
    }
  });

  return (
    <main>
      <FiltersRow
        data={data}
        showManagerContext={showManagerContext}>
      </FiltersRow>
      <Container>
        <div>
          <strong>Period: </strong>
          {params['filters[date_range]'] || getDefaultDateRange()}
        </div>
      </Container>
      {scorecard && <Container>
        <div>
          <strong>Scorecard: </strong>
          {scorecard}
        </div>
      </Container>}
      <Tabs>
        <TabList>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Agent</Tab>
          <Tab className="btn btn-outline-link mr-2" selectedClassName="btn-primary">Benchmarking</Tab>
        </TabList>

        <TabPanel>
          <FilterContent>
            <FilterBlock>
                <Container>
                <div>
                  <strong>Please select the agent:</strong>
                </div>
                </Container>
                <SingleSelect
                  initialValue={agent}
                  options={data.agent_options}
                  onChange={setAgent}
                />
            </FilterBlock>
          </FilterContent>
          <ScorecardTableContent>
            <LoadingIndicator visible={loading} />
            <ScorecardTable
              data={data.agent_scorecard}
              agentMetrics={data.agent_metrics}
              agent={agent}
              filterParams={params}
            >
            </ScorecardTable>
          </ScorecardTableContent>
        </TabPanel>
        <TabPanel>
          <Benchmarking
            data={data.agent_benchmark}
            agentOptions={agentOptions}
            filterParams={params}
          >
          </Benchmarking>
        </TabPanel>
      </Tabs>
    </main>
  );
};

export {FilterBlock, FilterContent};

export default Main;
