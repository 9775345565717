import React, { useState, useEffect } from 'react';
import ToggleSwitcher from '../ToggleSwitcher';

const ToggleSwitch = ({showManagerContext, url}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const initToggle = urlParams.get('manager') === 'true';

  const [toggle, setToggle] = useState(initToggle);

  const handleToggleChange = () => {
    const newToggleState = !toggle;
    window.location.href = `../${url}/?manager=${newToggleState}`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setToggle(urlParams.get('manager') === 'true');
  }, [window.location.search]);

  return (
    <div>
      {showManagerContext && (
        <ToggleSwitcher toggle={toggle} handleToggleChange={handleToggleChange} />
      )}
    </div>
  );
}

export default ToggleSwitch;
