import React from 'react';
import { scoreColor } from '../../helpers';
import moment from 'moment';

const ScorecardScore = ({result, autoScored, scorer, scoredAt, name}) => {
  const scoreColorStyle = scoreColor(result);

  return (
    <div>
      {result != null && (
        <>
          <h4>
            Score
            <small className="ml-2" style={{ color: scoreColorStyle }}>
              {result}{autoScored && scorer === 'Autoscore' ? '*' : ''}
            </small>
          </h4>
          {scorer && (<div><b>Scored by:</b> {autoScored && scorer === null ? 'Autoscore' : scorer}</div>)}
          {scoredAt && (<div><b>Scored at:</b> {moment(scoredAt * 1000).format("MMM D, h:mm a")}</div>)}
          <div><strong>Scorecard: </strong> {name}</div>
        </>
      )}
  </div>);
}

export default ScorecardScore;
