import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import compact from 'lodash/compact';
import { useTable, useSortBy } from 'react-table';
import { DefaultTblStyle } from '../common/tables/styles';
import PALETTE from '../palette';
import { beautifyDuration, scoreColor } from '../helpers';

export const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const recordingsColumns = (hideAgent, hideScore) => compact([
  {
    Header: 'Date',
    id: 'date_time',
    accessor: r => r.date,
  },
  !hideAgent && {
    Header: 'Agent',
    accessor: 'created_by',
    Cell: ({ value }) => {
      return <span style={{ color: PALETTE.BRAND.light }}>{value}</span>
    },
  },
  {
    Header: 'Contacts',
    id: 'contacts',
    accessor: r => r.contacts || '-',
  },
  {
    Header: 'Call map',
    accessor: 'call_map',
  },
  {
    Header: <span>Compliance<br />violations</span>,
    accessor: 'compliance_violations',
  },
  {
    Header: 'Call length',
    accessor: r => beautifyDuration(r.duration),
  },
  {
    Header: 'Disposition',
    accessor: 'disposition',
    Cell: ({ value }) => {
      return <span dangerouslySetInnerHTML={{ __html: value }} />
    },
  },
  // {
  //   Header: 'Completion',
  //   accessor: 'completion_score'
  // },
  // {
  //   Header: 'Benchmark',
  //   accessor: 'benchmark_score'
  // },
  !hideScore && {
    Header: 'Score',
    accessor: 'score',
    Cell: ({ row, value }) => {
      if (value === null || value === undefined) return '';

      return <span style={{ color: scoreColor(value) }}>{parseInt(value)}{row.original.auto_scored ? '*' : ''}</span>;
    },
  },
  {
    Header: '',
    id: 'view_details',
    Cell: ({ row }) => {
      return (
        <div className="d-flex align-items-baseline" style={{ whiteSpace: 'nowrap' }}>
          <a href={`/calls/${row.original.id}`} target="_blank">Details</a>
          {row.original.by_external_audio && (
            <StatusBadge className="badge badge-secondary badge-fill ml-2">E</StatusBadge>
          )}
        </div>
      );
    }
  }
]);

const RecordingsTable = ({
  recordings,
  total,
  title = 'Calls',
  hideAgent,
  hideScore,
}) => {
  const columns = useRef(
    recordingsColumns(hideAgent, hideScore)
  );
  const sortees = React.useMemo(
    () => [],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: columns.current, data: recordings,
    initialState: {
      sortBy: sortees,
    }},
    useSortBy);
  return (
    <DefaultTblStyle>
      <div className="table-header d-flex align-items-center">
        <h4>{title}</h4>
        <span className="badge badge-primary mt-1">{total}</span>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getSortByToggleProps()}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' \u2191'
                        : ' \u2193'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
  );
}

export default RecordingsTable;
