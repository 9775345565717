import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import MultiSelect from '../common/form/MultiSelect';
import { InputLabelBig, InputtyButton } from '../common/styles';
import { METRICS, setupAxiosCSRF } from '../helpers';

const PanelWrapper = styled.div`
  padding: 32px;
`;

const metricOptions = Object.keys(METRICS).map(metric => ({
  label: METRICS[metric],
  value: metric,
}));

const AdvancedFilters = ({
  metrics: initialMetricsSelection,
  onChange,
}) => {
  const [metrics, setMetrics] = useState(initialMetricsSelection);

  const handleSave = () => {
    setupAxiosCSRF();

    axios.put('/dashboard/settings', {
      settings: {
        metrics,
      },
    }).then(_res => {
      onChange(metrics);
    });
  };

  return (
    <PanelWrapper>
      <h4 style={{ marginBottom: 32 }}>Advanced Filters</h4>

      <InputLabelBig>Metric</InputLabelBig>
      <MultiSelect
        initialValue={metrics}
        options={metricOptions}
        onChange={setMetrics}
      />

      <div className="row" style={{ marginTop: 32 }}>
        <div className="col-md-6">
        <button className="btn btn-neutral btn-block btn-lg" style={{ fontWeight: 500 }}>Clear Filters</button>
        </div>
        <div className="col-md-6">
          <button className="btn btn-primary btn-block btn-lg" onClick={handleSave}>Apply Filters</button>
        </div>
      </div>
    </PanelWrapper>
  );
};

export default AdvancedFilters;
