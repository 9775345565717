import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import MultiSelect from '../common/form/MultiSelect';
import { InputLabelBig, BlockInput } from '../common/styles';
import { DATE_PREDEFINED_RANGES, arrToSelectorOption } from '../helpers';
import GroupingsPanel, { GROUPING } from './GroupingsPanel';

const FilterBlock = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  &:not(:first-child) {
    margin-left: 30px;
  }
`;

const Configuration = ({
  data,
  onChange,
}) => {
  const [startDate, setStartDate] = useState(moment().subtract(89,'d'));
  const [endDate, setEndDate] = useState(moment());
  const [agentIds, setAgentIds] = useState([]);
  const [reviewerIds, setReviewerIds] = useState([]);
  const [formIds, setFormIds] = useState([]);
  const [options, setOptions] = useState({
    agents: [],
    reviewers: [],
    forms: [],
  });
  const [groupings, setGroupings] = useState([GROUPING.REVIEWER]);

  useEffect(() => {
    const agentOptions = data.agents.map(arrToSelectorOption);
    const reviewerOptions = data.reviewers.map(arrToSelectorOption);
    const formOptions = data.forms.map(arrToSelectorOption);

    setOptions({
      agents: agentOptions,
      reviewers: reviewerOptions,
      forms: formOptions,
    });
  }, [data]);

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleApply = () => {
    notifyFilterChanges();
  };

  const notifyFilterChanges = (overrides = {}) => {
    const state = {
      startDate,
      endDate,
      agentIds,
      reviewerIds,
      formIds,
      groupings,
      ...overrides
    };

    const payload = {
      agents: state.agentIds,
      reviewers: state.reviewerIds,
      forms: state.formIds,
      dateRange: `${state.startDate.format('MM/DD/YYYY')} - ${state.endDate.format('MM/DD/YYYY')}`,
    };

    onChange(payload, state.groupings);
  };

  return (
    <div className="review-analytics-config">
      <div className="d-flex mb-3">
        <FilterBlock>
          <InputLabelBig>Period</InputLabelBig>
          <DateRangePicker
            initialSettings={{
              ranges: DATE_PREDEFINED_RANGES,
              alwaysShowCalendars: true,
              startDate,
              endDate,
            }}
            onCallback={handleDateRangeChange}
          >
            <BlockInput />
          </DateRangePicker>
        </FilterBlock>
        <FilterBlock>
          <InputLabelBig>Forms</InputLabelBig>
          <MultiSelect
            key="form-filter"
            initialValue={formIds}
            options={options.forms}
            onChange={setFormIds}
            placeholder="Any form"
          />
        </FilterBlock>
      </div>
      <div className="d-flex">
        <FilterBlock>
          <InputLabelBig>Agents</InputLabelBig>
          <MultiSelect
            key="agent-filter"
            initialValue={agentIds}
            options={options.agents}
            onChange={setAgentIds}
            placeholder="Any agent"
          />
        </FilterBlock>
        <FilterBlock>
          <InputLabelBig>Reviewers</InputLabelBig>
          <MultiSelect
            key="reviewer-filter"
            initialValue={reviewerIds}
            options={options.reviewers}
            onChange={setReviewerIds}
            placeholder="Any reviewer"
          />
        </FilterBlock>
      </div>

      <div className="d-flex align-items-end mt-3">
        <FilterBlock>
          <InputLabelBig>Group by</InputLabelBig>
          <GroupingsPanel initialGrouping={GROUPING.REVIEWER} onChange={setGroupings} />
        </FilterBlock>
        <button className="btn btn-primary btn-lg" onClick={handleApply}>Apply</button>
      </div>
    </div>
  );
};

export default Configuration;
