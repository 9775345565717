export const TYPE_NUM_CALLS = 'num_calls';
export const TYPE_CALL_LENGTH = 'call_length';
export const TYPE_VIOLATIONS = 'violations';
export const TYPE_QUESTIONS = 'questions';
export const TYPE_TALK_LISTEN = 'talk_listen';
export const TYPE_SCORE = 'score';

export const CHART_TYPES = {
  [TYPE_NUM_CALLS]: 'Number of calls',
  [TYPE_CALL_LENGTH]: 'Average call length',
  [TYPE_VIOLATIONS]: 'Compliance violations',
  [TYPE_QUESTIONS]: 'Questions per call',
  [TYPE_TALK_LISTEN]: 'Talk / Listen ratio',
  [TYPE_SCORE]: 'Score',
};
