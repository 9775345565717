import React from 'react';
import SimpleValueWidget from './common/SimpleValueWidget';

const ComplianceViolationsWidget = ({
  metric
}) => {
  const { score } = metric;

  return (
    <SimpleValueWidget
      title="Compliance violations"
      value={score}
    />
  )
};

export default ComplianceViolationsWidget;
