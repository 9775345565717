import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table/src/hooks/useTable';
import { DefaultTblStyle } from '../common/tables/styles';
import { METRICS, withinGoalRange, formatMetricValue } from '../helpers';
import PALETTE from '../palette';

const toQueryString = obj => "?".concat(Object.keys(obj).map(e => obj[e] ? `${encodeURIComponent(e)}=${encodeURIComponent(obj[e])}` : null).filter(e => !!e).join("&"));

const generateTblColumns = (metrics, filterParams) => {
  const columns = [
    {
      Header: 'Agent',
      id: 'agent',
      sortable: true,
      accessor: d => d.agent.name,
      Cell: ({ row, value }) => {
        const params = toQueryString(filterParams);
        return <a href={`/agents/${row.original.agent.id}${params}`}>{value}</a>
      }
    }
  ];

  for (let metric of metrics) {
    columns.push(
      {
        Header: METRICS[metric],
        id: metric,
        sortable: true,
        accessor: d => d.metrics[metric]?.score,
        Cell: ({ row }) => {
          if (!row.original.metrics[metric]) return null;

          const { goal, score } = row.original.metrics[metric];
          const hasGoal = !!goal;

          let color = PALETTE.NEUTRAL['900'];
          if (hasGoal) {
            color = withinGoalRange(score, goal) ? PALETTE.GREEN.light : PALETTE.RED.light;
          }

          return <span style={{ color }}>{formatMetricValue(score, metric, true)}</span>
        }
      }
    )
  }

  return columns;
};

const AgentMetricsTable = ({
  data,
  visibleMetrics,
  filterParams,
}) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const includedMetrics = visibleMetrics.filter(metric => metric !== 'active_agents');

    setColumns(
      generateTblColumns(includedMetrics, filterParams)
    );
  }, [visibleMetrics, filterParams]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <DefaultTblStyle>
      <h4>Metrics by Agents</h4>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
   );
};

export default AgentMetricsTable;
