import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { setupAxiosCSRF } from '../helpers';
import Filters from './Filters';
import moment from 'moment';
import { InputLabelBig } from '../common/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { customStyles } from '../common/form/MultiSelect';

const ListTransfer = ({data}) => {
  const initialItems = Object.keys(data.attributeNames);
  const initialConstantColumns = Object.keys(data?.constantColumnNames ?? {}).map(name => ({name, value: data.constantColumnNames[name]}));
  const [leftItems, setLeftItems] = useState(initialItems);
  const [allItems, setAllItems] = useState(initialItems);
  const [rightItems, setRightItems] = useState([]);
  const [selectedLeftItems, setSelectedLeftItems] = useState([]);
  const [selectedRightItems, setSelectedRightItems] = useState([]);
  const [filters, setFilters] = useState({
    date_range: `${moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY')} - ${moment().startOf('month').subtract(1, 'days').format('MM/DD/YYYY')}`
  });
  const [isDowloading, setIsDownloading] = useState(false);
  const [constantColumns, setConstantColumns] = useState(initialConstantColumns);
  const [newConstantColumn, setNewConstantColumn] = useState({name: '', value: ''});
  const [scorecardItems, setScorecardItems] = useState([]);
  const [previousScorecardItems, setPreviousScorecardItems] = useState([]);
  const storedFilename = localStorage.getItem('storedFilename');
  const [filename, setFilename] = useState(storedFilename || '');
  const [selectAllLeft, setSelectAllLeft] = useState(false);
  const [savedFilenames, setSavedFilenames] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [originalNames, setOriginalNames] = useState({});
  const [loadedScorecardItems, setLoadedScorecardItems] = useState([]);
  const [validConstantColumns, setValidConstantColumns] = useState(() => {
    return Object.keys(data?.constantColumnNames ?? {}).map(name => ({name, value: data.constantColumnNames[name]}));
  });

  const updateAttributeName = (oldName, newName) => {
    // Check if oldName exists in data.attributeNames
    if (data.attributeNames[oldName]) {
      // Store the current value and delete the old entry
      const currentValue = data.attributeNames[oldName];
      delete data.attributeNames[oldName];

      // Transform newName to create a new database-friendly name
      const newDbFriendlyName = newName.toLowerCase().replace(/\s+/g, '_');

      // Update the data.attributeNames object with the new name and value
      data.attributeNames[newName] = newDbFriendlyName;
    }
  }

  const handleSaveEdit = (item, index) => {
    // Check for uniqueness
    if (editingValue !== item && rightItems.includes(editingValue)) {
      alert('This name is already taken! Please choose another.');
      return;
    }

    const newRightItems = [...rightItems];
    newRightItems[index] = editingValue;
    setRightItems(newRightItems);

    // Save the original name for the edited item
    const newOriginalNames = {...originalNames};
    if (!newOriginalNames[editingValue]) {
      newOriginalNames[editingValue] = item;
    }
    setOriginalNames(newOriginalNames);

    // Update the attribute name mapping
    updateAttributeName(item, editingValue);

    // Exit editing mode
    setEditingItem(null);
    setEditingValue('');
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
    setEditingValue('');
  };

  const confirmAddConstantColumn = () => {
    if (!newConstantColumn.name || !newConstantColumn.value) {
      alert('Please provide both column name and value.');
      return;
    }
    addConstantColumn(newConstantColumn.name, newConstantColumn.value);
    setNewConstantColumn({name: '', value: ''});
    setLeftItems(prevItems => [...prevItems, newConstantColumn.name]);
  };

  const updateConstantColumn = (index, name, value) => {
    const newColumns = [...constantColumns];
    newColumns[index][name] = value;
    setConstantColumns(newColumns);
  };

  const removeConstantColumn = async (index) => {
    const newColumns = [...constantColumns];
    const removedName = newColumns[index].name;
    newColumns.splice(index, 1);

    const isSuccess = await handleConstantColumnDeletion(removedName);

    if (isSuccess) {
      setConstantColumns(newColumns);
      setValidConstantColumns(prevValidColumns => prevValidColumns.filter(column => column.name !== removedName));
      setLeftItems(prevItems => prevItems.filter(item => item !== removedName));
      setRightItems(prevItems => prevItems.filter(item => item !== removedName));
    } else {
      console.log('The constant column was not deleted.');
    }
  };

  const deleteConstantColumn = async (columnName) => {
    try {
      setupAxiosCSRF();
      const response = await axios.post('/scored_calls_reports/delete_constant_column', { column_name: columnName });
      return response.data.status === 'success';
    } catch (e) {
      console.error('Error deleting constant column:', e);
      return false;
    }
  };

  const addConstantColumn = (columnName, columnValue) => {
    try {
      setupAxiosCSRF();
      axios.post('/scored_calls_reports/add_constant_column', { column_name: columnName, column_value: columnValue }).then((response) => {
        if (response.data.status === 'success') {
          setConstantColumns(prevColumns => [...prevColumns, {name: columnName, value: columnValue}]);
          setValidConstantColumns(prevValidColumns => [...prevValidColumns, {name: columnName, value: columnValue}]);
        }});
    } catch (e) {
      console.error('Error adding constant column:', error);
      alert('Error adding constant column. Please try again.');
    }
  }

  const handleConstantColumnDeletion = async (itemName) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete the constant column '${itemName}'?`);

    if (isConfirmed) {
      return await deleteConstantColumn(itemName);
    }

    return false;
};

  // that moves items to the right and also deselects all items
  const moveItemsToRight = () => {
    if (selectedLeftItems.length) {
      setRightItems(prevItems => [...prevItems, ...selectedLeftItems]);
      setLeftItems(prevItems => prevItems.filter(item => !selectedLeftItems.includes(item)));
      setSelectedLeftItems([]);
      setSelectAllLeft(false);
    }
  };

  const moveItemsToLeft = () => {
    if (selectedRightItems.length) {
      setLeftItems(prevItems => [...prevItems, ...selectedRightItems]);
      setRightItems(prevItems => prevItems.filter(item => !selectedRightItems.includes(item)));
      setSelectedRightItems([]);
    }
  };

  const toggleLeftItemSelection = (item) => {
    setSelectedLeftItems(prevItems => {
      if (prevItems.includes(item)) {
        return prevItems.filter(i => i !== item);
      } else {
        return [...prevItems, item];
      }
    });
  }

  const toggleRightItemSelection = (item) => {
    setSelectedRightItems(prevItems => {
      if (prevItems.includes(item)) {
        return prevItems.filter(i => i !== item);
      } else {
        return [...prevItems, item];
      }
    });
  }

  const handleSelectAllLeftToggle = () => {
    if (selectAllLeft) {
      setSelectedLeftItems([]);
      setSelectAllLeft(false);
    } else {
      setSelectedLeftItems(leftItems);
      setSelectAllLeft(true);
    }
  };

  useEffect(() => {
    // Filter out the old scorecard items from leftItems regardless of the new items count
    const filteredLeftItems = leftItems.filter(item => !previousScorecardItems.includes(item));

    // If there are new scorecard items, concatenate them. If not, use the filtered list only
    const updatedLeftItems = scorecardItems && scorecardItems.length > 0 ? [...filteredLeftItems, ...scorecardItems] : filteredLeftItems;

    // Update leftItems and set the current scorecard items as the previous ones for the next change
    setLeftItems(updatedLeftItems);
    setPreviousScorecardItems(scorecardItems || []); // This ensures that if scorecardItems is null or undefined, we set an empty array

  }, [scorecardItems]);

  useEffect(() => {
    setupAxiosCSRF();
    axios.get('/scored_calls_reports/fetch_filenames')
      .then(response => {
        setSavedFilenames(response.data.filenames);
      })
      .catch(error => {
        console.error('Error fetching filenames:', error);
      });
  }, []);


  const sortItems = (a, b) => {
    const isAScorecard = scorecardItems.includes(a);
    const isBScorecard = scorecardItems.includes(b);

    if (isAScorecard && isBScorecard) return 0;
    if (isAScorecard && !isBScorecard) return 1;
    if (isBScorecard && !isAScorecard) return -1;
    return a.localeCompare(b);
  };

  const getConfigurationName = () => {
    const scorecard = data.scorecards.find(sc => sc[0] === filters.scorecard);
    const scorecardName = scorecard ? scorecard[1] : '';

    // return scorecardName ? `${filename}_${scorecardName}` : filename;
    return filename.endsWith(scorecardName) ? filename : `${filename}_${scorecardName}`;
  }

  const refetchFilenames = () => {
    setupAxiosCSRF();
    axios.get('/scored_calls_reports/fetch_filenames')
    .then((response) => {
      if (response.data.status === 'success') {
        setSavedFilenames(response.data.filenames);
      }
    })
    .catch((error) => {
      console.error('Error getting available configurations:', error);
      alert('Error getting available configurations');
    });
  }

  const handleSaveConfiguration = () => {
    const configurationName = getConfigurationName();

    setupAxiosCSRF();
    axios.post('/scored_calls_reports/save_configuration', {
      configuration_name: configurationName,
      selected_columns: rightItems.map (item => ({ original: originalNames[item] || item, new: item })),
      constant_columns: constantColumns,
      scorecard_items: scorecardItems,
      filters: {
        date_range: filters.date_range,
        agent: filters.agent,
        autoScored: filters.autoScored,
        exportNightly: filters.exportNightly,
        includeCallMapFormData: filters.includeCallMapFormData,
        callPlacedPeriod: filters.callPlacedPeriod,
        callScoredPeriod: filters.callScoredPeriod,
        campaign: filters.campaign,
        evaluator: filters.evaluator,
        scorecard: filters.scorecard,
      }
    }).then((response) => {
      if (response.data.status === 'success') {
        console.log('Configuration saved successfully');
        refetchFilenames();
      } else {
        alert('Error saving configuration');
      }
    }).catch((error) => {
      console.error('Error saving configuration:', error);
      alert('Error saving configuration');
    });
  };

  const handleLoadConfiguration = (selectedFilename) => {
    const configurationName = selectedFilename || getConfigurationName();

    setupAxiosCSRF();
    axios.post('/scored_calls_reports/load_configuration', {
      configuration_name: configurationName,
    }).then((response) => {
      if (response.data.status !== 'success') {
        return alert('Error loading configuration');
      }

      let configData = response.data.configuration || {};

      // Check if configuration for the current scorecard doesn't exist but
      // some other scorecard configuration is available for the same filename.
      if (!configData && filename in response.data.configuration) {
        const otherScorecardKey = Object.keys(response.data.configuration[filename])[0];
        configData = response.data.configuration[filename][otherScorecardKey];
        // Exclude scorecard items.
        configData.scorecard_items = [];
      }

      if (!configData) {
        return alert("No configuration found for the provided filename and scorecard.");
      }

      const { selected_columns = [], constant_columns, scorecard_items = [], filters = {} } = configData;

      let validLoadedConstantColumns = [];

      if (validConstantColumns?.length > 0) {
          validLoadedConstantColumns = constant_columns?.filter(column =>
            validConstantColumns?.some(validColumn => validColumn.name === column.name)
          );
      }

      const deletedConstantColumns = constant_columns?.filter(column =>
        !validLoadedConstantColumns.some(validColumn => validColumn.name === column.name)
      );

      const validSelectedColumns = selected_columns?.filter(column =>
        !deletedConstantColumns.some(deletedColumn => deletedColumn.name === column.new)
      );

      const validMappedColumns = validSelectedColumns?.map(col => col.new);
      setRightItems(validMappedColumns);

      setLoadedScorecardItems(scorecard_items);
      setScorecardItems(scorecard_items);

      const updatedLeftItems = allItems.filter(item => !validMappedColumns.includes(item));
      setLeftItems(updatedLeftItems);

      // Maintain a mapping of custom name to its original
      const originalNameMapping = selected_columns?.reduce((acc, column) => {
        acc[column.new] = column.original;
        return acc;
      }, {});

      setOriginalNames(originalNameMapping);
      setConstantColumns(validLoadedConstantColumns);
      setFilters(filters);
      setFilename(configurationName);

    }).catch((error) => {
      console.error('Error loading configuration:', error);
      alert('Error loading configuration');
    });
  };

  const resetFilenameInputField = () => {
    setFilename('');
    localStorage.removeItem('storedFilename');
  };

  const handleExport = (exportData) => {
    handleSaveConfiguration();
    setIsDownloading(true);
    const downloadFilename = filename ? filename : 'scored_calls_reports';
    localStorage.setItem('storedFilename', downloadFilename);

    const allSelectedColumns = rightItems.map(item => {
      let originalName, newName;

      if (scorecardItems.includes(item)) {
          originalName = newName = item;
      } else {
          // Try to find the value using the original name if the item was edited
          originalName = originalNames[item] || item;
          newName = item;
          if (data.attributeNames[originalName]) {
              originalName = data.attributeNames[originalName];
          }
      }

      // Checking if the item is a name in constantColumns
      const constantColumn = constantColumns?.find(col => col.name === item);
      if (constantColumn) {
          originalName = newName = constantColumn.name;
      }

      // Return an object containing both the original and new names or null if no match
      return originalName ? { original: originalName, new: newName } : null;
    }).filter(Boolean); // this filter ensures any null or undefined values are removed

    setupAxiosCSRF();
    axios.post('/scored_calls_reports/export', {
      selected_columns: allSelectedColumns,
      constant_columns: constantColumns,
      scorecard_items: scorecardItems,
      export_data: exportData,
      filename: getConfigurationName(),
      filters: {
        date_range: filters.date_range,
        agent: filters.agent,
        autoScored: filters.autoScored,
        exportNightly: filters.exportNightly,
        includeCallMapFormData: filters.includeCallMapFormData,
        callPlacedPeriod: filters.callPlacedPeriod,
        callScoredPeriod: filters.callScoredPeriod,
        campaign: filters.campaign,
        evaluator: filters.evaluator,
        scorecard: filters.scorecard,
      }
    }, { responseType: 'blob' })
    .then((response) => {
      setIsDownloading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${downloadFilename}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      setIsDownloading(false);
      resetFilenameInputField();
      if (error.response) {
        if (error.response.data instanceof Blob) {
          error.response.data.text().then(text => {
            try {
              const errorData = JSON.parse(text);
              if (errorData && errorData.error) {
                Swal.fire({
                  icon: 'info',
                  title: 'Report generation',
                  text: errorData.error,
                  confirmButtonColor: '#FE5D39'
                });
              }
            } catch (e) {
              // If JSON parsing fails, this is likely an HTML error page.
              alert("There was an error generating the report. Please try again later.");
            }
          });
        }
      } else {
        console.log("Error exporting data: ", error);
      }
    });
  };

  const handleFiltersChange = (updateBody) => {
    setFilters({
      ...(filters || {}),
      ...updateBody
    });
  };

  const handleReset = () => {
    setLeftItems(initialItems);
    setRightItems([]);
    setSelectedLeftItems([]);
    setSelectedRightItems([]);
    resetFilenameInputField();
    setFilters({
      date_range: `${moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY')} - ${moment().startOf('month').subtract(1, 'days').format('MM/DD/YYYY')}`
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedRightItems = reorder(
      rightItems,
      result.source.index,
      result.destination.index
    );

    setRightItems(reorderedRightItems);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const handleUpdateScorecardItems = (newScorecardItems) => {
    // if you select a scorecard, we remove existing scorecard items from the right
    // and show the new ones to the left for you to select and add to the right
    const updatedRightItems = rightItems.filter(item =>
        !loadedScorecardItems.includes(item) && !newScorecardItems.includes(item)
    );
    setRightItems(updatedRightItems);
    setLoadedScorecardItems(newScorecardItems);
};

  const selectOptions = savedFilenames.map(name => ({ value: name, label: name }));
  return (
    <div className='container'>
      {savedFilenames?.length > 0 && <div className="row mb-5">
        <div className="col-md-6">
        <InputLabelBig>Load configuration file</InputLabelBig>
          <Select
            styles={customStyles}
            options={selectOptions}
            placeholder="Select a filename"
            onChange={(selectedOption) => {
                if (selectedOption) {
                  setFilename(selectedOption.value);
                  handleLoadConfiguration(selectedOption.value);
                }
              }}
            />
        </div>
      </div>}
      <Filters
        onChange={handleFiltersChange}
        agents={data.agents}
        groups={data.groups}
        campaigns={data.campaigns}
        evaluators={data.evaluators}
        scorecards={data.scorecards}
        setScorecardItems={setScorecardItems}
        filtersConfiguration={filters}
        onUpdateScorecardItems={handleUpdateScorecardItems}
      />
      <div className="my-4">
        <h5>Constant Columns</h5>
        {constantColumns && constantColumns?.map((col, index) => (
          <div key={index} className="d-flex mb-2">
            <input
              placeholder="Column name"
              value={col.name}
              onChange={e => updateConstantColumn(index, 'name', e.target.value)}
              className="mr-1 form-control"
            />
            <input
              placeholder="Column value"
              value={col.value}
              onChange={e => updateConstantColumn(index, 'value', e.target.value)}
              className="mr-1 form-control"
            />
            <button onClick={() => removeConstantColumn(index)} className='btn btn-danger btn-sm ml-1'>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        ))}
        <div className="d-flex mb-2">
          <input
            placeholder="Column name"
            value={newConstantColumn.name}
            onChange={e => setNewConstantColumn({...newConstantColumn, name: e.target.value})}
            className="mr-1 form-control"
          />
          <input
            placeholder="Column value"
            value={newConstantColumn.value}
            onChange={e => setNewConstantColumn({...newConstantColumn, value: e.target.value})}
            className="mr-1 form-control"
          />
          <button onClick={confirmAddConstantColumn} className='btn btn-secondary btn-sm ml-1' disabled={!newConstantColumn.name || !newConstantColumn.value}>
            <i className="fa fa-plus" aria-hidden="true"></i> Add
          </button>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
      <div className="row align-items-center">
        <div className='col'>
          <InputLabelBig>All metrics</InputLabelBig>
          <input type="checkbox"
          className="form-check-input ml-3"
          checked={selectAllLeft} onChange={handleSelectAllLeftToggle}
          />
          <div className="card border rounded h-100 px-3">
            <div className="card-body list-card-body">
            {leftItems.sort(sortItems).map(item => (
                <div key={item} className='d-flex align-items-center'>
                <label
                  className={`${selectedLeftItems.includes(item) ? 'selected-item p-1' : ''} mt-1 hover-reveal-badge`}
                  onClick={() => toggleLeftItemSelection(item)}
                >
                  {item}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='col-auto d-flex flex-column align-items-center sticky-btn'>
          <button onClick={moveItemsToRight} className='btn btn-primary mb-3'>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
          <button onClick={moveItemsToLeft} className='btn btn-primary'>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
        </div>

        <div className='col'>
          <InputLabelBig>Include in export</InputLabelBig>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="card border rounded h-100 px-3"
              >
                <div className="card-body list-card-body">
                {rightItems.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided, snapshot) => (
                      <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='d-flex align-items-center'
                      >
                          {editingItem === item ? (
                            <div className="d-flex align-items-center">
                            <input
                              value={editingValue}
                              onChange={e => setEditingValue(e.target.value)}
                              className="mr-2"
                            />
                            <button onClick={() => handleSaveEdit(item, index)} className='btn btn-outline-primary btn-sm mr-1'>Save</button>
                            <button onClick={() => handleCancelEdit()} className='btn btn-danger btn-sm'>
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </div>
                          ) : (
                            <label
                              {...provided.dragHandleProps}
                              className={`${selectedRightItems.includes(item) ? 'selected-item p-1' : ''} mt-1`}
                              onClick={() => toggleRightItemSelection(item)}
                              onDoubleClick={() => {
                                setEditingItem(item);
                                setEditingValue(item);
                              }}
                            >
                              {item}
                            </label>
                          )}
                      </div>
                    )}
                  </Draggable>
                ))}

                    {provided.placeholder}
                </div>
              </div>
            )}
        </Droppable>
        </div>
      </div>
    </DragDropContext>
    <div className="mb-3">
      <label htmlFor="filename" className='fw-bold'>Excel Filename:</label>
      <input
        type="text"
        id="filename"
        className="form-control"
        value={filename}
        onChange={(e) => setFilename(e.target.value)}
        placeholder="Enter filename without extension"
      />
    </div>

    <div className="d-flex justify-content-between mt-3">
      <div className="flex-grow-1">
        <button onClick={handleReset} className='btn btn-primary'>Reset</button>
      </div>

      <div className="d-flex">
        <button onClick={() => handleExport(true)} className='btn btn-primary mr-2' disabled={rightItems.length === 0 || isDowloading}>
          {isDowloading ? 'Downloading...' : 'Export Excel'}
        </button>
        <button onClick={() => handleExport(false)} className='btn btn-primary' disabled={rightItems.length === 0}>
          Save configuration
        </button>
      </div>
    </div>
  </div>
  );
};

export default ListTransfer;
