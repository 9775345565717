import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

const ContextSwitchLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.on ? '#ff5d39' : '#85868F'};
`;

const ToggleSwitcher = ({ toggle, handleToggleChange }) => (
  <div className="d-flex align-items-center mb-4">
    <Switch
      checked={toggle}
      onChange={handleToggleChange}
      onColor="#ff5d39"
      handleDiameter={25}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={15}
      width={33}
    />
    <ContextSwitchLabel
      className="ml-1"
    >
      My team
    </ContextSwitchLabel>
  </div>
);

export default ToggleSwitcher;
