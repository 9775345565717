import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { optionsToDict } from '../helpers';
import { getNavToReviewsParams } from './helpers';
import { GROUPING, GROUPING_LABELS } from './GroupingsPanel';

const TblCellContent = styled.div`
  font-size: 18px;
  color: #000215;
`;

const CountBoard = ({
  enabled = false,
  forms,
  reviewers,
  agents,
  groupings,
  filterTrack,
  reviewCounts,
}) => {
  const [dicts] = useState({
    [GROUPING.FORM]: optionsToDict(forms),
    [GROUPING.REVIEWER]: optionsToDict(reviewers),
    [GROUPING.AGENT]: optionsToDict(agents.concat(reviewers))
  });

  if (!enabled) return null;

  return (
    <table className='mt-5'>
      <thead>
        <tr>
          {groupings.map((grouping) => (
            <th key={`col-${grouping}`}>
              <TblCellContent className="strong mr-5">{GROUPING_LABELS[grouping]}</TblCellContent>
            </th>
          ))}
          <th>
            <TblCellContent className="strong">Count</TblCellContent>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          reviewCounts.map((cData, index) => {
            const factorIds = cData[0];
            const reviewCount = cData[1];
            const safeFactorIds = Array.isArray(factorIds) ? factorIds : [factorIds];

            const factorTrack = {}; // used exclusively for smart-nav to reviews list
            const groupingFactors = safeFactorIds.map((factorId, factorIndex) => {
              const factorKey = groupings[factorIndex];
              const factorValue = dicts[factorKey][factorId];
              factorTrack[factorKey] = factorId;

              return (
                <td key={factorKey}>
                  <TblCellContent className="mr-5">{factorValue || 'N/A'}</TblCellContent>
                </td>
              );
            });

            return (
              <tr key={`count-${index}`}>
                {groupingFactors}
                <td>
                  <a
                    href={`/admin_reviews?${getNavToReviewsParams(filterTrack, factorTrack)}`}
                    target="_blank"
                    className="pointer"
                  >{reviewCount}</a>
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default CountBoard;
