import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingIndicator = ({
  offsetY = 0,
  visible = false,
}) => {
  if (!visible) return null;

  return (
    <Wrapper style={{ paddingTop: offsetY }}>
      <img src="/images/loader.gif" height="40px" />
    </Wrapper>
  );
};

export default React.memo(LoadingIndicator);
