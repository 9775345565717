import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { PaginatedTableContainer } from '../Reports/styles';
import LoadingIndicator from '../common/LoadingIndicator';
import PeopleTable from './PeopleTable';

const PeopleSection = ({
  initialData,
  filters,
  endpoint,
  tableTitle,
  className,
}) => {
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(0);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (filters) {
      reload();
    }
  }, [filters]);

  const reload = () => {
    const newParams = {
      ...params,
      "filters[date_range]": filters['date_range'],
      page: 1
    };

    setParams(newParams);
    fetchPeople(newParams);
  };

  const fetchPeople = params => {
    setLoading(true);

    axios.get(endpoint, { params })
      .then(res => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageClick = ({ selected: newPage }) => {
    if (page === newPage) return;

    const newParams = {
      ...params,
      page: newPage + 1,
    };

    setParams(newParams);
    setPage(newPage);

    fetchPeople(newParams);
  };

  return (
    <PaginatedTableContainer className={className} style={{ opacity: loading ? 0.5 : 1 }}>
      <LoadingIndicator visible={loading} />
      <PeopleTable
        people={data.entries}
        total={data.pagination.total_entries}
        title={tableTitle}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel="&rsaquo;"
        previousLabel="&lsaquo;"
        forcePage={page}
        pageCount={data.pagination.total}
        onPageChange={handlePageClick}
        renderOnZeroPageCount={null}
        className="paginator"
        disabledClassName="disabled-page"
        activeClassName="active-page"
        pageRangeDisplayed={10}
      />
    </PaginatedTableContainer>
  );
};

export default PeopleSection;
