import styled from 'styled-components';

export const BasicTblStyle = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const DefaultTblStyle = styled.div`
  border: 1px solid #EBEBEC;
  border-radius: 12px;
  overflow: hidden;
  color: #000215;

  h4 {
    font-size: 18px;
    font-weight: 700;
    padding: 32px;
    margin-bottom: 0px;
  }

  table {
    border-spacing: 0;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }

    td {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }

    th {
      border-right: none;
      font-size: 16px;
      font-weight: 600;
      padding-top: ${props => props.noTitle ? '1rem' : '0px'};
      padding-bottom: 1rem;
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #F5F5F6;
      }
    }

    th:first-child, td:first-child {
      padding-left: 2rem;
    }

    th:last-child, td:last-child {
      padding-right: 2rem;
    }
  }
`;

export const ScrollingTblStyle = styled(DefaultTblStyle)`
  overflow: auto;
  max-height: 60vh;

  table th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
`;
