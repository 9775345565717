import React, { useMemo, useCallback } from 'react';
import { VictoryChart } from 'victory-chart';
import { VictoryAxis } from 'victory-axis';
import { VictoryGroup } from 'victory-group';
import { VictoryLegend } from 'victory-legend';
import { VictoryLine } from 'victory-line';
import { VictoryScatter } from 'victory-scatter';
import { VictoryTooltip } from 'victory-tooltip';
import moment from 'moment';
import styled from 'styled-components';
import range from 'lodash/range';
import PALETTE, { CHART_COLORS } from '../palette';
import { PERIOD_UNIT_LABELS } from './Configuration';
import { isCommonizedGroupId, stripCommonizedGroupId } from './helpers';

const ChartContainer = styled.div`
  // max-height: 300px;
  // height: 300px;
`;

const axisStyle = {
  tickLabels: {fontSize: 8, padding: 2},
  axisLabel: { fontSize: 9 },
};

const tooltipTextStyle = {
  fill: '#000000',
  fontSize: 8
};
const tooltipBubbleStyle = {
  fill: '#FFFFFF',
  stroke: PALETTE.BRAND.light,
  strokeWidth: 1
};

const cleanDataPoints = points => points.filter(pt => pt.y !== null);

const TrendsChart = ({
  agents,
  groups,
  data,
  dates,
  visualizedAgents,
  periodUnit,
}) => {
  const chartData = useMemo(() => {
    const cData = [
      {
        displayName: 'Campaign avg.',
        points: cleanDataPoints(data['all'].streaks.map((score, index) => ({ x: index+1, y: score }))),
        color: '#999',
      },
    ];
    let colorIndex = 0;
    for (let agentId of visualizedAgents) {
      if (!data[agentId]) continue;

      const lineData = {
        agentId,
        displayName: isCommonizedGroupId(agentId) ? `⁂ ${groups[stripCommonizedGroupId(agentId)]}` : agents[agentId],
      };
      lineData.points = cleanDataPoints(data[agentId].streaks.map((score, index) => ({ x: index+1, y: score })));
      lineData.color = CHART_COLORS[colorIndex++];

      cData.push(lineData);
    }

    return cData;
  }, [agents, groups, data, visualizedAgents, periodUnit]);

  const getXTickText = useCallback((_, dIndex) => {
    const date = moment(dates[dIndex]);

    if (periodUnit === 'month') {
      return date.format('MMM YYYY');
    } else if (periodUnit === 'day') {
      return date.format('ddd, Do');
    } else if (periodUnit === 'week') {
      return date.format('MMM D');
    } else {
      return date.format('YYYY-MM-DD');
    }
  }, [dates, periodUnit]);

  return (
    <ChartContainer>
      <VictoryChart
        width={600}
        height={300}
        padding={{ top: 0, bottom: 35, left: 35, right: 100 }}
        domainPadding={{ x: 30, y: [0, 20] }}
        domain={{ y: [0, 100] }}
      >
        <VictoryLegend
          x={500}
          orientation="vertical"
          symbolSpacer={5}
          rowGutter={2}
          style={{
            labels: {fontSize: 8 }
          }}
          data={chartData.map(lineData => ({ name: lineData.displayName, symbol: { fill: lineData.color } }))}
        />
        <VictoryAxis
          tickFormat={getXTickText}
          tickValues={range(1, dates.length + 1)}
          label={`${PERIOD_UNIT_LABELS[periodUnit]}s`}
          style={axisStyle}
          minDomain={{ x: 0 }}
        />
        <VictoryAxis
          dependentAxis
          domain={[0, 100]}
          label="Avg. score"
          style={axisStyle}
        />
        {chartData.map((lineData, index) => (
          <VictoryGroup
            key={`chart-${index}`}
            color={lineData.color}
            data={lineData.points}
          >
            <VictoryLine
              animate={{
                duration: 100
              }}
              style={{ data: { strokeWidth: 1} }}
            />
            <VictoryScatter
              size={2.5}
              labels={({ datum }) => datum.y}
              labelComponent={
                <VictoryTooltip
                  style={tooltipTextStyle}
                  flyoutStyle={{ ...tooltipBubbleStyle, stroke: lineData.color }}
                  pointerWidth={5}
                  pointerLength={3}
                  dy={-3}
                />
              }
            />
          </VictoryGroup>
        ))}
      </VictoryChart>
    </ChartContainer>
  );
};

export default TrendsChart;
