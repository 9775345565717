import React from 'react';
import EvalFormsTable from './EvalFormsTable';

const Main = ({
  isAdmin,
  isManager,
  data,
}) => {
  return (
    <main>
      <EvalFormsTable
        evalForms={data.evalForms}
        isAdmin={isAdmin}
        showLinks={isManager} />
    </main>
  )
};

export default Main;
