import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import omitBy from 'lodash/omitBy';
import { setupAxiosCSRF, scoreColor, getAsteriskNotation } from '../../helpers';
import Scorecard from './Scorecard';
import ScorecardScore from './ScorecardScore';

const Arrow = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  text-align: right;
  font-size: 12px;
`;

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  height: 100%;
`;

const PanelContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: 0;
  overflow-y: auto;
  overflow-x: visible;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
`;

const Headline = styled.div`
  padding: 0px 32px 32px;
  color: #000215;
  border-bottom: 1px solid #EBEBEC;
`;

const hasUncheckedItems = (scores, scorecards) => {
  let aScoredScorecardHasUncheckedItems = false;
  scorecards.every(scorecard => {
    const cardScores = scores[scorecard.id];
    const cardScoresCount = cardScores ? Object.keys(cardScores).length : 0;
    const cardItemsCount = scorecard.items.length;
    // A scorecard has to be scored on at least one item before we check it has unscored items,
    // in other words, a scorecard that's completely left blank is fine
    if (cardScoresCount > 0 && cardItemsCount > cardScoresCount) {
      aScoredScorecardHasUncheckedItems = true;
      return false; // aka break
    }
    return true;
  });
  return aScoredScorecardHasUncheckedItems;
}

const ScoringPanel = ({
  callId,
  scores: initialScores,
  notes: initialNotes,
  results,
  scorecards,
  scorer,
  scoredAt,
  lastScorer,
  lastScoredAt,
  readOnly,
  allowAutoScore,
  onResultsChange,
  isAdmin,
  onFinalize,
  onAutoScore,
  isAutoScoring
}, ref) => {
  const [scores, setScores] = useState(initialScores || {});
  const [notes, setNotes] = useState(initialNotes || {});
  const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);
  const hasUnsavedScores = useRef(false);
  const unloadEventListener = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const totalScore = results?.total || null;

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    unloadEventListener.current = window.addEventListener('beforeunload', (evt) => {
      if (hasUnsavedScores.current) {
        evt.preventDefault();
        // custom confirmation messages are not supported.
        evt.returnValue = 'Your call scores will not be saved';
      }
    });

    return () => {
      if (unloadEventListener.current) {
        window.removeEventListener('beforeunload', unloadEventListener.current);
      }
    };
  }, []);

  useEffect(() => {
    hasUnsavedScores.current = changed;
  }, [changed]);

  useImperativeHandle(ref, () => ({
    hasUnsavedScores: () => hasUnsavedScores.current,
  }));

  const handleSave = (shouldSendEmail) => {
    if (!hasUncheckedItems(scores, scorecards) || confirm("Unscored items will be counted as N/A, confirm?")) {
      submitScore(shouldSendEmail);
    }
  };

  const handleAutoScore = () => {
    if (onAutoScore) {
      onAutoScore();  // Call the parent's auto-score handler
    }
  };

  const handleCardScore = (cardId, cardScore) => {
    setScores(
      omitBy(
        {
          ...scores,
          [cardId]: cardScore
        },
        val => !Object.keys(val).length
      )
    );

    setChanged(true);
  };

  const handleItemNotes = (itemId, itemNotes) => {
    setNotes({
      ...notes,
      [itemId]: itemNotes
    });

    setChanged(true);
  };

  const submitScore = (shouldSendEmail) => {
    setSaving(true);

    setupAxiosCSRF();
    axios.post(`/calls/${callId}/score`, {
      call: { scores, score_notes: notes },
      send_email: shouldSendEmail,
    }).then((res) => {
      if (res.data.results) {
        onResultsChange(res.data.results);
      }

      setChanged(false);
    }).catch((err) => {
      console.log('Error', err);
    }).finally(() => {
      setSaving(false);
    });
  }

  const autoScore = () => {
    setSaving(true);

    setupAxiosCSRF();
    axios.post(`/calls/${callId}/autoscore`).then((res) => {
      if (res.data.results) {
        onResultsChange(res.data.results);
      }

      setChanged(false);
    }).catch((err) => {
      console.log('Error', err);
    }).finally(() => {
      setSaving(false);
    });
  }

  const removeScore = () => {
    if (!confirm('Are you sure to remove the scores? It will turn the call to the unscored state and reload the page.')) return;

    setupAxiosCSRF();
    axios.delete(`/calls/${callId}/remove_score`).then((res) => {
      window.location.reload();
    }).catch((err) => {
      console.log('Error removing score', err);
    });
  };

  const noScore = !Object.keys(scores).length;
  const dropdownStyle = { position: 'absolute', top: '-70px', left: '-120px', margin: '-4px', backgroundColor: '#FE5D39', border: 'none'}

  return (
    <PanelWrapper>
      <Headline>
        <h4>
        {scorecards.length > 1 ? 'Average score' : 'Score'}
          {results?.total != null && (
            <small className="ml-2" style={{ color: scoreColor(results.total) }}>
              {results.total}{(getAsteriskNotation(scorecards || [], results.scorecards))}
            </small>
          )}
          {isAdmin && results.total != null && (
            <a onClick={removeScore} className="pointer small ml-3">
              <small><span className="fa fa-trash" /></small>
            </a>
          )}
        </h4>

        {Object.keys(results.scores_meta).map((scorecardId) => {
          const scorecardMeta = results.scores_meta[scorecardId];
          const scorecard = scorecards.find(card => card.id === scorecardMeta.id);

          if (!scorecard) return null;

          return (
          <div className='my-4'>
            <ScorecardScore
              key={scorecardMeta.id}
              result={results.scorecards[scorecardMeta.id]}
              autoScored={scorecardMeta.auto_score}
              scorer={scorecardMeta.scorer}
              scoredAt={scorecardMeta.scored_at}
              name={scorecard.name}
            />
          </div>
          )
        })}

      </Headline>

      <PanelContent>
        {scorecards.map((scorecard) => (
          <Scorecard
            key={scorecard.id}
            scorecard={scorecard}
            score={scores[scorecard.id]}
            notes={notes}
            result={results.scorecards[scorecard.id]}
            readOnly={readOnly}
            autoScored={results.auto}
            onScore={handleCardScore}
            onNotes={handleItemNotes}
          />
        ))}
      </PanelContent>

      {!readOnly && (
        <div className="row no-gutters" style={{ paddingLeft: 32, paddingRight: 32, marginTop: 24 }}>
          <div className={allowAutoScore ? "col-md-6" : "offset-md-3 col-md-6"}>
            <div className="btn-group btn-block" role="group" id="button-group">
              <button
                type="button"
                className="btn btn-primary custom-btn btn-lg border-right"
                disabled={noScore || saving || !changed}
                onClick={() => handleSave(false)}>
                {saving ? 'Saving ...' : 'Save'}
              </button>

              <button
                type="button"
                className="btn btn-primary custom-btn p-1 border-left"
                disabled={noScore || saving || !changed}
                onClick={toggleDropdown}>
                <Arrow>{isDropdownVisible ? '▲' : '▼'}</Arrow>
                <div className="dropdown">
                  {isDropdownVisible && (
                    <div className="dropdown-menu show" style={dropdownStyle}>
                      <a
                        className="dropdown-item"
                        id='dropdown-link'
                        style={{ color: '#fff', fontWeight: 'bold' }}
                        href="#"
                        onClick={() => handleSave(true)}>
                        Save &amp; notify <span className="fa fa-envelope ml-2"></span>
                      </a>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ml-2">
              {allowAutoScore && (
                <button
                  className="btn btn-primary btn-block btn-lg"
                  onClick={handleAutoScore}
                  disabled={isAutoScoring}>  {/* Add disabled state */}
                  {isAutoScoring ? (
                    <>
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      Auto scoring...
                    </>
                  ) : (
                    'Auto score'
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </PanelWrapper>
  );
};

const ScoringPanelRefed = forwardRef(ScoringPanel);

export default ScoringPanelRefed;
