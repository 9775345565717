import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import omitBy from 'lodash/omitBy';
import ScorecardItem from './ScorecardItem';
import { scoreColor } from '../../helpers';

const Card = styled.div`
  margin-top: 24px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ScorecardTitle = styled.div`
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: #000215;
  flex-grow: 1;
`;

const ItemsList = styled.ul`
  padding-left: 0px;
  margin-top: 12px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000215;
  list-style: none;

  li:not(:first-child) {
    margin-top: 8px;
  }
`;

const Scorecard = ({
  scorecard,
  score: initialScore,
  notes,
  result,
  readOnly,
  autoScored,
  onScore,
  onNotes,
}) => {
  const [score, setScore] = useState(initialScore || {});

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onScore(scorecard.id, score);
    }
  }, [score]);

  const togglerRef = useRef(null);

  const handleItemScore = (itemId, itemScore) => {
    setScore(
      omitBy(
        {
          ...score,
          [itemId]: itemScore
        },
        val => val === null || val === undefined
      )
    );
  };

  const handleToggleExpansion = () => {
    togglerRef.current && togglerRef.current.click();
  };

  const cardId = `scorecard_${scorecard.id}`;

  return (
    <Card>
      <CardHeader>
        <ScorecardTitle onClick={handleToggleExpansion}>{scorecard.name}</ScorecardTitle>
        <div>
          {result != null && (
            <small className="mr-2" style={{ color: scoreColor(result) }}>{result}{autoScored ? '*' : ''}</small>
          )}
          <a ref={togglerRef} className="btn collapsed" data-toggle="collapse" href={`#${cardId}`}>
            <span className="fa fa-chevron-down" />
            <span className="fa fa-chevron-up" />
          </a>
        </div>
      </CardHeader>
      <ItemsList className="collapse" id={cardId}>
        {scorecard.items.map(item => (
          <ScorecardItem
            key={`item-${item.id}`}
            item={item}
            score={score[item.id]}
            notes={notes[item.id]}
            readOnly={readOnly}
            onScore={handleItemScore}
            onNotes={onNotes}
          />
        ))}
      </ItemsList>
    </Card>
  );
};

export default Scorecard;
