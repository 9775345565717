import React from 'react';
import SimpleValueWidget from './common/SimpleValueWidget';

const ActiveAgentsWidget = ({
  metric
}) => {
  const { score, goal } = metric;

  return (
    <SimpleValueWidget
      title="Active agents"
      value={score}
    />
  )
};

export default ActiveAgentsWidget;
