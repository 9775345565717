import React, { useState} from 'react';

const Intents = ({
  embeddableId
}) => {

  return (
    <main>
      <em-beddable token={embeddableId}/>
    </main>
  );
}

export default Intents;