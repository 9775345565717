import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import palette from '../palette';
import { meterTime } from '../helpers';
import { downloadFile } from '../../helpers';

const Container = styled.div`
  margin-top: 24px;
  margin-left: auto;
  max-width: 700px;
`;

const Deck = styled.div`
  margin-top: 16px;
  padding: 16px 8px 16px 16px;
  background-color: #F8F9FA;
  border-radius: 16px;

  > div {
    max-height: 90vh;
    overflow-y: auto;
    padding-right: 8px;
  }
`;

const MessageBox = styled.div`
  cursor: pointer;
  width: fit-content;
  max-width: 450px;
  margin-left: auto;
  padding: 8px;
  font-size: 16px;
  color: white;
  border-radius: 16px;
  background-color: ${palette.BRAND.light};
  border-bottom-right-radius: 0px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.customer {
    background-color: ${palette.GREEN.light};
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 0px;
    margin-left: 0px;
    margin-right: auto;
  }

  .time {
    font-size: 14px;
    opacity: 0.7;
    text-align: right;
  }
`;

const Arrow = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  text-align: right;
  font-size: 8px;
`;

const Conversation = ({
  messages,
  isManager,
  isAdmin,
  allowDownloadRecording,
  recordingID
}) => {
  const [visible, setVisible] = useState(false);
  const empty = !messages.length;
  const canDownload = allowDownloadRecording && (isManager || isAdmin);

  const toggleVisiblity = () => {
    setVisible(!visible);
  };

  const jumpTo = useCallback(seconds => {
    window.dispatchEvent(
      new CustomEvent('replay.jump', { detail: seconds })
    );
  }, []);

  const handleJSONDownload = (fileName, messages) => {
    try {
      if (!messages) {
        console.error("No messages provided");
        return;
      }
      const data = JSON.stringify(messages, null, 2);
      const blob = new Blob([data], { type: 'text/json' });
      downloadFile(blob, `${fileName}.json`);

    } catch (err) {
      console.error("Failed to download JSON file:", err);
    }
  };



  return (
    <Container>
      <div className="text-right">
        <button
          className="btn btn-primary"
          style={{ marginLeft: 'auto' }}
          disabled={empty}
          onClick={toggleVisiblity}
        >
          Transcript
          <Arrow>{visible ? '▲' : '▼'}</Arrow>
        </button>
        {canDownload && <button
          className="btn btn-primary ml-2"
          disabled={empty}
          onClick={() => handleJSONDownload(recordingID, messages)}
        >
          Download
          <Arrow><span className="fa fa-download" /></Arrow>
        </button>}
      </div>

      {visible && (
        <Deck>
          <div>
            {messages.map((message, index) => {
              return (
                <MessageBox
                  key={`msg-${index}`}
                  className={cx({ customer: message.customer })}
                  onClick={() => jumpTo(message.time)}
                >
                  <div className="message">{message.text}</div>
                  <div className="time">{meterTime(message.time)}</div>
                </MessageBox>
              );
            })}
          </div>
        </Deck>
      )}
    </Container>
  );
};

export default Conversation;
