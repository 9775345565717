import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Configuration from './Configuration';
import CountBoard from './CountBoard';
import { GROUPING } from './GroupingsPanel';

const Main = ({
  data: baseData,
}) => {
  const [data, setData] = useState(null);
  const [groupings, setGroupings] = useState([GROUPING]);
  const [filterTrack, setFilterTrack] = useState(null); // exclusively used for smart-nav to reviews list

  const handleConfig = (filters, groupings) => {
    setData(null);
    setGroupings(groupings);

    const params = {
      "filters[date_range]": filters.dateRange,
      "filters[agents]": filters.agents,
      "filters[reviewers]": filters.reviewers,
      "filters[forms]": filters.forms,
      "groupings": groupings,
    };

    axios.get('/review_analytics/data', {
      params,
    }).then(res => {
      setData(res.data);
      setFilterTrack(filters);
    });
  };

  return (
    <main>
      <Configuration data={baseData} onChange={handleConfig} />
      <CountBoard
        enabled={!!data}
        forms={baseData.forms}
        reviewers={baseData.reviewers}
        agents={baseData.agents}
        filterTrack={filterTrack}
        groupings={groupings}
        reviewCounts={data}
      />
    </main>
  );
};

export default Main;
