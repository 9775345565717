import React from 'react';
import { useTable } from 'react-table/src/hooks/useTable'
import { DefaultTblStyle } from '../common/tables/styles';

const agentColumns = [
  {
    Header: 'Agent',
    id: 'agent',
    Cell: ({ row }) => {
      const agent = row.original;

      return (
        <div>
          {agent.name || 'No name'}
          <br />
          <small>{agent.email}</small>
        </div>
      );
    }
  },
  {
    Header: <div className="text-right">Synced</div>,
    id: 'synced',
    Cell: ({ row }) => {
      const agent = row.original;

      return (
        <div className="text-right">
          {agent.outreach_id ? (
            <i className="fa fa-check text-success" />
          ) : (
            <i className="fa fa-times text-danger" />
          )}
        </div>
      );
    }
  }
];

const SyncedAgents = ({
  agents,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: agentColumns, data: agents })

   return (
    <DefaultTblStyle style={{ background: '#FFFFFF' }}>
      <h4>Synced Agents</h4>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
   );
 }

 export default SyncedAgents;
