import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import difference from 'lodash/difference';
import range from 'lodash/range';
import GroupingItem from './GroupingItem';

export const GROUPING = {
  REVIEWER: 'reviewer',
  FORM: 'form',
  AGENT: 'user',
};

export const GROUPING_LABELS = {
  [GROUPING.REVIEWER]: 'Reviewer',
  [GROUPING.FORM]: 'Form',
  [GROUPING.AGENT]: 'Agent',
};

const getSelectOptions = (groupings) => {
  return groupings.map((grouping) => ({ value: grouping, label: GROUPING_LABELS[grouping] }));
};

// not a controlled component
const GroupingsPanel = ({
  initialGrouping = GROUPING.REVIEWER,
  onChange,
}) => {
  const [groupings, setGroupings] = useState([initialGrouping]);
  const [groupingOptions, setGroupingOptions] = useState([]);
  const [dimension, setDimension] = useState(1);

  useEffect(() => {
    onChange(groupings);
  }, [groupings]);

  useEffect(() => {
    // must change by 1 (+1 or -1)
    const {
      groupings: newGroupings,
      groupingOptions: newGroupingOptions,
    } = reviseGroupingsAndOptions(groupings, dimension);

    setGroupings(newGroupings);
    setGroupingOptions(newGroupingOptions);
  }, [dimension]);

  const handleGroupingChange = (axis, grouping) => {
    const draftGroupings = [...groupings];
    draftGroupings[axis] = grouping;

    const {
      groupings: newGroupings,
      groupingOptions: newGroupingOptions,
    } = reviseGroupingsAndOptions(draftGroupings, dimension);

    setGroupings(newGroupings);
    setGroupingOptions(newGroupingOptions);
  };

  const reviseGroupingsAndOptions = useCallback((groupings, dimension) => {
    const groupingsPool = Object.values(GROUPING);
    const groupingOptions = [];
    const groupingsTaken = [];

    for (let axis=0; axis<dimension; axis++) {
      let grouping = groupings[axis];
      const axisOptions = difference(groupingsPool, groupingsTaken);

      if (!axisOptions.includes(grouping)) {
        grouping = axisOptions[0];
      }
      groupingsTaken.push(grouping);
      groupingOptions.push(axisOptions);
    }

    return {
      groupings: groupingsTaken,
      groupingOptions
    };
  }, []);

  const handleDimensionShrink = useCallback((e) => {
    e.preventDefault();

    setDimension(dimension - 1);
  }, [dimension, setDimension]);

  const handleDimensionExpand = useCallback((e) => {
    e.preventDefault();

    setDimension(dimension + 1);
  }, [dimension, setDimension]);

  const isFull = dimension === 3;
  const isSingular = dimension === 1;

  return (
    <div className="d-flex align-items-center">
      {range(dimension).map(axis => {
        const grouping = groupings[axis];
        const axisOptions = groupingOptions[axis];

        if (!grouping || !axisOptions) return null; // wait until state updates

        return (
          <GroupingItem
            key={`grouping-${axis}`}
            axis={axis}
            value={grouping}
            options={getSelectOptions(axisOptions)}
            onChange={handleGroupingChange}
          />
        );
      })}
      {!isSingular && (
        <a href='#' className='mr-2' onClick={handleDimensionShrink}>
          <i className="fa fa-times" />
        </a>
      )}
      {!isFull && (
        <a href='#' className='mr-2' onClick={handleDimensionExpand}>
          <i className="fa fa-plus" />
        </a>
      )}
    </div>
  );
};

export default GroupingsPanel;
