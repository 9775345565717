import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BlockInput, InputLabelBig } from '../common/styles';
import { DATE_PREDEFINED_RANGES } from '../helpers';
import Select from 'react-select';
import axios from 'axios';
import { customStyles } from '../common/form/MultiSelect';

const Filters = ({
  onChange,
  agents,
  groups,
  campaigns,
  evaluators,
  scorecards,
  setScorecardItems,
  filtersConfiguration,
  onUpdateScorecardItems,
}) => {
  const [startDate, setStartDate] = useState(filtersConfiguration?.date_range ? moment(filtersConfiguration.date_range.split(' - ')[0], 'MM/DD/YYYY') : moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(filtersConfiguration?.date_range ? moment(filtersConfiguration.date_range.split(' - ')[1], 'MM/DD/YYYY') : moment().startOf('month').subtract(1, 'days').format('MM/DD/YYYY'));
  const [selectedAgent, setSelectedAgent] = useState(filtersConfiguration?.agent || '');
  const [autoScored, setAutoScored] = useState(filtersConfiguration?.autoScored || false);
  const [includeCallMapFormData, setIncludeCallMapFormData] = useState(filtersConfiguration?.includeCallMapFormData || false);
  const [selectedCampaign, setSelectedCampaign] = useState(filtersConfiguration?.campaign || '');
  const [selectedEvaluator, setSelectedEvaluator] = useState(filtersConfiguration?.evaluator || '');
  const [selectedScorecard, setSelectedScorecard] = useState(filtersConfiguration?.scorecard ? { value: filtersConfiguration.scorecard, label: filtersConfiguration.scorecard } : '');
  const [exportNightly, setExportNightly] = useState(filtersConfiguration?.exportNightly || false);
  const [selectedPeriod, setSelectedPeriod] = useState(filtersConfiguration?.callPlacedPeriod ? 'callPlacedPeriod' : 'callScoredPeriod');

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    onChange({
      date_range: `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`
    });
  };

  const handleAgentChange = (selectedOptions) => {
    setSelectedAgent(selectedOptions);

    if (selectedOptions.some(option => option.value === 'All')) {
      onChange({ agent: ['All'] });
    } else {
      onChange({ agent: selectedOptions.map(option => option.value) });
    }
  };

  const groupOptions = groups.map(group => {
    return {
      value: `g:${group[0]}`,
      label: <span><i className="fa fa-users"></i> {group[1]}</span>,
    };
  });

  const agentOptions = [
    { label: 'All', value: 'All' },
    ...groupOptions,
    ...agents.map(usr => ({ value: usr[0], label: usr[1] }))
  ];

  const handleAutoScoredChange = (event) => {
    setAutoScored(event.target.checked);

    onChange({
      autoScored: event.target.checked
    });
  };

  const handleCallMapFormDataChange = (event) => {
    setIncludeCallMapFormData(event.target.checked);

    onChange({
      includeCallMapFormData: event.target.checked
    });
  };

  const handleExportNightlyChange = (event) => {
    setExportNightly(event.target.checked);
    onChange({
      exportNightly: event.target.checked
    });
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    onChange({
      callPlacedPeriod: period === 'callPlacedPeriod',
      callScoredPeriod: period === 'callScoredPeriod'
    });
  }

  const handleCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions);

    if (selectedOptions.some(option => option.value === 'All')) {
      onChange({ campaign: 'All' });
    } else {
      onChange({ campaign: selectedOptions.map(option => option.value) });
    }
  }

  const campaignOptions = campaigns.map(campaign => {
    return {
      value: campaign,
      label: campaign,
    };
  });

  const handleEvaluatorChange = (selectedOptions) => {
    setSelectedEvaluator(selectedOptions);

    if (selectedOptions.some(option => option.value === 'All')) {
      onChange({ evaluator: 'All' });
    } else {
      onChange({ evaluator: selectedOptions.map(option => option.value) });
    }
  }

  const evaluatorOptions = evaluators.map(evaluator => {
    return {
      value: evaluator[0],
      label: evaluator[1],
    }
  });

  const handleScorecardChange = (selectedOption) => {
    setSelectedScorecard(selectedOption);
    if (selectedOption) {
      axios.get(`/scored_calls_reports/scorecard_items?scorecard_id=${selectedOption.value}`)
        .then(response => {
          const newScorecardItems = response.data.scorecard_items;
          setScorecardItems(newScorecardItems);
          onUpdateScorecardItems(newScorecardItems);
        })
        .catch(error => {
          console.error("Error fetching scorecard items:", error);
        });
    } else {
      setScorecardItems([]); // reset if no scorecard is selected
    }
    onChange({ scorecard: selectedOption ? selectedOption.value : '' });
  }

  const scorecardOptions = scorecards.map(scorecard => {
    return {
      value: scorecard[0],
      label: scorecard[1],
    };
  });

  const transformToSelectOption = (value, optionsArray) => {
    if (value === 'All') {
      return { label: 'All', value: 'All' };
    }
    if (Array.isArray(value)) {
      return value.map(v => optionsArray.find(option => option.value === v));
    }
    return optionsArray.find(option => option.value === value);
  }


  useEffect(() => {
    if (filtersConfiguration) {
      if (filtersConfiguration?.date_range) {
        const [start, end] = filtersConfiguration.date_range.split(' - ');
        setStartDate(moment(start, 'MM/DD/YYYY'));
        setEndDate(moment(end, 'MM/DD/YYYY'));
      }
      setSelectedAgent(transformToSelectOption(filtersConfiguration.agent, agentOptions));
      setSelectedCampaign(transformToSelectOption(filtersConfiguration.campaign, campaignOptions));
      setSelectedEvaluator(transformToSelectOption(filtersConfiguration.evaluator, evaluatorOptions));
      setAutoScored(filtersConfiguration.autoScored || false);
      setIncludeCallMapFormData(filtersConfiguration.includeCallMapFormData || false)
      setSelectedScorecard(transformToSelectOption(filtersConfiguration.scorecard, scorecardOptions));
      setExportNightly(filtersConfiguration.exportNightly || false);
      setSelectedPeriod(filtersConfiguration.callPlacedPeriod ? 'callPlacedPeriod' : 'callScoredPeriod');
    }
  }, [filtersConfiguration]);

  return (
    <>
     <div className="row mb-2 justify-content-start">
        <div className='col-md-2'>
          <input
            type="radio"
            checked={selectedPeriod === 'callScoredPeriod'}
            onChange={() => handlePeriodChange('callScoredPeriod')}
            className='mr-2'
          />
          <InputLabelBig>Scored at</InputLabelBig>
        </div>
        <div className='col-md-2'>
          <input
            type="radio"
            checked={selectedPeriod === 'callPlacedPeriod'}
            onChange={() => handlePeriodChange('callPlacedPeriod')}
            className='mr-2'
          />
          <InputLabelBig>Placed at</InputLabelBig>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-4'>
          <InputLabelBig>Period</InputLabelBig>
          <DateRangePicker
            // the key is needed to force re-render when date range changes
            // otherwise the calendar will not update
            key={`${startDate}-${endDate}`}
            initialSettings={{
              ranges: DATE_PREDEFINED_RANGES,
              alwaysShowCalendars: true,
              opens: 'left',
              startDate,
              endDate,
            }}
            onCallback={handleDateRangeChange}
          >
            <BlockInput />
          </DateRangePicker>
        </div>
        <div className='col-md-4'>
          <InputLabelBig>Agent</InputLabelBig>
          <Select
            isMulti
            styles={customStyles}
            options={agentOptions}
            value={selectedAgent}
            onChange={handleAgentChange}
          />
        </div>
        <div className='col-md-4'>
          <InputLabelBig>Evaluator</InputLabelBig>
          <Select
            isMulti
            styles={customStyles}
            options={evaluatorOptions}
            value={selectedEvaluator}
            onChange={handleEvaluatorChange}
          />
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-6'>
          <InputLabelBig>Scorecard</InputLabelBig>
          <Select
            styles={customStyles}
            options={scorecardOptions}
            value={selectedScorecard}
            onChange={handleScorecardChange}
          />
        </div>
        <div className='col-md-6'>
          <InputLabelBig>Campaign</InputLabelBig>
          <Select
            isMulti
            styles={customStyles}
            options={campaignOptions}
            value={selectedCampaign}
            onChange={handleCampaignChange}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className='col-md-4'>
          <input
            type="checkbox"
            checked={autoScored}
            onChange={handleAutoScoredChange}
            className='mr-2'
          />
          <InputLabelBig>Include auto-scored calls</InputLabelBig>
        </div>
      </div>
      <div className="row mb-2">
        <div className='col-md-4'>
          <input
            type="checkbox"
            checked={includeCallMapFormData}
            onChange={handleCallMapFormDataChange}
            className='mr-2'
          />
          <InputLabelBig>Include Survey/Form Data</InputLabelBig>
        </div>
      </div>
      <div className="row mb-2">
        <div className='col-md-4'>
          <input
            type="checkbox"
            checked={exportNightly}
            onChange={handleExportNightlyChange}
            className='mr-2'
          />
          <InputLabelBig>Export nightly</InputLabelBig>
        </div>
      </div>
    </>
  );

};

export default Filters;


