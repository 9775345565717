import React from 'react';
import pick from 'lodash/pick';
import CompletionWidget from './widgets/CompletionWidget';
import MonologueWidget from './widgets/MonologueWidget';
import QuestionsWidget from './widgets/QuestionsWidget';
import TalkRatioWidget from './widgets/TalkRatioWidget';
import AhtWidget from './widgets/AhtWidget';

const COMPOUND_METRICS = ['completion', 'monologue', 'questions', 'talk_ratio']

const WidgetComponents = {
  completion: CompletionWidget,
  monologue: MonologueWidget,
  questions: QuestionsWidget,
  talk_ratio: TalkRatioWidget,
  aht: AhtWidget,
};

const ChartWidgets = ({
  metrics
}) => {
  let visibleMetricKeys = [];
  if (metrics['aht'] && metrics['aht'].goal) {
    visibleMetricKeys = [...COMPOUND_METRICS, 'aht'];
  } else {
    visibleMetricKeys = COMPOUND_METRICS;
  }

  const compoundMetrics = pick(metrics, visibleMetricKeys);

  return (
    <div className="row">
      {Object.keys(compoundMetrics).map(metricKey => {
        const WidgetComp = WidgetComponents[metricKey];
        const metric = metrics[metricKey];

        return (
          <div key={metricKey} className="col-md-4 mb-4">
            <WidgetComp metric={metric} />
          </div>
        );
      })}
    </div>
  );
};

export default ChartWidgets;
