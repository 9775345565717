import styled, { css } from 'styled-components';

const filterBase = css`
  display: block;
  width: 100%;
  padding: 9px 12px;
  border: 1px solid #EBEBEC;
  border-radius: 12px;
  font-size: 16px; /* sync with react-select font-size */
`;

export const WidgetStyle = styled.div`
  height: 100%;
  overflow: hidden;
  border: 1px solid #EBEBEC;
  border-radius: 16px;
  padding: 24px;
`;

export const InputLabelBig = styled.label`
  color: black;
  font-size: 16px;
  font-weight: 600;
`;

export const BlockInput = styled.input`
  ${filterBase}
`;

export const InputtyButton = styled.div`
  ${filterBase}
  cursor: pointer;
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  height: 44px; /* in sync with the filter inputs */
`;

export const BackDrop = styled.div`
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ScopeSwitchLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.highlighted ? '#ff5d39' : '#85868F'};
`;
