import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';

export const transformScoreStr = score => {
  if (score === undefined || score === null) return score;
  return (score*1).toFixed(1)*1;
}

export const transformScoresData = (scores) => {
  return mapValues(scores, transformScoreStr);
};

export const commonizeGroupId = groupId => `g:${groupId}`;
export const isCommonizedGroupId = commonId => commonId.includes('g:');
export const stripCommonizedGroupId = commonId => commonId.slice(2);

const nullUndefined = val => val === null || val === undefined;

export const numericFalsyLast = (rowA, rowB, columnId, desc) => {
  if (nullUndefined(rowA.values[columnId]) && nullUndefined(rowB.values[columnId])) {
      return 0;
  }

  if (nullUndefined(rowA.values[columnId])) {
      return desc ? -1 : 1;
  }

  if (nullUndefined(rowB.values[columnId])) {
      return desc ? 1 : -1;
  }

  return rowA.values[columnId] - rowB.values[columnId];
};

export const caseInsensitiveComp = (rowA, rowB, columnId, desc) => {
  if (!rowA.values[columnId] && !rowB.values[columnId]) {
    return 0;
  }

  if (!rowA.values[columnId]) {
    return desc ? -1 : 1;
  }

  if (!rowB.values[columnId]) {
      return desc ? 1 : -1;
  }

  return rowA.values[columnId].toLowerCase().localeCompare(rowB.values[columnId].toLowerCase());
};

export const commonToUserIds = (commonIds, groupAgents) => {
  let userIds = [];
  for (let commonId of commonIds) {
    if (isCommonizedGroupId(commonId)) {
      const groupId = stripCommonizedGroupId(commonId);
      userIds = userIds.concat(groupAgents[groupId] || []);
    } else {
      userIds.push(commonId);
    }
  }

  return uniq(userIds);
};
