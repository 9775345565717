import React from 'react';
import SimpleScoreWidget from './common/SimpleScoreWidget';
import GoalAndScoreWidget from './common/GoalAndScoreWidget';
import GoalAndScoreLabel from './common/GoalAndScoreLabel';

const PALETTE = { score: '#000A7A', background: '#DAE6FF', target: '#0522C6' };

const QuestionsWidget = ({
  metric
}) => {
  const { score, goal } = metric;
  const intScore = Math.round(score);

  if (!goal) {
    return (
      <SimpleScoreWidget
        title="Questions asked"
        primaryPercent={score}
        label={`${intScore}%`}
        palette={PALETTE}
      />
    )
  }

  const max = Math.max(intScore, goal);

  return (
    <GoalAndScoreWidget
      title="Questions asked"
      label={`${intScore}`}
      max={max}
      goal={goal}
      score={intScore}
      palette={PALETTE}
    >
      <GoalAndScoreLabel
        goal={goal}
        score={intScore}
        palette={PALETTE}
      />
    </GoalAndScoreWidget>
  );
};

export default QuestionsWidget;
