import React from 'react';
import pick from 'lodash/pick';
import TotalCallsWidget from './widgets/TotalCallsWidget';
import ActiveAgentsWidget from './widgets/ActiveAgentsWidget';
import ComplianceWidget from './widgets/ComplianceWidget';
import AhtWidget from './widgets/AhtWidget';

const LINEAR_METRICS = ['total_calls', 'active_agents', 'compliance'];

const WidgetComponents = {
  total_calls: TotalCallsWidget,
  active_agents: ActiveAgentsWidget,
  compliance: ComplianceWidget,
  aht: AhtWidget,
};

const LinearWidgets = ({
  metrics,
  hideAgents,
}) => {
  let visibleMetricKeys = [];
  if (metrics['aht'] && !metrics['aht'].goal) {
    visibleMetricKeys = [...LINEAR_METRICS, 'aht'];
  } else {
    visibleMetricKeys = LINEAR_METRICS;
  }

  if (hideAgents) {
    visibleMetricKeys = visibleMetricKeys.filter(key => key !== 'active_agents');
  }

  const linearMetrics = pick(metrics, visibleMetricKeys);

  return (
    <div className="row">
      {Object.keys(linearMetrics).map(metricKey => {
        const WidgetComp = WidgetComponents[metricKey];
        const metric = metrics[metricKey];

        return (
          <div key={metricKey} className="col-md-4 mb-4">
            <WidgetComp metric={metric} />
          </div>
        );
      })}
    </div>
  );
};

export default LinearWidgets;
