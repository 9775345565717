export const PROMPT_FG = {
  customer: '#00B6CF',
  agent: '#DB8216',
  violation: '#A70010',
};

export const PROMPT_BG = {
  agent: '#FFF7DC',
  customer: '#CEF9FF',
  violation: '#FFE0DD',
};
