import React from 'react';
import { WidgetStyle } from '../../../common/styles';
import GoalAndScoreChart from '../../../common/charts/GoalAndScoreChart';
import { ChartWrapper } from './styles';

const GoalAndScoreWidget = ({
  title,
  children,
  ...chartProps
}) => {
  return (
    <WidgetStyle>
      <h5>{title}</h5>
      <ChartWrapper>
        <GoalAndScoreChart {...chartProps} />
      </ChartWrapper>
      {children}
    </WidgetStyle>
  );
};

export default GoalAndScoreWidget;
