
const setCookieTimezone = (timezone) => {
  document.cookie = `timezone=${timezone};path=/`;
}

const checkTimezoneCookie = (name) => {
  const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${name}=`));
    return !!cookieValue;
}

$(document).on('DOMContentLoaded', () => {
  if (window.current_user_id) {
    let timezone = window.customer_timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!checkTimezoneCookie('timezone')) {
      setCookieTimezone(timezone);
      window.location.href = '/'
    }

    setCookieTimezone(timezone);

    fetch("/set_timezone")
      .then(response => response.json())
      .then(data => {
        if (data && data.timezone) {
          window.customer_timezone = data.timezone;
          timezone = window.customer_timezone || timezone;
          setCookieTimezone(timezone);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
});
