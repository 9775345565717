import React from 'react';
import styled from 'styled-components';

const ScoreBoard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const ScoreRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-right: 24px;
  }
`;

const Bullet = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 5px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-left: 2px;
  color: #85868F;
`;

const GoalAndScoreLabel = ({
  goal,
  score,
  palette,
}) => {
  return (
    <ScoreBoard className="score-board">
      <ScoreRow>
        <Bullet style={{ backgroundColor: palette.target }} />
        <Label>Goal:</Label>
        <Value>{goal}</Value>
      </ScoreRow>

      <ScoreRow>
        <Bullet style={{ backgroundColor: palette.score }} />
        <Label>Current:</Label>
        <Value>{score}</Value>
      </ScoreRow>
    </ScoreBoard>
  );
};

export default GoalAndScoreLabel;
