import React from 'react';
import styled from 'styled-components';
import { WidgetStyle } from '../../../common/styles';

const Value = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: black;
`;

const Title = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #85868F;
  letter-spacing: 0.02px;
`;

const SimpleValueWidget = ({
  title,
  value,
}) => (
  <WidgetStyle>
    <Value>{value}</Value>
    <Title>{title}</Title>
  </WidgetStyle>
);

export default SimpleValueWidget;
