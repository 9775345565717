import React from 'react';
import { CHART_TYPES } from './constants';
import styled from 'styled-components';
import PALETTE from '../palette';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
`;

const TypeBlock = styled.div`
  cursor: pointer;
  border: 1px solid #EBEBEC;
  border-radius: 16px;
  padding: 10px 20px;
  margin-right: 24px;
  background-color: ${props => props.active ? PALETTE.BRAND.light : 'white' };
  color: ${props => props.active ? 'white' : '#555' };
  border-color: ${props => props.active ? PALETTE.BRAND.light : '#EBEBEC' };
`;

const ChartTypePanel = ({
  value,
  onChange,
}) => {
  return (
    <Container className="chart-type-panel">
      {Object.keys(CHART_TYPES).map(type => {
        const typeString = CHART_TYPES[type];

        return (
          <TypeBlock onClick={() => onChange(type)} active={value === type}>
            {typeString}
          </TypeBlock>
        );
      })}
    </Container>
  )
};

export default ChartTypePanel;
