import React from 'react';
import SimpleScoreWidget from './common/SimpleScoreWidget';
import palette from '../../palette';

const PALETTE = { score: palette.BRAND.light, background: palette.BRAND.bg };

const CompletionWidget = ({
  metric
}) => {
  const { score, goal } = metric;

  return (
    <SimpleScoreWidget
      title="Completion"
      primaryPercent={score}
      label={`${Math.round(score)}%`}
      palette={PALETTE}
    />
  )
};

export default CompletionWidget;
