import React from 'react';
import { beautifyDuration } from '../../helpers';
import SimpleScoreWidget from './common/SimpleScoreWidget';
import GoalAndScoreWidget from './common/GoalAndScoreWidget';
import GoalAndScoreLabel from './common/GoalAndScoreLabel';

const PALETTE = { score: '#00B6CF', background: '#CEF9FF', target: '#00E0FF' };

const MonologueWidget = ({
  metric
}) => {
  const { score, goal } = metric;
  const intScore = Math.round(score);

  if (!goal) {
    return (
      <SimpleScoreWidget
        title="Monologue"
        primaryPercent={score}
        label={`${intScore}%`}
        palette={PALETTE}
      />
    )
  }

  const max = Math.max(intScore, goal);

  return (
    <GoalAndScoreWidget
      title="Monologue"
      label={beautifyDuration(intScore)}
      max={max}
      goal={goal}
      score={intScore}
      palette={PALETTE}
    >
      <GoalAndScoreLabel
        goal={beautifyDuration(goal)}
        score={beautifyDuration(intScore)}
        palette={PALETTE}
      />
    </GoalAndScoreWidget>
  );
};

export default MonologueWidget;
