import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { TYPE_NUM_CALLS } from './constants';
import ChartTypePanel from './ChartTypePanel';
import TrendChart from './TrendChart';
import ScopePanel from './ScopePanel';

const Main = ({
  data: baseData, // base data (users, groups, etc)
}) => {
  const filtersListener = useRef(null);
  const [params, setParams] = useState({});
  const [chartType, setChartType] = useState(TYPE_NUM_CALLS);
  const [data, setData] = useState(null); // graph data
  const [tickInterval, setTickInterval] = useState('day');
  const [loading, setLoading] = useState(false);

  const isInitialMount = useRef(true);

  useEffect(() => {
    filtersListener.current = window.addEventListener('trends.filters', event => {
      const { detail: filters } = event;
      const newParams = {
        "filters[call_type]": filters['call_type'],
        "filters[call_map]": filters['call_map'],
        "filters[date_range]": filters['date_range'],
        "filters[users]": filters['users'],
        "filters[agent_prompt_labels]": filters['agent_prompt_labels'],
        "filters[customer_prompt_labels]": filters['customer_prompt_labels'],
        "filters[violation_labels]": filters['violation_labels'],
        "filters[outcome]": filters['outcome'],
        "filters[disposition]": filters['disposition'],
        "filters[dialer_disposition]": filters['dialer_disposition'],
        "filters[keyword]": filters['keyword'],
        "filters[text_match]": filters['text_match'],
        "filters[meta_keyword]": filters['meta_keyword'],
        "filters[benchmark_ceil]": filters['benchmark_ceil'],
        "filters[completion_floor]": filters['completion_floor'],
        "filters[manager]": filters['manager'],
        "filters[campaign]": filters['campaign'],
        "filters[customer]": filters['customer'],
        "filters[contact]": filters['contact'],
        "filters[dialer_id]": filters['dialer_id'],
        "filters[phrase]": filters['phrase'],
        "filters[scored]": filters['scored'],
        "filters[dnis]": filters['dnis'],
        "filters[compliance_violations_range]": filters['compliance_violations_range'],
        "filters[completion_range]": filters['completion_range'],
        "filters[monologue_range]": filters['monologue_range'],
        "filters[questions_range]": filters['questions_range'],
        "filters[talk_ratio_range]": filters['talk_ratio_range'],
        "filters[objections_range]": filters['objections_range'],
        "filters[duration_range]": filters['duration_range'],
      };

      setParams(newParams);
    });

    return () => {
      if (filtersListener.current) {
        window.removeEventListener('trends.filters', filtersListener.current);
      }
    };
  }, []);

  useEffect(() => {
    loadData();
  }, [chartType, params]);

  const loadData = () => {
    axios.get('/trends/data', {
      params: {
        ...params,
        chart_type: chartType,
      },
    }).then(res => {
      setData(res.data.periods);
      setTickInterval(res.data.interval);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <main>
      <ChartTypePanel value={chartType} onChange={setChartType} />
      <ScopePanel data={baseData} params={params} />
      <TrendChart data={data} interval={tickInterval} type={chartType} />
    </main>
  );
};

export default Main;
