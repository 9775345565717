const BRAND = {
  fg: '#D53916', // 300
  light: '#FF5D39', // 200
  bg: '#FFECE7', // 100
};

const NEUTRAL = {
  '900': '#000215',
};

const RED = {
  fg: '#A70010',
  light: '#D93237',
  bg: '#FFE0DD',
};

const GREEN = {
  fg: '#00644B',
  light: '#20A383',
  bg: '#DDECE6',
};

const YELLOW = {
  fg: '#C38154',
  bg: '#FFF9C9',
};

export const CHART_COLORS = [BRAND.light, GREEN.light, '#FF33FF', '#00B3E6',
'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', '#FF6633', '#FFB399'];

export default {
  BRAND,
  NEUTRAL,
  GREEN,
  RED,
  YELLOW,
};
