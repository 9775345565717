import React from 'react';
import styled from 'styled-components';
import { PROMPT_FG } from '../colors';

const MarkerOuter = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 28, 57, 0.1);
`;
const MarkInner = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
  background-color: ${props => props.color};
`;

const Marker = ({
  type,
}) => (
  <MarkerOuter>
    <MarkInner color={PROMPT_FG[type]} />
  </MarkerOuter>
);

export default Marker;
