import React from 'react';
import compact from 'lodash/compact';
import moment from 'moment';
import styled from 'styled-components';

const Container = styled.div`
  margin: 24px 0px;
  font-size: 18px;
  color: #000215;
`;

const getSelectedPeopleStr = (users, groups, ids) => {
  if (!ids || !ids.length) return null;

  const agentIds = [];
  const groupIds = [];
  for (let id of ids) {
    if (id.includes('g:')) {
      groupIds.push(id.split(':')[1]);
    } else {
      agentIds.push(id);
    }
  }

  const selectedAgents = users.filter(user => agentIds.includes(user[0]));
  const selectedGroups = groups.filter(group => groupIds.includes(group[0]));

  return compact([
          selectedAgents.map(agent => agent[1]).join(', '),
          selectedGroups.map(group => group[1]).join(', ')
        ]).join(', ');
};

const getDefaultDateRange = () => {
  const startDate = moment().subtract(6,'d');
  const endDate = moment();

  return `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`;
};

const ScopePanel = ({
  data,
  params,
}) => {
  const { users, groups } = data;
  const selectedIds = params['filters[users]'];

  const selectedPpl = getSelectedPeopleStr(users, groups, selectedIds);

  return (
    <Container>
      {!!selectedPpl && (
        <div>
          <strong>Agent{selectedPpl.includes(',') ? 's' : ''}: </strong>
          {selectedPpl}
        </div>
      )}
      <div>
        <strong>Period: </strong>
        {params['filters[date_range]'] || getDefaultDateRange()}
      </div>
    </Container>
  );
};

export default React.memo(ScopePanel);
